/* Fullscreen Image */
.fullscreen-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.fullscreen-image img {
  max-width: 90%;
  max-height: 80%;
  object-fit: contain;
  position: relative;
  user-select: none; /* Prevent image dragging */
  -webkit-user-select: none;
  touch-action: pan-y; /* Allow vertical scrolling, prevent default horizontal */
}

.prev-button, .next-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.3));
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  font-size: 28px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
  z-index: 1010; /* Повышенный z-index для кликабельности */
}

.prev-button {
  left: 20px;
}

.next-button {
  right: 20px;
}

.prev-button:hover, .next-button:hover {
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.5));
  transform: translateY(-50%) scale(1.1);
}

.prev-button:disabled, .next-button:disabled {
  opacity: 0.4;
  cursor: not-allowed;
  transform: translateY(-50%) scale(1);
}

.prev-button:disabled:hover, .next-button:disabled:hover {
  background: linear-gradient(45deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.3));
}

.fullscreen-like {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 10;
}

.fullscreen-actions {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  z-index: 10;
  text-align: center;
}

@media (min-width: 768px) {
  .fullscreen-actions {
    bottom: auto;
    top: 50%;
    right: 20px;
    left: auto;
    transform: translateY(-50%);
    align-items: flex-end;
  }
}

.likes-count {
  background: rgba(255, 255, 255, 0.1);
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}

.likes-count:hover {
  background: rgba(255, 255, 255, 0.2);
}

.comments-count {
  background: rgba(255, 255, 255, 0.1);
  padding: 5px 10px;
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}

.comments-count:hover {
  background: rgba(255, 255, 255, 0.2);
}

.comment-preview {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-height: 100px; /* Limit preview height */
  overflow: hidden;
}

.fullscreen-comments-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: rgba(255, 255, 255, 0.1);
  padding: 0 10px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  transition: height 0.3s ease;
  z-index: 11;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.fullscreen-comments-container.active {
  height: 100%; /* Full screen on mobile */
  padding: 10px;
}

@media (min-width: 768px) {
  .fullscreen-comments-container {
    position: fixed;
    bottom: 0;
    right: 0;
    top: auto;
    left: auto;
    width: 400px;
    height: 50%;
    border-top-right-radius: 0;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 0;
    transition: none;
  }

  .fullscreen-comments-container.active {
    height: 50%;
  }
}

.fullscreen-comments-container .fullscreen-comments {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.fullscreen-comments-container .comment-form {
  display: flex;
  gap: 5px;
  margin-top: 10px;
}

.fullscreen-comments-container .back-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  padding: 6px;
  border-radius: 50%;
  transition: transform 0.2s ease;
}

.fullscreen-comments-container .back-button:active {
  transform: scale(0.95);
}

.fullscreen-comments-container input {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.fullscreen-comments-container input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.fullscreen-comments-container form button {
  background: #007aff;
  padding: 8px 12px;
  border-radius: 10px;
  color: #fff;
  border: none;
  cursor: pointer;
}

.user-profile-container.space .fullscreen-comments-container form button,
.user-profile-container.future .fullscreen-comments-container form button {
  background: linear-gradient(45deg, #8a2be2, #00b7ff);
}

.fullscreen-comment {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 5px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.fullscreen-comment:hover {
  background: rgba(255, 255, 255, 0.1);
}

.comment-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

.fullscreen-comment p {
  font-size: 13px;
  color: #fff;
  margin: 0 0 2px;
  word-break: break-word;
}

.fullscreen-comment .reply-to {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
}

.fullscreen-comment span {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.7);
}

.comment-actions {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.comment-actions button {
  background: none;
  border: none;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}

.comment-actions button.liked {
  color: #ff2d55;
}

.emoji-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  margin-top: 5px;
}

.emoji-grid span {
  font-size: 20px;
  cursor: pointer;
}

.fullscreen-likers {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(255, 255, 255, 0.1);
  padding: 20px;
  border-radius: 15px;
  width: 90%;
  max-width: 300px;
  max-height: 80vh;
  overflow-y: auto;
  z-index: 12;
  color: #fff;
}

.fullscreen-likers h3 {
  margin: 0 0 10px;
  font-size: 16px;
}

.likers-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.liker-item {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  padding: 5px;
  transition: background 0.2s ease;
}

.liker-item:hover {
  background: rgba(255, 255, 255, 0.1);
}

.liker-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

.close-likers {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 20px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1010; /* Повышенный z-index для кликабельности */
}

.close-button:hover {
  background: rgba(0, 0, 0, 0.7);
}
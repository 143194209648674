@import url('../../Services/variables/variables.css');

/* General NavBar Styling */
.nav-bar {
    position: fixed;
    bottom: 0px; /* Немного приподнята над нижним краем для воздушности */
    width: 90%;
    max-width: 855px; /* Соответствие ширине профиля */
    height: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 20px; /* Оставляем фиксированное значение, так как нет переменной для 20px */
    background: var(--gradient-button); /* Используем переменную градиента кнопок */
    border: 1px solid var(--border-glass); /* Используем переменную границы стекла */
    box-shadow: var(--shadow-glass); /* Используем переменную тени для стекла */
    backdrop-filter: blur(10px); /* Эффект стекла */
    z-index: 1000;
    left: 50%;
    transform: translateX(-50%); /* Центрирование по горизонтали */
}
/* src/components/Navbar/NavBar.css */

/* Существующие стили (предполагаю, что они у тебя есть) */
.nav-bar {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: fixed;
  bottom: 0; /* Предполагаю, что твой навбар внизу */
  width: 100%;
  /*background: #fff; !* Или твой фон *!*/
  padding: 10px 0;
}

.nav-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  text-decoration: none;
  color: #000; /* Или твой цвет */
}

.nav-button.active {
  color: #007bff; /* Или твой активный цвет */
}

/* Новые стили для индикатора */
.nav-button {
  position: relative; /* Для позиционирования индикатора */
}

.chat-icon {
  width: 24px; /* Настрой размер иконки */
  height: 24px;
}

.unread-indicator {
  position: absolute;
  right: -5px;
  color: red;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  font-weight: bold;
}

/* Nav Button Styling */
.nav-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    font-size: var(--font-size-small); /* Используем переменную размера шрифта */
    color: var(--text-color-primary); /* Используем переменную цвета текста */
    cursor: pointer;
    text-decoration: none;
    width: 60px;
    height: 60px;
    border-radius: 50%; /* Круглые кнопки */
    transition: all 0.3s ease;
    position: relative; /* Для эффекта блеска */
    overflow: hidden;
}



.nav-button.active {
    color: var(--text-color-fifth); /* Используем переменную цвета текста */
}

/* SVG Icons */
.nav-icon-svg {
    width: 30px;
    height: 30px;
    fill: var(--text-color-primary); /* Используем переменную цвета текста */
    transition: fill 0.3s ease, transform 0.3s ease;
}

.nav-button span {
    display: block;
    width: 30px;
    height: 30px;
}


/* Profile Icon (assuming ProfileIcon is an SVG or similar) */
.nav-button svg {
    width: 30px;
    height: 30px;
    fill: var(--text-color-primary); /* Используем переменную цвета текста */
}

.nav-button.active svg{
    fill: var(--text-color-fifth); /* Используем переменную цвета текста */
}

/*!* Shine Effect *!*/
/*.nav-button::before {*/
/*    content: '';*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left: -100%;*/
/*    width: 50%;*/
/*    height: 100%;*/
/*    background: var(--shine-effect); !* Используем переменную эффекта блика *!*/
/*    animation: shine 2s infinite;*/
/*}*/


/* Media Queries */
@media (max-width: 600px) {
    .nav-bar {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        bottom: 0; /* Прижата к нижнему краю на маленьких экранах */
        height: 63px;
    }

    .nav-button {
        width: 50px;
        height: 50px;
    }

    .nav-icon-svg,
    .nav-button span,
    .nav-button svg {
        width: 25px;
        height: 25px;
    }
}

@media (max-width: 428px) {
    .nav-bar {
        padding: 0 10px;
    }

    .nav-button {
        width: 45px;
        height: 45px;
    }

    .nav-icon-svg,
    .nav-button span,
    .nav-button svg {
        width: 20px;
        height: 20px;
    }
}

@media (max-width: 1024px) and (min-width: 601px) {
    .nav-bar {
        width: 90%;
        max-width: 855px;
    }

    .nav-button {
        width: 55px;
        height: 55px;
    }

    .nav-icon-svg,
    .nav-button span,
    .nav-button svg {
        width: 28px;
        height: 28px;
    }
}

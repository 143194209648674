@import url('../../Services/variables/variables.css');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');
/*.side-filters{*/
/*    margin-top: 120px;*/
/*}*/
.filter-buttons {
    display: flex;
    flex-direction: column;
    gap: 20px;
    /*border-top: 2px solid gray;*/
    /*border-bottom: 1px solid gray;*/
    margin: 0 auto;
    width: 100%;

}
.FilterIcon {
    color: var(--text-color-primary); /* Применяем цвет к контейнеру SVG */
}

.filter-buttons * {
    font-family: 'Oswald', sans-serif;
    /*border-radius: 20px;*/
}

.filter-item {

    padding: 15px;
    border-radius: 8px;
    width: 25%;

    margin-bottom: 10px;
    position: relative; /* Чтобы псевдоэлемент был относительно элемента */
}

.filter-item .child::before {
    content: '';
    position: absolute;
    left: 0; /* Располагаем линию по левому краю */
    top: 0;
    bottom: 0; /* Растягиваем линию по высоте элемента */
    width: 2px; /* Толщина линии */
    background-color: gray; /* Цвет линии */
}
.priceFilter label{
    padding: 10px;
}
.line {
    position: relative;
    display: block; /* Блоковый элемент, чтобы линия могла занимать всю ширину */
    margin-bottom: 20px; /* Отступ снизу, чтобы линия отделяла элемент от следующего */
}

.line::after {
    content: '';
    position: absolute;
    bottom: -10px; /* Расположим линию чуть ниже элемента */
    left: 0;
    width: 2px; /* Линия по всей ширине элемента */
    height: 100%; /* Толщина линии */
    background-color: gray; /* Цвет линии */
}
.filter-item input[type="checkbox"] {
    appearance: none; /* Убираем стандартный вид чекбокса */
    -webkit-appearance: none; /* Для Safari */
    width: 10px;
    height: 10px;
    padding: 0;
    border-radius: 50%; /* Делаем чекбокс кругом */
    opacity: 0;
    outline: none; /* Убираем обводку при фокусе */
    cursor: pointer; /* Меняем курсор на указатель */
    background-color: transparent; /* Прозрачный фон для неактивного чекбокса */
    transition: background-color 0.3s ease; /* Плавный переход фона */
}

/* Когда чекбокс активен (выбран) */
.filter-item input[type="checkbox"]:checked {
    opacity: 1;
    background-color: var(--text-color-primary); /* Заполняем цветом, когда чекбокс активен */
}
.DisGrid {
    display: block;
}

label {
    font-size: 16px;
    font-weight: 500;
    display: block;

    cursor: pointer;
}

input[type="checkbox"] {
    margin-right: 10px;
}

select, input[type="text"] {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease;
}

select:hover, input[type="text"]:hover {
    border-color: #999;
}





.filters-container {
    padding-left: 50px;
    padding-right: 50px;
    display: flex;
    justify-content: space-between;
}

.brand-list {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: 100%;
    padding: 10px;
    border-bottom: 1px solid #ccc;

    white-space: nowrap;
}
.brand-list::-webkit-scrollbar {
    width: 10%; /* Ширина скроллбара */
    height: 8px; /* Высота скроллбара для горизонтального скролла */
    margin-right: 10px; /* Смещение скроллбара немного вправо */
}

.brand-list::-webkit-scrollbar-thumb {
    background-color: #007bff; /* Цвет ползунка */
    border-radius: 10px; /* Скругление ползунка */
    /* Внешний цвет вокруг ползунка */
}

.brand-list::-webkit-scrollbar-track {
    background-color: var(--background-highlight-color); /* Цвет трека (фона скроллбара) */
    border-radius: 10px; /* Скругление трека */
}

/* При наведении на ползунок */
.brand-list::-webkit-scrollbar-thumb:hover {
    background-color: #0056b3; /* Более темный цвет при наведении */
}
.brands-info {
    justify-content: center;
    text-align: center;
    padding: 20px;
    padding-bottom: 40px;
}
.Brand-info-main{
    font-size: 32px;
    font-weight: 500;
}
.Brand-info-default{
    font-size: 18px;
    font-weight: 400;
}
.brand-list label {
    margin-right: 20px;
}
.BrandWrapper label:first-child {
    font-weight: bold;
    margin-right: 10px;
}

.hiddenCheckbox {
    display: none;
}

.priceLabel {
    display: flex;

    padding-right: 10px;
    padding-left: 10px;

    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-left: 10px;
}

.priceLabel.active{
    background-color: var(--primary-color);
    color: white;

}

.FilterWrap {
    display: flex;
    background-color: var(--background-highlight-color);
    border-radius: 20px;
    height: 66px;
    border-bottom: 0.5px solid gray;

}

.FilterWrap select, .FilterWrap button, .FilterWrap label {
    width: 100%;
}

.FilterWrap select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-color: var(--background-highlight-color);
    color: var(--text-color-primary);
    border: none;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    border-right:1px solid gray ;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
}

.FilterWrap select option {
    background-color: var(--background-color);
    padding: 10px;
}

.brand-logo {
    /* Задаем ширину логотипа */
    height: 80px; /* Автоматически подстраиваем высоту */
    transition: 0.3s ease; /* Добавляем плавный переход при изменении состояния */
    cursor: pointer; /* Курсор указывает на кликабельность */
    border-radius: var(--border-radius-medium);
    padding: 10px;
    justify-content: center;
    align-content: center;
    display: flex;
    object-fit: contain;
}
.brand-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}

.brand-item.selected {
    background-color: #1a73e8;
}

.BrandWrapper {
    height: 100%;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    width: 25%;
    border-right: 1px solid gray;
    border-bottom-right-radius: 20px;


}
.BrandWrapper label{
    justify-content: center;
    align-items: center;
}
.BrandWrapper img{
    height: 3vw;
    padding: 5px;
    min-height: 36px ;
}
.brand-logo.selected{
    background-color: #1a73e8;
}
.toggle-filters-button{
    height: 100%;
    background-color: var(--background-highlight-color);
    display: flex;
    justify-content: center;
    width: 25%;
    align-items: center;
    font-size: 24px;
    font-weight: 500;
    border-bottom-right-radius: 20px;
    /*border-top-right-radius: 20px;*/
    /*    border-right: 1px solid gray;*/
}
.toggle-filters-button img{
    height: 3vw;
    padding: 5px;
    min-height: 36px ;

}
.brnd{
    margin-left: 10px;
}
.toggle-filters-button svg{
    margin-right: 10px;
}
.dropdown-container {
    width: 100%;
    height: 100%;
    position: relative;

}

.dropdown-header {
    padding: 8px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdown-list {
    position: absolute;
    width: 100%;
    border: 1px solid #ccc;
    background-color: var(--background-highlight-color);
    z-index: 100000;
}

.dropdown-option {
    padding: 8px;
    cursor: pointer;
}

.dropdown-option:hover {
    background-color: #eee;
}
.placeholder-content{
    display: flex;
    /*padding: 10px;*/
    align-items: center;
}
.placeholder-content svg{
    margin-right: 10px;
}
.maintext{
    font-size: 24px;

}
.secondarytext{
    font-size: 20px;
}
.partner-input {
    background: none;
    border-radius: 0!important;
    border-top: 2px gray!important;
    border-left: 2px gray!important;
    border-right: 2px gray!important;
    outline: none; /* Убираем обводку, которая может появляться при фокусе */
    box-shadow: none; /* Убираем тень, если она используется для границы */

}

.partner-input:focus {
    border: none; /* Убираем границу при фокусе */
    outline: none; /* Убираем обводку при фокусе */
    box-shadow: none; /* Убираем возможную тень при фокусе */
}
.pricefilters{
    display: flex;
}


.parameters-container {
    /*border-top: 1px solid #1a73e8; !* Только верхняя синяя линия *!*/
    /*padding-top: 10px; !* Отступ сверху *!*/
    padding-bottom: 10px; /* Небольшой отступ снизу */
    display: flex;
    gap: 29px; /* Расстояние между элементами */
    flex-wrap: wrap; /* Если элементы не помещаются, они переходят на новую строку */
    width: 100%;
}



@media(max-width: 650px){
    .pricelabels{
        display: grid;
    }
    .pricefilters{
        display: grid;
    }
    .priceFilters p{
        display: flex;
        justify-content: center;
    }
    .priceLabel{
        margin :     0;
        width: 100%;
    }
}
/* Адаптивность */
@media (max-width: 1000px) {
    .BrandWrapper{
        font-size: 16px;
    }
    .toggle-filters-button svg{
        margin-left: 5px;
    }
    .toggle-filters-button{
        font-size: 16px;
    }
    .FilterIcon{
        margin-left: 5px;
        width: 26px;
        height: 26px;
    }
    .priceLabel{
        font-size: 8px;
    }
    .filters-container{
        padding-right: 0;
        padding-left: 0;
    }
    .filter-buttons {

        max-width: 100%;
    }

    .filter-item {
        padding: 10px;
        width: 26%;
    }

    .brand-list {
        gap: 5px;
    }
    .maintext{
        font-size: 12px;
    }
    .secondarytext{
        font-size: 12px;
    }
    label {
        font-size: 14px;
    }
}
@media (max-width: 600px) {
    .filters-container{
        padding-right: 0;
        padding-left: 0;

    }
    .filter-buttons {

        max-width: 100%;
    }

    .filter-item {
        padding: 10px;
        width: 58%;
    }

    .brand-list {
        gap: 5px;
    }
    .maintext{
        font-size: 12px;
    }
    .secondarytext{
        font-size: 12px;
    }
    label {
        font-size: 14px;
    }
}

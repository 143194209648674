@import url('../../Services/variables/variables.css');

/* General Form Styling */
.Form {
  /* display: flex; */ /* Оставлено закомментированным как в оригинале */
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--background-color); /* Заменяем linear-gradient(135deg, #f5f7fa, #c3cfe2), можно добавить --background-gradient в variables.css */
  padding: 20px;
  font-family: var(--font-family-ios); /* Заменяем -apple-system и т.д. на переменную, можно добавить --font-family-ios: -apple-system, BlinkMacSystemFont, 'Segoe UI', sans-serif */
  -webkit-font-smoothing: antialiased;
  border-radius: 20px;
}

/* Avatar Styling (Transferred from Profile.css) */
.form-avatar {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 77px; /* Offset for iPhone status bar */
}

.form-avatar img {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: var(--box-shadow-medium); /* Заменяем rgba(0, 0, 0, 0.15), можно добавить --box-shadow-medium: 0 4px 15px rgba(0, 0, 0, 0.15) */
  border: 3px solid var(--glass-white); /* Заменяем rgba(255, 255, 255, 0.9), можно добавить --glass-white: rgba(255, 255, 255, 0.9) */
  transition: transform 0.2s ease;
}

.form-avatar img:hover {
  transform: scale(1.05);
}

/* Header Styling */
.h2 {
  font-family: var(--font-family-primary); /* Уже использует переменную */
  font-size: var(--font-size-xl-plus) !important; /* Заменяем 23px, можно добавить --font-size-xl-plus: 23px */
  font-weight: 700;
  text-align: center;
  padding-top: 16px;
  color: var(--text-color-primary); /* #333 в светлой, #ddddd6 в темной */
}

/* Error chat */
.error-message {
  color: var(--warning-color); /* Заменяем #ff3b30 на переменную */
  font-size: var(--font-size-medium); /* Заменяем 14px на переменную */
  text-align: center;
  margin-bottom: 10px;
}

/* Form Inputs */
.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  height: 400px !important;
  padding: 20px;
  margin: 0 auto;
  border: 1px solid var(--neutral-color-6); /* Уже использует переменную (#e0e0e0) */
  border-radius: var(--border-radius-large); /* Уже использует переменную (8px) */
  background-color: var(--neutral-color-2); /* Уже использует переменную, можно уточнить значение */
  box-shadow: var(--box-shadow-light); /* Уже использует переменную */
}

/* Input and Select Styling */
.input,
.select,
input[type="date"] {
  width: 100%;
  padding: 10px;
  padding-left: 40px;
  border: 1px solid var(--neutral-color-6); /* Уже использует переменную */
  border-radius: var(--border-radius-small); /* Уже использует переменную (5px) */
  font-size: var(--font-size-large); /* Уже использует переменную (16px) */
  background: var(--glass-white); /* Заменяем rgba(255, 255, 255, 0.9) */
  transition: all 0.3s ease;
}

.input:focus,
.select:focus,
input[type="date"]:focus {
  border-color: var(--primary-color); /* Заменяем #007bff на переменную */
  box-shadow: var(--box-shadow-primary); /* Заменяем rgba(0, 123, 255, 0.2), можно добавить --box-shadow-primary: 0 4px 15px rgba(0, 123, 255, 0.2) */
  outline: none;
}

.select {
  background-color: var(--neutral-color-1); /* Уже использует переменную (#fff в светлой) */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: var(--text-color-primary); /* Уже использует переменную */
}

/* Input Group */
.input-group {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 15px;
  margin-bottom: 10px;
}

.input-group label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: var(--font-size-large); /* Заменяем 16px на переменную */
  color: var(--text-color-primary); /* Уже использует переменную */
  margin-bottom: 5px;
}

.input-group i {
  position: absolute;
  left: 10px;
  font-size: var(--font-size-xl); /* Заменяем 18px на переменную (20px) */
  color: var(--neutral-color-9); /* Уже использует переменную (#666) */
}

/* Checkbox Styling */
.input-group input[type="checkbox"] {
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--neutral-color-4); /* Заменяем rgba(0, 0, 0, 0.2), можно добавить --neutral-color-4: rgba(0, 0, 0, 0.2) */
  background: var(--glass-white); /* Заменяем rgba(255, 255, 255, 0.9) */
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
}

.input-group input[type="checkbox"]:checked {
  background: var(--primary-color); /* Заменяем #007bff на переменную */
  border-color: var(--primary-color); /* Заменяем #007bff на переменную */
  box-shadow: var(--box-shadow-primary-light); /* Заменяем rgba(0, 123, 255, 0.2), можно добавить --box-shadow-primary-light: 0 0 0 4px rgba(0, 123, 255, 0.2) */
}

.input-group input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  background: var(--text-color-fifth); /* Заменяем white на переменную (#fff) */
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* Visibility Classes */
.hidden {
  display: none;
}

.visible {
  display: block;
}

/* Send Button */
.send-button {
  background: var(--gradient-primary-hover); /* Заменяем linear-gradient(135deg, #007bff, #0056b3), можно добавить --gradient-primary в variables.css */
  color: var(--button-text-color); /* Заменяем white на переменную */
  padding: 12px 20px;
  border: none;
  border-radius: var(--border-radius-xl); /* Заменяем 15px на переменную */
  cursor: pointer;
  font-size: var(--font-size-large); /* Заменяем 16px на переменную */
  box-shadow: var(--box-shadow-medium); /* Заменяем rgba(0, 0, 0, 0.2) на переменную */
  transition: all 0.3s ease;
  margin-top: 20px;
  width: 100%;
}

.send-button:hover {
  transform: translateY(-2px);
  box-shadow: var(--box-shadow-dark); /* Заменяем rgba(0, 0, 0, 0.25), можно добавить --box-shadow-dark: 0 6px 20px rgba(0, 0, 0, 0.25) */
}

.send-button:active {
  transform: scale(0.98);
}

/* Success Animation */
.success-animation {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: fadeIn 0.5s ease-in-out;
  animation-fill-mode: forwards;
}

.checkmark {
  font-size: var(--font-size-xxxl); /* Заменяем 50px на переменную */
  color: var(--success-color); /* Заменяем green, можно добавить --success-color: #34C759 (iOS green) */
  animation: bounce 0.5s ease-in-out;
  animation-fill-mode: forwards;
}

.success-animation h3 {
  color: var(--primary-color); /* Исправляем var(--color-prim) на существующую переменную */
  margin-top: 20px;
  font-size: var(--font-size-xl); /* Заменяем 20px на переменную */
  text-align: center;
}

/* User Info Container */
.user-info-container {
  margin-top: 20px;
  text-align: center;
  font-size: var(--font-size-large); /* Заменяем 16px на переменную */
  color: var(--text-color-primary); /* Уже использует переменную */
}

/* DisGrid Buttons */
.DisGrid {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  width: 100%;
  max-width: 300px;
}

.close-notification {
  background: var(--gradient-primary-hover);; /* Используем переменную для градиента */
  color: var(--text-color-fifth); /* Заменяем white на переменную */
  padding: 10px 20px;
  border: none;
  border-radius: var(--border-radius-xl); /* Заменяем 15px на переменную */
  cursor: pointer;
  font-size: var(--font-size-large); /* Заменяем 16px на переменную */
  box-shadow: var(--box-shadow-medium); /* Заменяем rgba(0, 0, 0, 0.2) на переменную */
  transition: all 0.3s ease;
  width: 100%;
}


/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes bounce {
  0% { transform: scale(0.5); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

/* iPhone-Specific Media Query */
@media only screen and (min-device-width: 320px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
  .select,
  input[type="date"] {
    -webkit-appearance: none;
    padding-left: 40px;
  }

  .Form {
    padding-top: env(safe-area-inset-top); /* Respect iPhone notch */
    padding-bottom: env(safe-area-inset-bottom); /* Respect home indicator */
  }
}
.mapboxgl-popup-content {
    width: auto;
}

.popup-content .flat-card * {
    font-size: 12px;
}

.popup-content .flat-card .datepicker .rdrMonthAndYearWrapper .rdrMonthPicker select,
.popup-content .flat-card .datepicker .rdrMonthAndYearWrapper .rdrYearPicker select {
    padding: 10px;
}

.popup-content  .flat-card .flat-details .datepicker-container .disFlex.BookMsgBtn button {
    font-size: 16px !important;
}

.popup-content .flat-card .flat-details {
    padding:  5px 5px;

}

.popup-content .flat-card .flat-details .flat-details {
    padding: 0;
}

.popup-content .flat-card .flat-details .disFlex.elementsIcons.IconGrid {
    padding: 1px;
    margin-bottom: -8px;
}

.popup-content .flat-card .flat-details .disFlex.elementsIcons.IconGrid .Bluetooth .bluetooth-label {
    display: none;
}

.popup-content .flat-card .flat-details .datepicker {
    overflow: hidden;
}

.popup-content .flat-card .flat-details .rdrDateRangePickerWrapper,
.popup-content .flat-card .flat-details .rdrCalendarWrapper.rdrDateRangeWrapper,
.popup-content .flat-card .flat-details .rdrMonth {
    width: 100%;
}

.popup-content .flat-card .total-price .per-day {
    position: static;
}

.popup-content .flat-card.card-opened .button-container {
    padding: 0;
}

.popup-content .flat-card > .flat-details {
    padding: 5px;
}

.popup-content .flat-card.card-opened .company-name-overlay,
.popup-content .flat-card.card-opened .slick-slider,
.popup-content .flat-card.card-opened .flat-details .LeftRight,
.popup-content .flat-card.card-opened .flat-details .flat-details,
.popup-content .flat-card .flat-details .price-details,
.popup-content .flat-card .date-icon {
    display: none;
}

.popup-content .flat-card.card-opened .date-icon {
    display: block;
}
.fuelConsumption .disFlex.column {
    display: flex;
    flex-direction: column;
    align-items: center;  /* Центрирует все элементы по горизонтали */
}

.Bluetooth {
    display: flex;
    flex-direction: column;
    align-items: center;  /* Центрирует текст под иконкой */
    text-align: center;  /* Выравнивание текста по центру */
}

.Bluetooth img {
    display: block;
    margin-bottom: 5px;  /* Отступ между иконкой и текстом */
}

.fuelUnit {
    font-size: 12px; /* Размер шрифта текста */
    margin-top: 8px;  /* Отступ между иконкой и текстом */
}
.date-icon{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 5px;
}

.flatCard-title-block{
    width: 100%;
    justify-content: space-between;
    display: flex;
    padding-left: 20px;
    padding-right: 20px;
}
.chat-header {
  padding: 10px 15px;
  background: rgba(20, 30, 48, 0.9);
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  top: 0;
  z-index: 2;
}

.chat-header-left {
  display: flex;
  align-items: center;
  gap: 8px;
}

.chat-header .avatar {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 8px;
}

.chat-header h2 {
  color: #fff;
  font-size: 16px;
  margin: 0;
  font-weight: 500;
}

.chat-header .status.online {
  color: #00cc00;
  font-size: 12px;
}

.chat-header .status.offline {
  color: #6c757d;
  font-size: 12px;
}

.chat-header button {
  background: transparent;
  color: #00b4d8;
  border: none;
  padding: 6px 12px;
  cursor: pointer;
  font-size: 13px;
}

/* Поддержка тем */
body.night-mode .chat-header h2 {
  color: #bbb;
}

body.whiteLand .chat-header h2 {
  color: #BBBBCC;
}

body.pureDark .chat-header h2 {
  color: #cccccc;
}
@import url('../../../Services/variables/variables.css');

/* Базовые стили для чата */
body {
  overflow: auto;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background: #0a0f1e; /* Цвет вселенной для всего тела, чтобы фон был единым */
}

.chat-container {
  display: grid;
  grid-template-columns: 0fr 3fr;
  gap: 0;
  height: calc(100vh - 80px); /* Учитываем Header (60px) + NavBar (60px) */
  /*margin: 40px auto; !* Добавляем отступы сверху и снизу для эффекта парения *!*/
  border-radius: 16px; /* Более мягкие углы */
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  background: rgba(30, 42, 68, 0.85); /* Полупрозрачный фон для глубины */
  box-shadow:
    0 12px 30px rgba(0, 0, 0, 0.6), /* Основная тень для парения */
    0 8px 20px rgba(0, 180, 216, 0.2); /* Легкое свечение */
  transform: translateZ(10px); /* Эффект поднятия */
}

@media (max-width: 768px) {
  .chat-container {
    grid-template-columns: 1fr;
    height: calc(100vh - 100px); /* Уменьшенная высота для мобильных */
    width: 100%;
    margin: 20px 0; /* Уменьшенные отступы для мобильных */
    border-radius: 0;
    box-shadow:
      0 8px 20px rgba(0, 0, 0, 0.5),
      0 4px 15px rgba(0, 180, 216, 0.15);
  }
}

/* Темы */
body.night-mode {
  background: var(--gradient-background, linear-gradient(135deg, #1e2a44, #2b3a5a));
}
body.night-mode .chat-container {
  background: rgba(30, 42, 68, 0.85);
}
body.night-mode .chat-filters button {
  background: rgba(50, 65, 100, 0.9);
  color: #bbb;
}
body.night-mode .chat-filters button:hover {
  background: linear-gradient(135deg, rgba(70, 85, 120, 1), rgba(60, 75, 105, 0.9));
}
body.night-mode .user-item span {
  color: #bbb;
}

body.whiteLand {
  background: var(--gradient-background, linear-gradient(135deg, #3A2A5F, #4A3A7F));
}
body.whiteLand .chat-container {
  background: rgba(58, 42, 95, 0.85);
}
body.whiteLand .chat-filters button {
  background: rgba(74, 58, 127, 0.9);
  color: #BBBBCC;
}
body.whiteLand .chat-filters button:hover {
  background: linear-gradient(135deg, rgba(90, 74, 143, 1), rgba(74, 58, 127, 0.9));
}
body.whiteLand .user-item span {
  color: #BBBBCC;
}

body.pureDark {
  background: var(--gradient-background, linear-gradient(135deg, #000000, #1a1a1a));
}
body.pureDark .chat-container {
  background: rgba(0, 0, 0, 0.85);
}
body.pureDark .chat-filters button {
  background: rgba(26, 26, 26, 0.9);
  color: #cccccc;
}
body.pureDark .chat-filters button:hover {
  background: linear-gradient(135deg, rgba(51, 51, 51, 1), rgba(38, 38, 38, 0.9));
}
body.pureDark .user-item span {
  color: #cccccc;
}

/* Стили боковой панели */
.sidebar {
  background: rgba(20, 30, 48, 0.9);
  border-right: 1px solid var(--border-glass);
  padding: 0;
  overflow-y: auto;
  height: 100%;
  transition: transform 0.3s ease;
  position: relative;
}

.chat-stats {
  position: sticky;
  top: 0;
  background: rgba(20, 30, 48, 0.9);
  padding: 12px 15px;
  font-size: 13px;
  color: #d0d8e0;
  z-index: 10;
  text-align: left;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.user-list {
  padding: 0;
  height: calc(100% - 50px);
}

.user-item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  cursor: pointer;
  transition: background 0.2s ease;
}

.user-item:hover {
  background: rgba(255, 255, 255, 0.06);
}

.user-item .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-item span {
  color: #d0d8e0;
  font-size: 15px;
  flex-grow: 1;
}

.user-search {
  padding: 10px 15px;
  background: rgba(20, 30, 48, 0.9);
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.user-search input {
  width: 100%;
  padding: 10px 15px;
  border: none;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.08);
  color: #fff;
  font-size: 14px;
  outline: none;
  transition: background 0.2s ease;
}

.user-search input:focus {
  background: rgba(255, 255, 255, 0.12);
}

.chat-filters {
  display: flex;
  gap: 8px;
  padding: 10px 15px;
  background: rgba(20, 30, 48, 0.9);
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.chat-filters button {
  background: rgba(255, 255, 255, 0.08);
  color: #d0d8e0;
  border: none;
  padding: 8px 15px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 13px;
  transition: background 0.2s ease;
}

.chat-scroll-container {
  flex: 1;
}

.chat-window, .group-chat {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 100%;
}

.message-search {
  padding: 10px 20px;
  background: rgba(20, 30, 48, 0.9);
}

.message-search input {
  width: 100%;
  padding: 10px 15px;
  border: none;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.08);
  color: #fff;
  font-size: 14px;
  outline: none;
}

.message-search input:focus {
  background: rgba(255, 255, 255, 0.12);
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  max-height: calc(100vh - 220px);
  position: relative;
  background:
    linear-gradient(180deg, rgba(10, 15, 30, 0.8), rgba(20, 30, 60, 0.9)), /* Наложение градиента */
    url('https://www.transparenttextures.com/patterns/stardust.png') repeat center center, /* Текстура звезд */
    #0a0f1e; /* Цвет вселенной */
  background-size: auto, cover;
  /*perspective: 1000px; !* Сохранение перспективы для эффекта глубины *!*/
}

.pinned-messages {
  padding: 8px 15px;
  background: rgba(20, 30, 48, 0.9);
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  position: sticky;
  top: 0;
  z-index: 1;
  max-height: 100px;
  overflow-y: auto;
  transition: max-height 0.3s ease;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
}

.pinned-messages.collapsed {
  max-height: 30px;
  overflow: hidden;
}

.pinned-messages h4 {
  font-size: 12px;
  color: #d0d8e0;
  margin: 0 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pinned-messages h4::before {
  content: "📌";
  font-size: 13px;
}

.pinned-messages .toggle-pinned {
  background: transparent;
  border: none;
  color: #00b4d8;
  cursor: pointer;
  font-size: 12px;
}

.context-menu {
  position: absolute;
  background: rgba(20, 30, 48, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 8px;
  z-index: 1000;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  left: 100%;
  top: 0;
  min-width: 120px;
}

.context-menu button {
  display: block;
  width: 100%;
  background: transparent;
  color: #d0d8e0;
  border: none;
  padding: 8px 12px;
  text-align: left;
  cursor: pointer;
  font-size: 13px;
  transition: background 0.2s ease;
}

.context-menu button:hover {
  background: rgba(255, 255, 255, 0.1);
}

.forward-list {
  position: absolute;
  background: rgba(20, 30, 48, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 10px;
  z-index: 1000;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  max-height: 200px;
  overflow-y: auto;
  width: 200px;
}

.forward-list h4 {
  color: #d0d8e0;
  font-size: 14px;
  margin: 0 0 10px;
}

.forward-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.forward-list li {
  color: #d0d8e0;
  padding: 8px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.forward-list li:hover {
  background: rgba(255, 255, 255, 0.1);
}

.messages {
  display: flex;
  flex-direction: column;
  gap: 15px; /* Увеличенный отступ между сообщениями */
  padding-bottom: 10px;
  width: 90%; /* Сужаем область сообщений для эффекта парения */
  margin: 0 auto; /* Центрируем сообщения */
  transform: translateZ(20px); /* Добавляем глубину */
}

.messages-container::-webkit-scrollbar,
.pinned-messages::-webkit-scrollbar,
.sidebar::-webkit-scrollbar {
  width: 6px;
}

.messages-container::-webkit-scrollbar-thumb,
.pinned-messages::-webkit-scrollbar-thumb,
.sidebar::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}

.messages-container::-webkit-scrollbar-track,
.pinned-messages::-webkit-scrollbar-track,
.sidebar::-webkit-scrollbar-track {
  background: transparent;
}

.create-group-btn {
  background: #00b4d8;
  color: #fff;
  border: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 24px;
  position: relative;
  margin: 20px auto;
  display: block;
  box-shadow: 0 4px 10px rgba(0, 123, 255, 0.3);
}

.group-list {
  width: 100%;
  padding: 10px 0;
}

.group-list h3 {
  font-size: 16px;
  color: #fff;
  margin-bottom: 10px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.group-list ul {
  list-style: none;
  padding: 0;
}

.group-list li {
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 5px;
}

.group-list li span {
  color: #fff;
  font-size: 14px;
}

.group-list .toggle-btn {
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  padding: 0 10px;
}

.group-create {
  padding: 20px;
  border-radius: 12px;
  width: 90%;
  max-width: 300px;
  margin: 0;
}

.group-create h2 {
  color: #fff;
  margin-bottom: 10px;
}

.group-create form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.group-create input,
.group-create select {
  padding: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.08);
  color: #fff;
}

.group-create button {
  background: linear-gradient(135deg, #007bff, #00b4d8);
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 8px;
  cursor: pointer;
}

.sidebar-modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

@media (max-width: 768px) {
  .sidebar.hidden-mobile {
    display: none;
  }
  .chat-content {
    transform: none;
    display: block;
  }
  .group-chat {
    height: calc(100vh - 120px);
  }
  .messages-container {
    height: calc(100vh - 180px);
  }
  .group-list h3 {
    font-size: 14px;
  }
  .group-list li {
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .group-list li span {
    font-size: 12px;
  }
  .group-list .toggle-btn {
    font-size: 12px;
  }
}

/* Добавленные стили для интеграции MiniProfile */
.sidebar {
  width: 300px;
  background: rgba(20, 30, 48, 0.95);
  border-right: 1px solid rgba(255, 255, 255, 0.08);
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.sidebar .mini-profile {
  border-bottom: none;
  padding: 10px 15px;
  background: rgba(20, 30, 48, 0.9);
  position: sticky;
  top: 0;
  z-index: 11;
}

body.night-mode .sidebar .mini-profile {
  background: rgba(30, 42, 68, 0.95);
}
body.whiteLand .sidebar .mini-profile {
  background: rgba(58, 42, 95, 0.95);
}
body.pureDark .sidebar .mini-profile {
  background: rgba(0, 0, 0, 0.95);
}

.chat-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.create-group-btn:hover {
  background: #009bbd;
}

.chat-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: #d0d8e0;
  font-size: 16px;
}

/* Адаптивность для мобильных устройств */
@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    position: absolute;
    z-index: 10;
  }

  .chat-content {
    width: 100%;
    position: absolute;
    z-index: 5;
  }

  .sidebar .mini-profile {
    padding: 8px 10px;
  }

  .mini-profile .avatar {
    width: 35px;
    height: 35px;
  }

  .mini-profile-name {
    font-size: 14px;
  }

  .mini-profile-stats {
    font-size: 12px;
    gap: 10px;
  }
}

/* Стили для переключения между чатами и группами */
.sidebar-toggle {
  display: flex;
  gap: 10px;
  padding: 10px 15px;
  background: rgba(20, 30, 48, 0.9);
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.toggle-btn {
  flex: 1;
  padding: 8px 15px;
  background: rgba(255, 255, 255, 0.08);
  color: #d0d8e0;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  font-size: 14px;
  transition: background 0.2s ease, color 0.2s ease;
}

.toggle-btn.active {
  background: #00b4d8;
  color: #fff;
}

.toggle-btn:hover:not(.active) {
  background: rgba(255, 255, 255, 0.12);
}
@import url('../../../Services/variables/variables.css');

/*.partner-form-container {*/
/*    max-width: 400px;*/
/*    margin: 50px auto;*/
/*    padding: 20px;*/
/*    border: 1px solid var(--neutral-color-6); !* Neutral border color *!*/
/*    border-radius: var(--border-radius-large); !* Border radius *!*/
/*    !*background-color: var(--neutral-color-2); !* Background color *!*!*/
/*    box-shadow: var(--box-shadow-medium); !* Box shadow *!*/
/*}*/

.partner-form-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: var(--font-size-xxl); /* Font size */
    color: var(--text-color-primary); /* Text color */
}

.form-group {

}

.form-group label {
    display: block;
    font-weight: bold;
    color: var(--text-color-secondary); /* Label text color */
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid var(--neutral-color-6); /* Input border color */
    border-radius: var(--border-radius-small); /* Input border radius */
    background-color: var(--neutral-color-2); /* Input background color */
    color: var(--text-color-primary); /* Input text color */
}

.form-group textarea {
    height: 100px;
}

.submit-button {
    width: 100%;
    padding: 10px;
    background-color: var(--primary-color); /* Submit button background */
    color: var(--neutral-color-1); /* Submit button text color */
    border: none;
    border-radius: var(--border-radius-small); /* Border radius */
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: var(--primary-hover-color); /* Hover background color */
}

.success-message {
    color: var(--success-color); /* Success message color */
    font-weight: bold;
    margin-top: 15px;
    text-align: center;
}

.error-message {
    color: var(--warning-color); /* Error message color */
    font-weight: bold;
    margin-top: 15px;
    text-align: center;
}
.form-submit-button{
    width:  340px; /* Адаптивная ширина кнопки */
    max-width: 100%;
    font-size: 1.5rem; /* Относительные единицы для шрифта */
    background-color: #1a73e8;
    color: var(--submit-button--background-secondary-color--hover);
    border: 1px solid #204bb8;;
    border-radius: var(--border-radius-medium);
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: var(--box-shadow-medium);
}
.modal-close-button{
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 20px; /* Увеличиваем размер */
    color: rgba(0, 0, 0, 0.7); /* Легкий черный цвет */
    cursor: pointer;
    transition: color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    z-index: 1000; /* Убедитесь, что кнопка на переднем плане */
}
.modal-profile-close-button{
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: none;
    font-size: 20px; /* Увеличиваем размер */
    color: rgba(0, 0, 0, 0.7); /* Легкий черный цвет */
    cursor: pointer;
    transition: color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
    z-index: 1000; /* Убедитесь, что кнопка на переднем плане */
}
.likes-list-component{
    /*margin-top: 125px;*/
}
.likes-list{
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(315px, 1fr));
    padding: 10px;
    width: 100%;
    margin-bottom: 100px;
}

.likes-title{

    display: flex;
    justify-content: center;
    margin-bottom: 10px;
    color: var(--text-color-primary);
    font-weight: bold;
    font-size: 30px;
}
@import url('../../../Services/variables/variables.css');

/* Popup Content */
.mapboxgl-popup-content {
    width: auto;
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.95), rgba(240, 240, 245, 0.9)); /* Эффект стекла */
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(10px);
    padding: 10px;
    overflow: hidden;
}

/* Flat Card General Styling */
.popup-content .flat-card * {
    font-size: 12px !important;
    font-family: var(--font-family-primary, sans-serif);
}

/* Datepicker Selects */
.popup-content .flat-card .datepicker .rdrMonthAndYearWrapper .rdrMonthPicker select,
.popup-content .flat-card .datepicker .rdrMonthAndYearWrapper .rdrYearPicker select {
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.9);
    font-size: 14px;
    transition: all 0.3s ease;
}

.popup-content .flat-card .datepicker .rdrMonthAndYearWrapper .rdrMonthPicker select:focus,
.popup-content .flat-card .datepicker .rdrMonthAndYearWrapper .rdrYearPicker select:focus {
    border-color: #007bff;
    box-shadow: 0 4px 15px rgba(0, 123, 255, 0.2);
    outline: none;
}

/* Buttons in Datepicker */
.popup-content .flat-card .flat-details .datepicker-container .disFlex.BookMsgBtn button {
    font-size: 16px !important;
    padding: 10px;
    background: linear-gradient(135deg, #007bff, #0056b3);
    color: var(--text-color-fifth, #fff);
    border: none;
    border-radius: 12px;
    font-weight: 600;
    cursor: pointer;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.popup-content .flat-card .flat-details .datepicker-container .disFlex.BookMsgBtn button:hover {
    background: linear-gradient(135deg, #0056b3, #003d82);
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 123, 255, 0.2);
}

/* Flat Details */
.popup-content .flat-card .flat-details {
    padding: 5px;
}

/* Nested Flat Details */
.popup-content .flat-card .flat-details .flat-details {
    padding: 0;
}

/* Icon Grid */
.popup-content .flat-card .flat-details .disFlex.elementsIcons.IconGrid {
    padding: 1px;
    margin-bottom: -8px;
    display: flex;
    justify-content: center;
    width: 100%;
}

.popup-content .flat-card .flat-details .disFlex.elementsIcons.IconGrid .Bluetooth .bluetooth-label {
    display: none;
}

/* Datepicker */
.popup-content .flat-card .flat-details .datepicker {
    overflow: hidden;
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

/* Calendar Widths */
.popup-content .flat-card .flat-details .rdrDateRangePickerWrapper,
.popup-content .flat-card .flat-details .rdrCalendarWrapper.rdrDateRangeWrapper,
.popup-content .flat-card .flat-details .rdrMonth {
    width: 100%;
}

/* Total Price */
.popup-content .flat-card .total-price .per-day {
    position: static;
    font-size: 14px;
    color: var(--text-color-primary, #333);
}

/* Card Opened State */
.popup-content .flat-card.card-opened .button-container {
    padding: 0;
}

.popup-content .flat-card.card-opened .company-name-overlay,
.popup-content .flat-card.card-opened .slick-slider,
.popup-content .flat-card.card-opened .flat-details .LeftRight,
.popup-content .flat-card.card-opened .flat-details .flat-details,
.popup-content .flat-card .flat-details .price-details,
.popup-content .flat-card .date-icon {
    display: none;
}

.popup-content .flat-card.card-opened .date-icon {
    display: block;
}

/* Fuel Consumption */
.fuelConsumption .disFlex.column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Bluetooth */
.Bluetooth {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.Bluetooth img {
    display: block;
    margin-bottom: 5px;
    width: 24px;
    height: 24px;
}

/* Fuel Unit */
.fuelUnit {
    font-size: 12px;
    margin-top: 8px;
    color: var(--text-color-primary, #333);
    justify-content: center;
    display: flex;
}

/* Flat Icon Item */
.flat-icon-item {
    margin-left: 5px;
    margin-right: 5px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Icon Grid Flats */
.IconGridFlats {
    width: 100% !important;
}

/* Shine Effect */
.popup-content .flat-card .flat-details .datepicker-container .disFlex.BookMsgBtn button::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 50%;
    height: 100%;
    background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.3), transparent);
    animation: shine 2s infinite;
}

@keyframes shine {
    0% { left: -100%; }
    20% { left: 100%; }
    100% { left: 100%; }
}
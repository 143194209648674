@import url('../../Services/variables/new-variables.css');

.toggle-switch {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 270px;
    min-width: 130px;
    height: 48px;
    background-color: var(--secondary-button-background);
    border-radius: 25px;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border: 1px solid var(--switch-button-border);
    user-select: none;
    margin: 0 20px;
}

.option {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-family-primary), sans-serif;
    font-size: var(--font-size-desctop-medium);
    color: var(--switch-button-color);
    z-index: 1;
    transition: color 0.3s ease;
    user-select: none;
}

.slider {
    position: absolute;
    width: 50%;
    height: 100%;
    background-color: var(--primary-button-background);
    border-radius: 25px;
    transition: transform 0.3s ease;
}

.slider.left {
    transform: translateX(0);
}

.slider.right {
    transform: translateX(100%);
}

.option.selected {
    color: #ffffff;
}
@media screen and (max-width: 1024px) {
    .toggle-switch {
        margin: 0 10px
    }
}
@media screen and (max-width: 700px) {
    .theme-toggle-span {
        width: 15px;
        height: 15px;
    }

    .toggle-switch {
        max-width: 180px;
        height: 48px;
    }
}

@media screen and (max-width: 600px) {
    .theme-toggle-span {
        width: 15px;
        height: 15px;
    }

    .toggle-switch {
        max-width: 130px;
        min-width: 100px;
        height: 26px;
        border-radius: 20px;
    }

    .slider {
        border-radius: 19px;
    }
}

@media screen and (max-width: 400px) {
    .toggle-switch {
        margin-left: -6px;
    }

}

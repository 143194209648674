@import url('../../Services/variables/variables.css');
/* Общие стили для всей страницы */
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

html {
    width: 100% !important;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Сброс отступов и границ у всех элементов */
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* Стили для списка автомобилей */
.car-list {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(315px, 1fr));
    padding: 10px;
    width: 100%;

}
.car-list *{
    font-family: "Oswald", sans-serif;
}
/* Гибкие блоки */
.disFlex {
    display: flex;
}

.Volume {
    display: flex;
    width: 33%;
    justify-content: center;
    align-items: center;
}

.Volume svg {
    width: 35px;
    height: 35px;
    color: gray;
}
.Bluetooth img{
    width: 25px;
    height: 25px;
}
.Volume p {
    color: gray;
    padding: 5px;
}
.brandvolume {
    display: flex;
    align-items: center; /* Выровнять по вертикали */
}
.brandvolume p{
    color: gray;
padding-left: 5px;
}
.IconBlock {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Два столбца */
    gap: 0; /* Отступы между иконками */
    justify-items: center; /* Центрируем иконки внутри ячеек */
    align-items: center; /* Выравниваем иконки по центру по вертикали */
    width: 100%; /* Полная ширина контейнера */
}
.IconGrid{
    display: flex;
    justify-content: space-between;
    /*padding-left: 20px;*/
    /*padding-right: 20px;*/
}
.button {
    padding: 9px;
    margin-top: 10px;
    border: none; /* Убрать границу */
    border-radius: var(--border-radius-medium); /* Закругленные углы */
    background-color: var(--primary-color); /* Фоновый цвет */
    color: var(--button-text-color); /* Цвет текста */
    font-size: var(--font-size-large); /* Размер шрифта */
    cursor: pointer; /* Курсор указатель */
    transition: background-color 0.3s ease; /* Плавное изменение цвета */
}

/* Ховер эффект для кнопки */
.button:hover {
    background-color: var(--primary-hover-color); /* Темный фон при наведении */
}
.IconWrap {
    width: 33%;
    justify-items: center;
    align-items: center;
}

.price {
    margin-top: 10px;

    align-items: center;
    width: 100%;
}



/* Стили карточки автомобиля */
.elementsIcons{
    padding-bottom: 10px;


    padding-top: 10px;
}
.car-card {
    padding: 0;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-self: start;
    align-items: center;
    background: var(--glass-background);
    transition: transform 0.2s ease-in-out;
    position: relative;
}
.fuelConsumption {
    display: flex;
    align-items: center;

}

.fuelNumber {
font-size: 18px;
    font-weight: bold;
    vertical-align: bottom;


}
.pagination {
    display: flex;
    justify-content: center;
    gap: 8px; /* Пространство между кнопками */
    margin-top: 20px;
    padding-bottom: 100px;
    width: 100%;
}

.pagination button {
    background-color: #f0f0f0; /* Светло-серый для неактивных кнопок */
    border: none;
    border-radius: 6px;
    padding: 8px 12px;
    cursor: pointer;
    font-size: 14px;
    color: #333; /* Тёмный текст */
    transition: background-color 0.3s ease, color 0.3s ease;
}

.pagination button:hover {
    background-color: #e0e0e0; /* Более тёмный серый при наведении */
}

.pagination button.active {
    background-color: #007bff; /* Синий для активной страницы */
    color: white;
    font-weight: bold;
}

.pagination button:focus {
    outline: none;
}

.custom-arrow-down{
    transform: rotate(180deg); /* Поворачивает SVG */
    color: gray; /* Задаёт серый цвет для currentColor */
}
.fuelUnit {

}
/*.fueltext {*/
/*    margin-top: -15px; !* Отступ сверху для текста *!*/
/*    font-size: 12px; !* Размер текста *!*/
/*}*/

/* Эффект при наведении на карточку */


.car-image {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: center;
    border-radius: 20px;

}

.company-name-overlay {
    position: absolute;
    top: 10px;
    left: 10px;
    /*background-color: #007bff;;*/
    color: white;
    border-radius: 5px;
    z-index: 1;
}

.car-details {
    margin-bottom: auto;
    padding: 10px;
    width: 100%;
    text-align: center;
    padding-bottom: 0;
}

.car-details h3 {
    margin: 0;
    font-size: 1.2em;

}

.car-details p {
    margin: 5px 0;

}

.LeftRight {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
    margin-top: -6px;
}

.right {
    margin: 0;
    text-align: left;
}

.Class-inf {
    margin: 0;
    text-align: right;
    background-color: #007bff;
    border-radius: 20px;
    padding: 10px;
    color: white;
}

/* Стили для блока с ценами */
.price-details {
    text-align: left;

    width: 100%;
}

.price-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
    position: relative;
}

.price-days {
    text-align: right;
    flex-shrink: 0;
    white-space: nowrap;
    margin-right: 10px;
    font-weight: bold;
}

.price-value {
    text-align: right;
    flex-shrink: 0;
    white-space: nowrap;
    font-weight: bold;
    color: var(--text-color-primary);
    font-size: 20px;
    position: relative;
}
.per-day {
    font-size: 80%; /* Уменьшение размера текста */
    position: absolute;
    top: -0.2em; /* Поднимаем текст чуть выше */
    left: 100%; /* Размещаем текст справа от числа */
    margin-left: 5px;
}
/* Псевдоэлемент для пунктира */

.datepicker{
width: 100%;
    border: 1px var(--primary-color) solid;
    border-radius: 2px;
    overflow: visible;

}
/* Стили для кнопки деталей */
.button-container{
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    width: 100%;

}
.bluetooth-label {
    margin-top: 5px; /* Отступ сверху для текста */
    font-size: 12px; /* Размер текста */

}
.separator {
    margin: 0 10px; /* Отступы по бокам для эстетики */
    font-weight: 100; /* Можно увеличить жирность черты, если нужно */
}
.details-button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 10px!important;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}
.details-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%; /* Кнопка на всю ширину */
    padding: 10px; /* Можно настроить padding по своему вкусу */
    border: none;
    cursor: pointer;
}

.car-detail-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.car-detail {
    flex: 1; /* Занимает равное пространство с другой стороной */
    text-align: center; /* Текст центрируется внутри каждой половины */
}

.separator {
    padding: 0 15px; /* Отступы вокруг черты для воздуха */
    font-weight: 200; /* Можно сделать жирнее черту */
    display: inline-block;
}

.buttonLine {
    border: none; /* Убираем стандартную рамку */
    width: 100px; /* Фиксированная ширина 1 пиксель */
    height: 100%; /* Делаем высоту 100%, чтобы растянуть по высоте родительского элемента */
    background-color: #fff; /* Цвет линии (можно настроить под нужный) */
    transform: rotate(90deg); /* Поворачиваем линию на 90 градусов для горизонтального размещения */
    margin: 0; /* Убираем отступы по умолчанию */
}

.btnBlock{
    justify-content: space-between;
    width: 100%;
    display: flex;
}
.details-button:hover {
    background-color: #0056b3;
}

.slick-slider {
    width: 100%;  /* Устанавливаем ширину слайдера */
    max-width: 100%; /* Ограничиваем ширину слайдера */
    height: auto;  /* Автоматическая высота */
    position: relative;  /* Позволяет позиционировать элементы внутри */
    overflow: hidden; /* Скрывает элементы за пределами слайдера */
    cursor: pointer;
}

.slick-slide img {
    width: 100%; /* Изображения должны занимать всю ширину контейнера */
    /*height: auto; !* Высота изображений должна быть автоматической *!*/
    object-fit: cover; /* Сохраняем пропорции изображения и обрезаем, если оно больше */
    object-position: center; /* Центрируем изображение */
}

.slick-list {
    overflow: hidden; /* Убедитесь, что элементы вне видимости скрыты */
}

.slick-track {
    display: flex; /* Делаем трек флекс-контейнером, чтобы элементы выравнивались корректно */
}

.slick-next {
    right: 8px;
}
.slick-prev {
    left: 8px;
    z-index: 10;
}



.Description{
    text-align: left;
    color: rgba(0, 0, 0, 0.63);
    font-size: 20px;

}

.details-button.collapsed {
    background-color: transparent; /* Убираем фон при открытии карточки */
    color: white;
    height: auto; /* Делаем высоту адаптивной */
}

.arrow-up {
    display: inline-block;
    font-family: none, sans-serif;
}

.details-button.collapsed .arrow-up {
    color: var(--button-alternative-background-color); /* Цвет стрелки остается синим, когда карточка открыта */
}


.DeclinedCar{
    color: red;
}

/* Медиазапросы для экранов меньше 400px */
@media (max-width: 385px) {
    .car-list {
        grid-template-columns: 1fr; /* Одна колонка на всю ширину экрана */

        gap: 10px; /* Небольшой отступ между элементами */
    }

    .car-card {
        margin: 0; /* Убираем внешний отступ снизу */
        width: 100%; /* Карточки занимают всю ширину контейнера */
    }

    .car-image {
        height: 200px; /* Уменьшаем высоту изображения */
        object-fit: cover; /* Обеспечиваем, чтобы изображение хорошо вписывалось в карточку */
    }

    .car-details h3 {
        font-size: 1em; /* Уменьшаем размер заголовка */
    }

    .details-button {
        padding: 8px 15px; /* Уменьшаем отступы кнопки */
        font-size: 14px; /* Уменьшаем размер шрифта кнопки */
    }
}
.cdc-share-container {
  position: relative;
  margin-bottom: 1rem;
}

.cdc-share-main-btn {
  padding: 0.5rem 1rem;
  background-color: #2B66FE;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.cdc-share-main-btn:hover {
  background-color: #1A4DBF;
  transform: scale(1.05);
}

.cdc-share-overlay {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  backdrop-filter: blur(4px);
  z-index: 1000;
  animation: fadeIn 0.2s ease-in;
  border-radius: 0 0 1rem 1rem;
}

.cdc-share-menu {
  background: var(--gradient-background);
  border-radius: 0 0 1rem 1rem;
  padding: 1rem;
  width: 100%;
  max-width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  animation: slideDown 0.3s ease-out;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.cdc-share-btn {
  padding: 0.75rem 1rem;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  font-size: 1rem;
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.cdc-share-btn:hover {
  transform: translateX(5px);
  opacity: 0.9;
}

.cdc-share-icon {
  width: 20px;
  height: 20px;
}

.cdc-share-twitter { background-color: #1DA1F2; }
.cdc-share-facebook { background-color: #3B5998; }
.cdc-share-whatsapp { background-color: #25D366; }
.cdc-share-telegram { background-color: #0088CC; }
.cdc-share-instagram { background-color: #E1306C; }

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes slideDown {
  from {
    transform: translateY(-10%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@media (max-width: 480px) {
  .cdc-share-main-btn {
    padding: 0.4rem 0.8rem;
    font-size: 0.9rem;
  }

  .cdc-share-btn {
    padding: 0.6rem 0.8rem;
    font-size: 0.9rem;
  }

  .cdc-share-icon {
    width: 16px;
    height: 16px;
  }

  .cdc-share-menu {
    max-width: 100%;
  }
}

/* Стили для случая без JavaScript */
.cdc-no-js {
  padding: 1rem;
  background: #f8d7da;
  color: #721c24;
  border-radius: 0.5rem;
}
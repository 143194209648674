.user-profile-media {
  padding: 10px;
}

.media-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.edit-media-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  transition: transform 0.2s ease;
}

.edit-media-btn:hover {
  transform: scale(1.1);
}

.edit-media-btn:active {
  transform: scale(0.95);
}

.media-upload {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.media-upload input {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 10px;
  background: #f5f5f5;
  transition: transform 0.2s ease;
}

.media-upload input:focus {
  transform: scale(1.02);
}

.user-profile-container.space .media-upload input,
.user-profile-container.future .media-upload input {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #ffffff;
}

.media-upload button {
  background: #007aff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  transition: transform 0.2s ease, background 0.2s ease;
}

.media-upload button:active {
  transform: scale(0.95);
}

.user-profile-container.space .media-upload button,
.user-profile-container.future .media-upload button {
  background: linear-gradient(45deg, #8a2be2, #00b7ff);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.media-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 4px;
}

.media-item-container {
  position: relative;
  width: 100%;
  aspect-ratio: 1;
}

.media-item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.media-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.media-item:active {
  transform: scale(0.95);
}

.media-likes {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 10;
}

.delete-media-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 20px;
  height: 20px;
  background: var(--warning-color);
  border-radius: 50%;
  border: 2px solid var(--neutral-color-1);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s ease, background 0.2s ease;
  z-index: 10;
}

.delete-media-btn:hover {
  transform: scale(1.1);
  background: var(--warning-hover-color);
}

.delete-media-btn:active {
  transform: scale(0.95);
}
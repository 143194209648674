/* CSS остаётся почти таким же, только добавим начальное состояние */
.back-button-container {
  position: fixed;
  top: 85px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 855px;
  display: flex;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 10px;
  z-index: 900;
  transition: opacity 0.3s ease, transform 0.3s ease;
  opacity: 0; /* Начальное состояние - скрыто */
  transform: translateX(-50%) translateY(-20px); /* Начальная позиция */
}

/* Состояние видимой кнопки */
.back-button-container.visible {
  opacity: 1;
  transform: translateX(-50%) translateY(0);
}

/* Состояние скрытой кнопки */
.back-button-container.hidden {
  opacity: 0;
  transform: translateX(-50%) translateY(-20px);
  pointer-events: none;
}

/* Остальной CSS остаётся без изменений */
.back-button {
  background-color: #2B66FE;
  border: none;
  border-radius: var(--border-radius-medium);
  padding: 8px 20px;
  color:var(--button-text-color);
  font-family: var(--font-family-primary);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  box-shadow: var(--shadow-glass);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}



.back-button:active {
  transform: scale(0.98);
}

/* Адаптивность */
@media (max-width: 1024px) {
  .back-button-container {
    top: 49px;
    max-width: 95%;
  }

  .back-button {
    padding: 6px 15px;
    font-size: 14px;
    margin-top:20px;
  }
}

@media (max-width: 600px) {
  .back-button-container {
    top: 60px;
    width: 100%;
    max-width: 100%;
  }

  .back-button {
    padding: 5px 12px;
    font-size: 13px;
  }
}
@import url('../../Services/variables/variables.css');

[class*="ymaps-"][class*="-ground-pane"] {
    /* No specific styling here */
}

.ymaps-2-1-79-map-bg {
    background-image: url(../img/w.svg) !important;
    background-size: 20px 20px !important;
    background-position: center;
}

.main_container {
    width: 100%;
    height: 100vh;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    box-sizing: border-box !important;
}

.ymaps-2-1-79-map-copyrights-promo,
.ymaps-2-1-79-copyright,
.ymaps-2-1-79-copyright_logo_no {
    display: none !important;
}

.react-map-gl {
    position: absolute;
}

.map-container,
.mapboxgl-canvas,
.mapboxgl-map {
    position: absolute !important;
    width: 100% !important;
    height: 100vh !important;
    box-sizing: border-box;
}

.mapboxgl-ctrl-top-right {
    position: relative !important;
}

.mapboxgl-ctrl-bottom-left,
.mapboxgl-ctrl-bottom-right,
.mapboxgl-ctrl-geocoder--powered-by {
    display: none !important;
}

.mapboxgl-ctrl-geocoder--icon-search {
    display: none;
}
.mapboxgl-ctrl-geocoder--input {
    outline: none !important;
    color: var(--neutral-color-7) !important;
    margin-left: 15px; /* Добавляем отступ слева от иконки */
    width: calc(100% - 40px) !important; /* Уменьшаем ширину, чтобы иконка не перекрывала текст */
    padding: 10px 20px; /* Регулируем отступы внутри поля */
    border-radius: 19px !important;
    box-shadow:none !important;
    font-family: var(--font-family-primary), sans-serif !important;
    font-size: var(--font-size-medium) !important;
    border: none !important;
}

.marker-subtitle {
    color: var(--text-color-fourth);
    font-size: 11px;
    padding: 2px 5px;
    border-radius: 3px;
    text-align: center;
    position: absolute;
    bottom: -18px; /* Смещение ниже маркера */
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    font-family: Monaco, monospace;
    text-shadow:

        0 0 0 var(--background-secondary-color),
        1px -1px 0 var(--background-secondary-color),
        -1px 1px 0 var(--background-secondary-color),
        1px 1px 0 var(--background-secondary-color); /* Белая обводка вокруг текста */
    font-weight: bold;
}

.marker-title {
    display: flex;
    align-items: center; /* Выравнивание по вертикали */
    justify-content: flex-start; /* Выравнивание по горизонтали (слева направо) */
    font-size: 14px; /* Размер шрифта */
    font-weight: bold; /* Жирный текст для акцента */
    color: var(--text-color-fourth); /* Цвет текста */
        position: relative; /* Устанавливаем относительное позиционирование */
    top: -10px; /* Сдвигаем выше */
    left: 38px; /* Сдвигаем левее */
}

.marker-title::before {
    content: '\f005'; /* Код звезды из FontAwesome */
    font-family: 'Font Awesome 5 Free'; /* Укажите имя шрифта */
    font-weight: 900; /* Толщина для прдавильного отображения */
    display: inline-block;
    margin-right: 5px;
    color: #2d92ff; /* Синий цвет звезды */
    font-size: 14px;
}
.custom-marker {
    width: 50px;
    height: 50px;
    transition: transform 0.3s, background-color 0.3s ease, box-shadow 0.3s ease;
    border-radius: 50%; /* Если хотите, чтобы маркер был круглый */
}

/* Эффект при наведении */
.custom-marker:hover {
    transform: scale(1.1);
    background-color: #00bfff; /* Яркий голубой цвет */
}

/* Эффект при нажатии */
.custom-marker:active {
    transform: scale(1.1);
    background-color: #5d76e1; /* Яркий голубой */
    box-shadow: 0 0 25px rgb(26, 132, 239); /* Более яркое свечение */
}

/* Класс для активного маркера, который остается при открытом попапе */
.custom-marker.active {
    background-color: #092487; /* Яркий голубой */
}



.suggestions {
    margin-top: 0 !important;
    border-radius: 0 !important;
}

.suggestions li {
    padding: 0 !important;
}

.mapboxgl-ctrl-geocoder,
.mapboxgl-ctrl-geocoder {
    display: flex !important;

    border-radius: 0 !important;
    justify-content: center !important;
    align-items: center !important;
    margin: 0 auto;
    width: 100% !important;
}

.map {
    width: 100% !important;
    height: 100vh !important;
}

.mapboxgl-ctrl-geocoder {
    max-width: 100%;
}

.toggle3d {
    position: fixed;
    width: 50px; /* Adjust width to accommodate icon and text */
    height: 50px; /* Adjust height */
    padding: 7px 5px; /* Adjust padding for better icon alignment */
    border-radius: var(--border-radius-medium);
    background-color: var(--primary-background);
    box-shadow: var(--box-shadow-light);
    right: 20px;
    bottom: 155px;
    z-index: 10000000000000;
    /*background-image: url(../img/4.png);*/
    background-size: 40px;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
    filter: grayscale(100);
    font-size: 14px; /* Adjust text size */
    color: var(--text-color-primary);
    cursor: pointer;
}

.locate3d {
    position: fixed;
    width: 20px;
    padding: 25px;
    border-radius: var(--border-radius-medium);
    background-color: var(--primary-background);
    box-shadow: var(--box-shadow-light);
    height: 20px;
    right: 20px;
    bottom: 175px;
    z-index: 10000000000000;
    background-image: url(../img/001.png);
    background-size: 40px;
    text-align: center;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
}

.toggle3d p {
    margin: 0;
    padding: 5px 0;
    font-size: 16px;
    pointer-events: none;
}
.locate-button {
    position: fixed;
    width: 50px;
    height: 50px;
    border-radius: var(--border-radius-medium);
    background-color: var(--primary-background);
    box-shadow: var(--box-shadow-light);
    right: 20px;
    bottom: 225px;
    z-index: 1000;
    font-size: 24px;
    color: var(--neutral-color-7);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.pointa {
    width: 30px;
    padding-bottom: 5px;
    height: 30px;
}

.toggle3d.active {
    filter: grayscale(0);
}

.map_container {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: Calc(100vh);
}
.popup-content {
    cursor: pointer; /* Change cursor to indicate clickable */
}
.popup-content h3 {
    font-size: 16px;
    font-weight: bold;
    margin: 5px 0;
}
.popup-title {
    font-size: 16px;
    font-weight: bold;
    margin: 0 0 8px;
    text-align: center;
    color: #333;
}

.popup-image {
    width: 100%; /* Adjust the size as needed */
    height: auto; /* Maintain aspect ratio */
}

/* Mapbox styles */

.mapboxgl-ctrl-top-right .mapboxgl-ctrl {
    position: absolute;
    right: 20px;
    top: 160px;
    margin: 0 !important;
}

@media screen and (max-width: 1024px) {
    .mapboxgl-ctrl-top-right .mapboxgl-ctrl {
        top: 130px;
        right: 10px;
    }


    .locate-button {
        right: 10px;
        bottom: 200px;
    }

    .toggle3d{
        right: 10px;
        bottom: 130px;
    }

}

@media screen and (max-width: 600px) {
    .mapboxgl-ctrl-top-right .mapboxgl-ctrl {
        top: 110px;
    }


    .locate-button {
        bottom: 170px;
    }

    .toggle3d{
        bottom: 100px;
    }
}



/* Контейнер, объединяющий гифку и поиск */
.search-and-gif-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 160px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
    width: 60%;
    max-width: 400px;



}
/* Стиль для иконки поиска (лупы) */
.geocoder-container .mapboxgl-ctrl-geocoder--icon-search {
    margin-right: 20px; /* Добавляем отступ между иконкой и текстом */
    font-size: 20px; /* Можно настроить размер иконки при необходимости */
}
/* Гифка */
    .gif-container-for-map {
    position: absolute;
    align-items: center;
        margin-left: 275px;
    justify-content: right;
        z-index: 10;

}

.gif-container-for-map img {
    width: 20px;
    height: auto;

}

/* Поисковая строка */
.geocoder-container {
    flex-grow: 1;


}

.geocoder-container .mapboxgl-ctrl-geocoder {
    width: 100%;
    font-size: 16px;
    border-radius:20px!important;
    display: flex;
      align-items: center;

    background-color: var(--background-color);

}
.geocoder-input {
    width: 100%; /* Ширина поля ввода */
    padding: 10px 40px 10px 10px; /* Отступы: справа достаточно места для GIF */
    box-sizing: border-box; /* Учитывает отступы в ширине */
}

/* Изменение стилей на мобильных устройствах */
@media (max-width: 768px) {
    .search-and-gif-container {
        width: 50%;
        position: absolute;
        top: 130px;

    }
    .gif-container-for-map img {
        width: 20px;
    }
}
@media (max-width: 600px) {
    .search-and-gif-container {
         max-width: 320px;
        top: 80px;
    }
    .mapboxgl-ctrl-geocoder--icon-search {
        margin-right: 15px; /* Добавляем отступ справа от иконки */
    }
     .mapboxgl-ctrl-geocoder--input{
        margin-left: 15px;
    }
       .search-and-gif-container {
        left: 54%;
    }
}

/* Изменения для мобильных устройств */
@media (max-width: 480px) {
    .search-and-gif-container {

    }
    /* Устанавливаем отступ между иконкой поиска и полем ввода */
    .geocoder-container .mapboxgl-ctrl-geocoder--icon-search {
        margin-right: 7px; /* Устанавливаем отступ в 7px */
    }
    .mapboxgl-ctrl-geocoder--input{
        margin-left: 15px!important;
    }
    .gif-container-for-map img {
        width: 20px;
        padding-right: 10px!important;
    }
}

/* Для очень маленьких экранов (меньше 360px) */
@media (max-width: 360px) {
    .search-and-gif-container {
        width: 10%;
        padding: 5px;
    }
    .gif-container-for-map img {
        width: 20px;
    }
    .geocoder-container .mapboxgl-ctrl-geocoder {
        font-size: 14px;
    }
}

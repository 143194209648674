.profile-icon {
    /*overflow: hidden;*/
    cursor: pointer;
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    height: 50px;
    width: 50px;
}

.profile-image {
    object-fit: cover;
    border-radius: 50%;
    width: 50px;
    height: 50px;
}

.default-user-icon {
    font-size: 32px;
    color: var(--text-color-primary);
}

@media screen and (max-width: 1024px) {
    .profile-image, .profile-icon {
        width: 40px;
        height: 40px;
    }
}

@media screen and (max-width: 600px) {
    .profile-image, .profile-icon {
        width: 20px;
        height: 20px;
    }
}

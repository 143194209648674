.chat-history {
  padding: 10px;
  overflow-y: auto;
  height: 100%;
}

.chat-history h3 {
  color: #d0d8e0;
  font-size: 16px;
  margin: 10px 0;
  padding-left: 15px;
}

.chat-history ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.chat-history-item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  cursor: pointer;
  transition: background 0.2s ease;
}

.chat-history-item:hover {
  background: rgba(255, 255, 255, 0.06);
}

.chat-history-item.pinned {
  background: rgba(30, 40, 60, 0.9);
  order: -1;
}

.chat-history-item .avatar {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 12px;
}

.chat-info {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.chat-name {
  font-size: 15px;
  color: #d0d8e0;
  font-weight: 500;
}

.last-message {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}

.unread-count {
  background: #3390ec; /* Синий цвет, как в Telegram */
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 12px;
  line-height: 14px; /* Увеличиваем для соответствия высоте */
  min-width: 14px; /* Увеличиваем с 12px до 14px */
  height: 14px; /* Увеличиваем с 12px до 14px */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto; /* Перемещаем вправо */
  align-self: flex-start; /* Поднимаем вверх */
}

.chat-history::-webkit-scrollbar {
  width: 6px;
}

.chat-history::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}

.chat-history::-webkit-scrollbar-track {
  background: transparent;
}
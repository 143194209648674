@import url('../../../Services/variables/variables.css');

/* General Category Bar Styles */
.category-bar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: var(--background-highlight-color);
    padding: 10px;
    border-radius: var(--border-radius-medium);
    z-index: 999;
}
.category-bar.hidden {
    transform: translateY(-600px) !important;
    transition: transform 0.5s ease-in-out;
}
.category-items-container::-webkit-scrollbar {

    width: 5px;
    height: 5px;
    border-radius: var(--border-radius-medium);

}
.category-items-container::-webkit-scrollbar-thumb {
    background-color: var(--primary-color); /* Цвет ползунка */
    border-radius: var(--border-radius-medium);

}
.category-items-container::-webkit-scrollbar-track{
    margin: 0 15px; /* Оставляет пустое пространство по краям */
}
/* Specific styles for Product List */
.category-bar.product-list {
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    position: fixed;
    box-shadow: none;
    backdrop-filter: none;
    top: 120px;
    background: var(--primary-background);
    left: 0;
    right: 0;
    width: 40%;
    min-width: 741px;
    padding: 0;
    border-radius: 20px;

    white-space: nowrap;
    transition: transform 0.5s ease-in-out;
}

.product-list .category-items-container {
    display: flex;
    padding: 0;
    margin: 0 auto;
    width: 100%;
    max-height: 120px;
    height: 120px;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;

}



.product-list .category-bar__item {
    flex: 1 0 21%;
    min-width: 170px;
    margin: 0;
    height: 100%;
}

.product-list .category-button {
    height: 120px;
    border-radius: 0;
    padding: 0;
    border-top: none;
    border-left: none;
    border-bottom: none;
    border-right: none;
}

.product-list .category-button:hover {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.product-list .category-button i {
    margin-bottom: 20px;
    font-size: 32px;
}

.product-list .category-bar__item:not(:last-of-type) .category-button {
    border-right: 1px solid var(--primary--category--border-color);
    border-radius: 20px;
}

.product-list .category-bar__item.active .category-button {
    border-right: 1px solid var(--primary--category--border-color);
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
}

.product-list .category-name {
    height: 100%;
    max-height: 20px;
    font-family: Oswald, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


/* Specific styles for Map View */
.category-bar.map-view {
    position: absolute;
    top: 215px;
    left: 20px;
    backdrop-filter: blur(5px);
    box-shadow: var(--box-shadow-medium);
    background-color: rgba(255, 255, 255, 0.25);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
    padding: 0;
    max-height: calc(100vh - 350px);

}

/* Mobile version */
.map-view .category-items-container {
    display: flex;
    flex-direction: column;
    width: 100px;
    gap: 10px;
    padding: 0;

    max-height: 100%;
}
.category-bar__item.active .category-button {
    background-color: #007bff; /* Цвет фона для активной категории */
    color: white; /* Цвет текста для активной категории */
    border-radius: 8px; /* Закругленные края для красоты */
}

.category-bar__item.active .category-button i {
    color: white; /* Цвет иконки для активной категории */
}


@media (min-width: 768px) {
    .map-view .category-items-container {
        width: 200px;
        gap: 10px;
        padding: 10px;
        overflow-y: auto;
        max-height: calc(100vh - 150px);
    }
}

/* Hide scrollbar */
.map-view .category-items-container {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.map-view .category-items-container::-webkit-scrollbar {
    display: none;
}

.map-view.show-all .category-items-container {
    display: flex;
}

.toggle-button-container {
    position: absolute;
    top: 160px;
    left: 20px;
    transform: translateY(-50%);
    z-index: 2000;
}

.toggle-category-button {
    background-color: var(--primary-color);
    color: var(--button-text-color);
    border: none;
    border-radius: 50%; /* Circular button */

    padding: 10px;
    width: 50px; /* Increased size for better visibility */
    height: 50px; /* Increased size for better visibility */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* AddForm a shadow to make it more prominent */
}

.toggle-category-button:hover {
    background-color: var(--primary-hover-color);
    transform: scale(1.05);
}

.toggle-category-button i {
    font-size: 20px; /* Adjust icon size */
    color: var(--button-text-color); /* Ensure the icon color matches the text */
}

/* Category Item Styles */
.category-bar__item {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 50%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
}

.category-button {
    background: none;
    /*border: 1px solid var(--neutral-color-4);*/
    /*border-radius: var(--border-radius-large);*/
    cursor: pointer;
    font-size: var(--font-size-medium);
    color: var(--text-color-primary);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    width: 100%;
    height: 80px;
    transition: border-color 0.3s, background-color 0.3s;
}

.category-button i {
    font-size: 24px;
    margin-bottom: 5px;
}

.category-bar__item.active .category-button {
    color: var(--primary-color);
    border-color: var(--primary-color);
    background-color: var(--hover-background-color);
}

.category-bar__item.active .category-button i {
    color: var(--primary-color);
}

.category-button:hover {
    border-color: var(--primary-color);
    background-color: var(--hover-background-color);
}

.category-name {
    color: var(--text-color-primary);
}

/* Scroll Arrow */

.scroll-arrow {
    left: 50%;  /* Center it horizontally */
    transform: translateX(-50%);
    position: fixed;
    top: 120px;
    bottom: 0;
    right: 0;
    z-index: 1001;
    background: var(--primary-background);
    color: white;
    border: none;
    border-radius: 20px;
    width: 160px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: transform 0.5s ease-in-out, background-color 0.5s ease-in-out, opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, top 0.5s ease-in-out;
}

.scroll-arrow.hidden {
    top: -100px;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, visibility 0.5s ease-in-out, top 0.5s ease-in-out;
}

.scroll-arrow:hover {
    background-color: var(--primary-button-background);
}

.scroll-arrow.up i {
    transform: rotate(180deg);
}
.scroll-arrow svg {
    width: 20px;
    height: 20px;
}

@media screen and (max-width: 1600px) {

}
@media screen and (max-width: 1400px) {

}


@media screen and (max-width: 1024px) {
    .category-bar.product-list {
        min-width:  655px;
        top: 114px;
    }
    .scroll-arrow {
        top: 81px;
    }

    /* MAP VIEW */

    .toggle-button-container {
        top: 150px;
        left: 10px;
    }

    .category-bar.map-view {
        top: 190px;
    }
}

@media screen and (max-width: 700px) {
    .category-bar.product-list {
        min-width:  90%;
    }
}

@media screen and (max-width: 600px) {
    .category-bar.product-list {
        top: 84px;
    }


    .product-list .category-bar__item {
        height: 100%;
        min-width: 108px;

        margin: auto;
    }

    .product-list .category-bar__item .category-bar__item .category-button {
        height: 100%;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
    }

    .product-list .category-bar__item .category-bar__item .category-button:hover {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .product-list .category-bar__item.active .category-bar__item {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    /*.product-list .category-bar__item:not(:last-of-type) .category-button:hover {*/
    /*    border-top-left-radius: 0;*/
    /*    border-bottom-left-radius: 0;*/
    /*}*/
    .product-list .category-button {
        height: 74px;
    }

    .product-list .category-bar__item.active .category-button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }
    .product-list .category-bar__item:not(:last-of-type) .category-button {
        border-radius: 0;
    }
    .product-list .category-button i {
        margin-bottom: 10px;
        font-size: 24px;
    }
    .product-list .category-name {
        font-size: 10px;
    }

    .scroll-arrow {
        display: none;
    }

    .product-list .category-items-container {
        height: 74px;
    }

    /* MAP VIEW */

    .category-bar.map-view {
        top: 140px;
        max-height: calc(100vh - 250px);
    }

    .toggle-button-container {
        top: 100px;
    }
}

@media screen and (max-width: 428px) {

}

@media screen and (max-width: 410px) {
    .product-list .category-bar__item {


        min-width: 108px;
    }

}

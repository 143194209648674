@import url('../../../../Services/variables/variables.css');

.user-profile-header {
  text-align: center;
  padding: 20px 0;
  margin-top: 45px;
  background: var(--glass-background);
  border-radius: var(--border-radius-large);
  box-shadow: var(--shadow-glass);
}

.header-top {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.menu-btn {
  position: absolute;
  right: 10px;
  top: calc(50% + 12px);
  transform: translateY(-50%);
  background: var(--glass-background-secondary);
  border: 1px solid var(--border-glass);
  cursor: pointer;
  padding: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: transform 0.2s ease, background 0.2s ease;
}

.menu-btn:hover {
  transform: translateY(-50%) scale(1.1);
  background: var(--gradient-button-hover);
}

.menu-btn:active {
  transform: translateY(-50%) scale(0.95);
}

.avatar-menu {
  position: absolute;
  top: 70px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background: var(--glass-background);
  padding: 10px;
  border-radius: var(--border-radius-medium);
  box-shadow: var(--shadow-glass-strong);
  z-index: 10;
}

.avatar-menu input[type="color"] {
  width: 30px;
  height: 30px;
  padding: 0;
  border: none;
  cursor: pointer;
  border-radius: 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: none;
}

.avatar-menu input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}

.avatar-menu input[type="color"]::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
}

.avatar-menu input[type="color"]::-moz-color-swatch {
  border: none;
  border-radius: 50%;
}

.highlight-toggle-btn {
  background: var(--gradient-button);
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: var(--border-radius-small);
  color: var(--text-color-primary);
  font-size: var(--font-size-small);
  transition: transform 0.2s ease, background 0.2s ease;
}

.highlight-toggle-btn:hover {
  transform: scale(1.05);
  background: var(--gradient-button-hover);
}

.highlight-toggle-btn:active {
  transform: scale(0.95);
}

.add-avatar-btn {
  width: 30px;
  height: 30px;
  background: var(--gradient-button);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s ease, background 0.2s ease;
}

.add-avatar-btn:hover {
  transform: scale(1.1);
  background: var(--gradient-button-hover);
}

.add-avatar-btn:active {
  transform: scale(0.95);
}

.delete-mode-btn {
  width: 30px;
  height: 30px;
  background: var(--warning-color);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s ease, background 0.2s ease;
}

.delete-mode-btn:hover {
  transform: scale(1.1);
  background: var(--warning-hover-color);
}

.delete-mode-btn:active {
  transform: scale(0.95);
}

.close-menu-btn {
  width: 30px;
  height: 30px;
  background: transparent; /* Прозрачный фон */
  border: none; /* Убираем границу */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.close-menu-btn:hover {
  transform: scale(1.1);
}

.close-menu-btn:active {
  transform: scale(0.95);
}

.avatar-container {
  position: relative;
  margin-bottom: 15px;
  margin-top: 10px;
}

.user-profile-header .avatar {
  width: 170px;
  height: 170px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;
  border: 3px solid var(--neutral-color-1);
  box-shadow: var(--shadow-glass);
  cursor: pointer;
  transition: transform 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;
}

@media (min-width: 769px) {
  .user-profile-header .avatar:hover {
    border-color: var(--primary-color);
    box-shadow: 0 0 15px var(--primary-color);
    transform: scale(1.05);
  }
}

.user-profile-header .avatar:active {
  transform: scale(0.95);
}

.avatar-thumbnails {
  display: flex;
  overflow-x: auto;
  gap: 8px;
  padding: 5px;
  max-width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.avatar-thumbnails::-webkit-scrollbar {
  display: none;
}

.avatar-thumbnail-wrapper {
  position: relative;
}

.avatar-thumbnail {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid var(--neutral-color-1);
  opacity: 0.7;
  transition: opacity 0.2s ease, border-color 0.2s ease, transform 0.2s ease;
}

.avatar-thumbnail:hover {
  opacity: 1;
  transform: scale(1.05);
}

.avatar-thumbnail:active {
  transform: scale(0.95);
}

.avatar-thumbnail.selected {
  opacity: 1;
  border-color: var(--primary-color);
}

.delete-avatar-btn {
  position: absolute;
  top: -5px;
  right: -5px;
  width: 20px;
  height: 20px;
  background: var(--warning-color);
  border-radius: 50%;
  border: 2px solid var(--neutral-color-1);
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease, background 0.2s ease;
}

.delete-avatar-btn:hover {
  transform: scale(1.1);
  background: var(--warning-hover-color);
}

.delete-avatar-btn:active {
  transform: scale(0.95);
}

.user-profile-header h1 {
  font-size: var(--font-size-xl);
  font-weight: 600;
  margin: 0 0 5px;
  color: var(--text-color-primary);
}

.user-profile-header .status {
  font-size: var(--font-size-medium);
  color: var(--text-color-secondary);
  margin: 0 0 10px;
}

.follow-stats {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 15px;
}

.follow-stats span {
  font-size: var(--font-size-medium);
  cursor: pointer;
  color: var(--text-color-tertiary);
  transition: color 0.2s ease;
}

.follow-stats span:hover {
  color: var(--primary-color);
}

.follow-link {
  font-weight: 500;
}

.profile-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.profile-actions button {
  background: var(--gradient-primary);
  color: var(--button-text-color);
  border: none;
  padding: 8px 16px;
  border-radius: var(--border-radius-medium);
  font-size: var(--font-size-medium);
  font-weight: 500;
  cursor: pointer;
  transition: transform 0.2s ease, background 0.2s ease;
}

.profile-actions button:hover {
  background: var(--gradient-primary-hover);
}

.profile-actions button:active {
  transform: scale(0.95);
}

@media (max-width: 768px) {
  .user-profile-header .avatar {
    width: 80px;
    height: 80px;
  }

  .avatar-thumbnail {
    width: 40px;
    height: 40px;
  }

  .user-profile-header h1 {
    font-size: var(--font-size-large);
  }

  .user-profile-header .status {
    font-size: var(--font-size-small);
  }

  .follow-stats {
    gap: 15px;
  }

  .profile-actions button {
    padding: 6px 12px;
    font-size: var(--font-size-small);
  }

  .avatar-menu {
    top: 60px;
    right: 5px;
  }
}

@media (max-width: 480px) {
  .user-profile-header .avatar {
    width: 60px;
    height: 60px;
  }

  .avatar-thumbnail {
    width: 30px;
    height: 30px;
  }

  .user-profile-header h1 {
    font-size: var(--font-size-medium);
  }

  .user-profile-header .status {
    font-size: var(--font-size-small);
  }

  .follow-stats {
    flex-direction: column;
    gap: 5px;
  }

  .profile-actions {
    flex-direction: column;
    gap: 5px;
  }

  .profile-actions button {
    padding: 6px 10px;
    font-size: var(--font-size-small);
  }
}
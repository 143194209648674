@import url('../../Services/variables/variables.css');


.auth-page-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Растягивание на весь экран */
}
.auth-page-container{

    width: 100%;
    margin-bottom: 100px;

}
.auth-page-header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px; /* Добавляем промежутки между элементами */
    background-color: var(--glass-background);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;

}

.auth-close-button {
    width: 40px; /* Задаем ширину */
    height: 40px; /* Делаем высоту равной ширине */
    padding: 0; /* Убираем внутренние отступы */
    background-color: #ff9f9f;
    border-radius: 20px; /* Делаем кнопку квадратной */
    border: none;
    font-weight: bold;
    font-size: 24px; /* Уменьшаем, чтобы иконка хорошо помещалась */
    color: white;
    margin-left: auto; /* Прижимает кнопку вправо */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 10px;
}

.login-switch{
    padding: 10px;
    background-color: var(--background-secondary-color);
    margin:10px;
    border-radius: 20px;
    cursor: pointer;

}
.active-link{
    padding: 10px;
    background-color: var(--neutral-color-6);
    margin:10px;
    border-radius: 20px;
}
@media (max-width: 550px) {
    .login-switch{
       font-size: 18px;
    }
    .active-link{
        font-size: 18px;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

* {
  padding: 0;
  box-sizing: border-box;
}

body, html {
  font-family: var(--font-family-primary, 'Oswald'), sans-serif;
  margin: 0;
  padding: 0;
}

body {
  background-color: var(--background-color);
  color: var(--text-color-primary);
  transition: background-color 0.3s ease, color 0.3s ease;
}

.App {
  min-height: 100vh;
  font-family: var(--font-family-primary, 'Oswald'), sans-serif;
  color: var(--text-color-primary);
}
.app-content {
  flex: 1; /* Растягиваем контент между шапкой и навигацией */
  overflow-y: auto; /* Разрешаем прокрутку контента */
}

a {
  color: var(--text-color-primary);
  text-decoration: none;
}

a:hover {
  color: var(--primary-hover-color);
}

h1, h2, h3, h4, h5, h6 {
  color: var(--text-color-primary);
}

input, textarea, .input {
  background-color: var(--background-secondary-color);
  border: 1px solid var(--neutral-color-6);
  color: var(--text-color-primary);
  padding: 10px;
  border-radius: var(--border-radius-small);
  transition: background-color 0.3s ease, border-color 0.3s ease;
}

input:focus, textarea:focus {
  outline: none;
  border-color: var(--primary-color);
}

.error-forbiden {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-required-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  text-align: center;
  padding: 20px;
}

.auth-required-message h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 10px;
}

.auth-required-message p {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 20px;
}

.auth-required-message button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.auth-required-message button:hover {
  background: #0056b3;
}

/* Адаптивность */
@media (max-width: 768px) {
  .App {
    padding: 10px;
  }

  .auth-required-message h2 {
    font-size: 1.5rem;
  }

  .auth-required-message p {
    font-size: 1rem;
  }

  .auth-required-message button {
    padding: 8px 16px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .App {
    padding: 5px;
  }

  .auth-required-message h2 {
    font-size: 1.2rem;
  }

  .auth-required-message p {
    font-size: 0.9rem;
  }

  .auth-required-message button {
    padding: 6px 12px;
    font-size: 0.8rem;
  }
}
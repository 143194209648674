/* Импорт переменных (если используется) */
@import url('../../Services/variables/variables.css');

/* Основной контейнер */
.CrmContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh; /* Полная высота экрана */
    padding: 20px;
    margin-top: 0; /* Убираем фиксированные отступы для адаптивности */
    margin-bottom: 0;
}

/* Текст имени */
.name-text-Crm {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: 27px 0 36px;
    padding: 10px;
    font-size: 19px;
    font-weight: 600;
    color: var(--text-color-primary, #333);
    border-radius: 15px;
    backdrop-filter: blur(10px); /* Эффект стекла */
}

/* Иконка CRM */
.crmIcon {
    width: 100px;
    padding: 10px;
    margin: 20px;
    cursor: pointer;
    opacity: 0.9;
    flex-shrink: 0; /* Предотвращаем сжатие иконок */
    border-radius: 15px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.crmIcon.active {
    opacity: 1;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0 6px 20px rgba(0, 123, 255, 0.2);
    transform: scale(1.05);
}

/* Контейнер для горизонтального скролла иконок */
.IconFlex {
    display: flex;
    gap: 20px; /* Расстояние между категориями */
    margin-bottom: 20px;
    overflow-x: auto; /* Включаем горизонтальную прокрутку */
    white-space: nowrap; /* Запрещаем перенос элементов */
    flex-wrap: nowrap; /* Отключаем перенос элементов */
    justify-content: flex-start; /* Выравнивание в начале для прокрутки */
    width: 100%; /* Устанавливаем ширину контейнера */
    padding: 10px;
    scrollbar-width: thin; /* Тонкий скролл-бар для Firefox */
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(10px); /* Эффект стекла */
}

.IconFlex::-webkit-scrollbar {
    height: 8px; /* Высота полосы прокрутки для WebKit-браузеров */
}

.IconFlex::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2); /* Цвет полосы прокрутки */
    border-radius: 10px; /* Закругляем полосу прокрутки */
}

/* Контейнер кнопки "Назад" */


/* Контейнер сетки */
.DisGrid {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    border-radius: 15px;
    /*box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);*/
    backdrop-filter: blur(5px);
    transition: all 0.3s ease;
}

.DisGrid img {
    width: 63px;
    height: 63px;
    margin-bottom: 10px;
    transition: all 0.3s ease;
}

.DisGrid:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.active-category {
    background: rgba(0, 123, 255, 0.1);
    color: #007bff;
    box-shadow: 0 4px 15px rgba(0, 123, 255, 0.2);
}

.active-category img {
    filter: brightness(1);
    transform: scale(1.1);
}

/* Медиа-запросы для адаптивности */
@media (max-width: 600px) {
    .IconFlex {
        justify-content: flex-start;
        padding: 5px;
    }

    .crmIcon {
        width: 80px;
        margin: 10px;
    }

    .DisGrid img {
        width: 50px;
        height: 50px;
    }

    .name-text-Crm {
        font-size: 16px;
        margin: 20px 0 25px;
    }

=

    .CrmContainer {
        padding: 10px;
    }
}
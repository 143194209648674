/* FlatDetailCard.css */
@import url('../../../Services/variables/variables.css');

.cdc-app-container {
  width: 100%;
  padding: 1rem;
  margin: 0;
  overflow-y: auto;
  position: relative; /* Добавляем для правильного контекста фиксированного позиционирования */
}

.cdc-main {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding-top: 85px; /* Учитываем высоту BackButton */
}

/* Убедимся, что BackButton не перекрывает контент */
.back-button-container {
  position: fixed;
  top: 85px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 855px;
  display: flex;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 10px;
  z-index: 900;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.cdc-gallery-container {
  flex: 1;
}

.cdc-title {
  font-size: 2rem;
  margin-bottom: 1rem;
}

/* ... (остальные стили без изменений) ... */

@media (max-width: 768px) {
  .cdc-main {
    gap: 1rem;
    padding-top: 70px; /* Уменьшаем отступ для адаптивности */
  }

  .cdc-title {
    font-size: 1.5rem;
  }

  .cdc-thumbnail-item {
    width: 80px;
    height: 50px;
  }



}

@media (max-width: 480px) {
  .cdc-app-container {
    padding: 0.5rem;
  }

  .cdc-title {
    font-size: 1.25rem;
  }

  .cdc-main {
    padding-top: 60px;
  }




}
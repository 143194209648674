.group-create-modal {
  padding: 20px;
  background: rgba(20, 30, 48, 0.95);
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  max-width: 500px;
  width: 90%;
  margin: 20px auto;
  color: #d0d8e0;
  max-height: calc(100vh - 40px); /* Учитываем отступы сверху и снизу */
  overflow-y: auto; /* Включаем вертикальный скролл для всей формы */
}

.group-create-modal::-webkit-scrollbar {
  width: 6px; /* Ширина скролл-бара */
}

.group-create-modal::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2); /* Цвет ползунка */
  border-radius: 3px;
}

.group-create-modal::-webkit-scrollbar-track {
  background: transparent; /* Прозрачный фон трека */
}

.group-create-modal h2 {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 20px;
  text-align: center;
  font-weight: 500;
}

.group-create-modal form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-size: 1rem;
  color: #d0d8e0;
}

.group-create-modal input,
.group-create-modal select {
  padding: 12px 15px;
  font-size: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.08);
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s ease, background 0.3s ease;
}

.group-create-modal input:focus,
.group-create-modal select:focus {
  border-color: #00b4d8;
  background: rgba(255, 255, 255, 0.12);
  outline: none;
}

.group-photo-preview {
  max-width: 100px;
  max-height: 100px;
  border-radius: 8px;
  margin-top: 10px;
  object-fit: cover;
}

.user-selection {
  margin-top: 15px;
}

.user-selection h3 {
  font-size: 1.2rem;
  color: #fff;
  margin-bottom: 15px;
  font-weight: 500;
}

.user-search {
  padding: 12px 15px;
  font-size: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  background: rgba(255, 255, 255, 0.08);
  color: #fff;
  width: 100%;
  box-sizing: border-box;
  transition: border-color 0.3s ease, background 0.3s ease;
}

.user-search:focus {
  border-color: #00b4d8;
  background: rgba(255, 255, 255, 0.12);
  outline: none;
}

.user-selection ul {
  list-style: none;
  padding: 0;
  max-height: 200px;
  overflow-y: auto; /* Сохраняем скролл для списка пользователей */
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  margin-top: 10px;
}

.user-selection ul::-webkit-scrollbar {
  width: 6px;
}

.user-selection ul::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 3px;
}

.user-selection ul::-webkit-scrollbar-track {
  background: transparent;
}

.user-selection li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  transition: background 0.2s ease;
}

.user-selection li:hover {
  background: rgba(255, 255, 255, 0.06);
}

.user-selection li:last-child {
  border-bottom: none;
}

.user-selection li span {
  font-size: 1rem;
  color: #d0d8e0;
}

.user-selection li button {
  background: transparent;
  border: none;
  color: #00b4d8;
  font-size: 1.2rem;
  cursor: pointer;
  padding: 0 5px;
  transition: color 0.3s ease;
}

.user-selection li button:hover {
  color: #009bbd;
}

.user-selection li button.add-btn {
  color: #00cc00;
}

.user-selection li button.remove-btn {
  color: #ff4444;
}

.modal-actions {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 42px;
}

.modal-actions button {
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease;
}

.modal-actions .cancel-btn {
  background: rgba(255, 255, 255, 0.1);
  color: #d0d8e0;
}

.modal-actions .cancel-btn:hover {
  background: rgba(255, 255, 255, 0.2);
}

.modal-actions button[type="submit"] {
  background: linear-gradient(135deg, #007bff, #00b4d8);
  color: #fff;
}

.modal-actions button[type="submit"]:hover:not(:disabled) {
  background: linear-gradient(135deg, #0056b3, #009bbd);
}

.modal-actions button:disabled {
  background: rgba(255, 255, 255, 0.3);
  cursor: not-allowed;
}

/* Адаптивность */
@media (max-width: 480px) {
  .group-create-modal {
    padding: 15px;
    margin: 10px auto;
  }

  .group-create-modal h2 {
    font-size: 1.3rem;
  }

  .form-group label {
    font-size: 0.9rem;
  }

  .group-create-modal input,
  .group-create-modal select,
  .user-search {
    padding: 10px 12px;
    font-size: 0.9rem;
  }

  .user-selection h3 {
    font-size: 1.1rem;
  }

  .user-selection li {
    padding: 8px 12px;
  }

  .user-selection li span {
    font-size: 0.9rem;
  }

  .modal-actions button {
    padding: 10px 15px;
    font-size: 0.9rem;
  }
}
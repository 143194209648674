body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.user-profile-container {
  width: 100%;@import url('../../../../Services/variables/variables.css');

body {
  margin: 0;
  font-family: var(--font-family-primary);
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.user-profile-container {
  width: 100%;
  margin: 0;
  padding: 20px;
  min-height: 100vh;
  box-sizing: border-box;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  background: var(--background-color);
}

.user-profile-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('https://www.transparenttextures.com/patterns/stardust.png'); /* Звездное небо */
  background-size: cover;
  background-repeat: repeat;
  opacity: 0.3; /* Прозрачность для всех тем */
  pointer-events: none;
  z-index: 0;
}

.white .user-profile-container {
  background: var(--gradient-background);
}

.white .user-profile-container::before {
  opacity: 0.4; /* Чуть ярче звезды для светлой темы */
}

.night-mode .user-profile-container {
  background: var(--gradient-background);
}

.night-mode .user-profile-container::before {
  opacity: 0.6; /* Более заметные звезды для темной темы */
}

.whiteLand .user-profile-container {
  background: var(--gradient-background);
}

.whiteLand .user-profile-container::before {
  opacity: 0.5; /* Средняя яркость для WhiteLand */
}

.pureDark .user-profile-container {
  background: var(--gradient-background);
}

.pureDark .user-profile-container::before {
  opacity: 0.7; /* Максимальная видимость звезд для PureDark */
}

.theme-toggle-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: var(--glass-background-secondary);
  border: 1px solid var(--border-glass);
  cursor: pointer;
  padding: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  transition: transform 0.2s ease, background 0.2s ease;
  z-index: 10;
}

.theme-toggle-btn:hover {
  transform: scale(1.1);
  background: var(--gradient-button-hover);
}

.theme-toggle-btn:active {
  transform: scale(0.95);
}

.back-button {
  position: fixed;
  top: 10px;
  left: 10px;
  background: var(--glass-background-secondary);
  border: 1px solid var(--border-glass);
  font-size: var(--font-size-medium);
  color: var(--primary-color);
  cursor: pointer;
  padding: 6px;
  border-radius: 50%;
  transition: transform 0.2s ease, background 0.2s ease;
  z-index: 10;
}

.back-button:hover {
  background: var(--gradient-button-hover);
}

.back-button:active {
  transform: scale(0.95);
}

.user-profile-content {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.loading,
.not-found {
  text-align: center;
  padding: 15px;
  font-size: var(--font-size-large);
  color: var(--text-color-secondary);
  background: var(--glass-background);
  border-radius: var(--border-radius-medium);
  box-shadow: var(--shadow-glass);
}
  margin: 0;
  padding: 0;
  min-height: 100vh;
  box-sizing: border-box;
  transition: all 0.3s ease;
  position: relative;
}

.user-theme-toggle-btn {
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  z-index: 10;
}

.back-button {
  position: fixed;
  top: 10px;
  left: 10px;
  background: rgba(0, 0, 0, 0.05);
  border: none;
  font-size: 18px;
  color: #007aff;
  cursor: pointer;
  z-index: 10;
  padding: 6px;
  border-radius: 50%;
  transition: transform 0.2s ease;
}

.back-button:active {
  transform: scale(0.95);
}

.user-profile-content {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.user-profile-header {
  text-align: center;
  padding: 20px 0;
}

.avatar-container {
  position: relative;
  margin-bottom: 15px;
  margin-top: 53px;
}

.user-profile-header .avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;
  border: 2px solid #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s ease;
}

.user-profile-header .avatar:active {
  transform: scale(0.95);
}

.avatar-thumbnails {
  display: flex;
  overflow-x: auto;
  gap: 8px;
  padding: 5px;
  max-width: 100%;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.avatar-thumbnails::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.avatar-thumbnail {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid #fff;
  opacity: 0.7;
  transition: opacity 0.2s ease, border-color 0.2s ease, transform 0.2s ease;
}

.avatar-thumbnail:hover {
  opacity: 1;
}

.avatar-thumbnail:active {
  transform: scale(0.95);
}

.avatar-thumbnail.selected {
  opacity: 1;
  border-color: #007aff;
}

.avatar-upload-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30px;
  height: 30px;
  background: #007aff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  border: 2px solid #fff;
  transition: transform 0.2s ease;
}

.avatar-upload-btn:active {
  transform: scale(0.95);
}

.upload-confirm-btn {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 30px;
  height: 30px;
  background: #34c759;
  border-radius: 50%;
  border: 2px solid #fff;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.upload-confirm-btn:active {
  transform: scale(0.95);
}

.user-profile-header h1 {
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 5px;
}

.user-profile-header .status {
  font-size: 14px;
  color: #666;
  margin: 0 0 10px;
}

.follow-stats {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 15px;
}

.follow-stats span {
  font-size: 14px;
  cursor: pointer;
  transition: color 0.2s ease;
}

.follow-stats span:hover {
  color: #007aff;
}

.follow-link {
  font-weight: 500;
}

.profile-actions {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.profile-actions button {
  background: #007aff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: transform 0.2s ease, background 0.2s ease;
}

.profile-actions button:active {
  transform: scale(0.95);
}

.profile-actions button:hover {
  background: #0062cc;
}

.user-profile-info {
  padding: 10px;
  text-align: center;
}

.user-profile-info p {
  font-size: 14px;
  margin: 0;
}

.user-profile-form {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.user-profile-form input,
.user-profile-form textarea {
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  background: #f5f5f5;
  outline: none;
}

.user-profile-form textarea {
  min-height: 60px;
  resize: vertical;
}

.form-actions {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.form-actions button {
  background: #007aff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  transition: transform 0.2s ease, background 0.2s ease;
}

.form-actions button:active {
  transform: scale(0.95);
}

.form-actions button:hover {
  background: #0062cc;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  border-radius: 15px;
  padding: 20px;
  width: 90%;
  max-width: 400px;
  max-height: 80vh;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.modal-content h2 {
  margin: 0 0 15px;
  font-size: 18px;
  font-weight: 600;
}

.follow-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.follow-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;
  cursor: pointer;
  transition: background 0.2s ease, transform 0.2s ease;
}

.follow-item:hover {
  background: rgba(0, 0, 0, 0.05);
}

.follow-item:active {
  transform: scale(0.98);
}

.follow-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.modal-close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  color: #666;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.modal-close-btn:active {
  transform: scale(0.95);
}

.loading,
.not-found {
  text-align: center;
  padding: 15px;
  font-size: 16px;
  color: #666;
}

@media (max-width: 768px) {
  .user-profile-header .avatar {
    width: 80px;
    height: 80px;
  }

  .avatar-thumbnail {
    width: 40px;
    height: 40px;
  }

  .user-profile-header h1 {
    font-size: 18px;
  }

  .user-profile-header .status {
    font-size: 13px;
  }

  .follow-stats {
    gap: 15px;
  }

  .profile-actions button {
    padding: 6px 12px;
    font-size: 13px;
  }
}

@media (max-width: 480px) {
  .user-profile-header .avatar {
    width: 60px;
    height: 60px;
  }

  .avatar-thumbnail {
    width: 30px;
    height: 30px;
  }

  .user-profile-header h1 {
    font-size: 16px;
  }

  .user-profile-header .status {
    font-size: 12px;
  }

  .follow-stats {
    flex-direction: column;
    gap: 5px;
  }

  .profile-actions {
    flex-direction: column;
    gap: 5px;
  }

  .profile-actions button {
    padding: 6px 10px;
    font-size: 12px;
  }
}
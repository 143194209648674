@import url('../../../Services/variables/variables.css');
.modal-overlay {
    position: fixed; /* Используем fixed, чтобы окно было поверх остальных */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Тёмный фон с полупрозрачностью */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Обеспечиваем, чтобы модальное окно было сверху всех других элементов */
}

.edit-modal-content {
    position: relative;
    width: 90%; /* Ширина модального окна */
    max-width: 340px; /* Максимальная ширина окна */
    max-height: 90%; /* Ограничиваем высоту окна */
    background-color: var(--background-color);
    /* Позволяет прокручивать содержимое окна, если оно выходит за пределы */
    border-radius: 8px;
    /*margin: 10% auto;*/
    padding: 20px;
}
.close-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #a82727;
    color: white;
    border: 1px solid white;
    border-radius: 5px;
    font-size: 30px;
    width: 39px;
    height: 39px;
}
.close-buttonWrapper{

    display: flex;
    justify-content: flex-end;
}


.multiple-image-upload .image-item {
    position: relative; /* Устанавливаем относительное позиционирование для родителя */
    display: inline-block;
    margin: 5px;
}

.multiple-image-upload .image-item img {
    max-width: 100px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.multiple-image-upload .delete-button {
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(255, 0, 0, 0.7);
    color: white;
    border: none;
    padding: 5px;
    cursor: pointer;
    border-radius: 50%;
    font-size: 16px;
    line-height: 1;
}
.modal-overlay select{
    background: var(--background-highlight-color);
    color: var(--text-color-primary);
}
.modal-overlay input{
    color: var(--text-color-primary);
}
.multiple-image-upload .delete-button:hover {
    background: rgba(255, 0, 0, 0.9);
}
.multiple-image-upload {
    margin-top: 20px;
    position: relative;
}

.multiple-image-upload input {
    display: block;
    margin-bottom: 10px;
}

.multiple-image-upload button {
    padding: 5px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.multiple-image-upload button:hover {
    background-color: #45a049;
}

.image-preview {
    display: flex;
    overflow-x: auto; /* Добавляем горизонтальный скролл */
    border: 1px solid #ddd;
    border-radius: 5px;
    max-width: 100%; /* Ограничиваем ширину контейнера */
    white-space: nowrap; /* Запрещаем перенос строк */
    justify-content: center;
}

.image-item {
    position: relative;
    display: inline-block; /* Устанавливаем изображения в одну строку */
    margin-right: 10px; /* Отступ между изображениями */
}

.image-item img {
    max-width: 100px;
    max-height: 100px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.delete-button {
    position: absolute;
    top: -5px;
    right: -5px;
    background-color: rgba(255, 0, 0, 0.7);
    color: white;
    border: none;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

.delete-button:hover {
    background-color: rgba(255, 0, 0, 0.9);
}

/* Стили для полосы прокрутки */
.image-preview::-webkit-scrollbar {
    height: 8px;
}

.image-preview::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.image-preview::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
}

.image-preview::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}
.saveEdit{
    background-color: #4CAF50;
    border: none;
    border-radius: 5px;
    width: 100%;
    padding: 10px;
    color: white;
}

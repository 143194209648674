@import url('../../Services/variables/variables.css');

.category-popup-container {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: var(--primary-background);
    z-index: 9999;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    color: white;
    max-width: 300px;
    text-align: center;
}

.category-popup-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.category-popup-content p {
    font-family: Oswald, sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--text-color-primary);
}

.category-popup-close-button {
    margin-top: 10px;
    background-color: #f44336;
    border: none;
    color: white;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.category-popup-close-button:hover {
    background-color: #d32f2f;
}

/* Comments Styles */
.fullscreen-comments-container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0;
  background: rgba(255, 255, 255, 0.1);
  padding: 0 10px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  transition: height 0.3s ease;
  z-index: 11;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.fullscreen-comments-container.active {
  height: 100%;
  padding: 10px;
}

@media (min-width: 768px) {
  .fullscreen-comments-container {
    position: fixed;
    bottom: 0;
    right: 0;
    top: auto;
    left: auto;
    width: 400px;
    height: 50%;
    border-top-right-radius: 0;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 0;
    transition: none;
  }

  .fullscreen-comments-container.active {
    height: 50%;
  }
}

.fullscreen-comments-container .fullscreen-comments {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.fullscreen-comments-container .comment-form {
  display: flex;
  gap: 5px;
  margin-top: 10px;
}

.fullscreen-comments-container .back-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background: rgba(255, 255, 255, 0.1);
  border: none;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
  padding: 6px;
  border-radius: 50%;
  transition: transform 0.2s ease;
}

.fullscreen-comments-container .back-button:active {
  transform: scale(0.95);
}

.fullscreen-comments-container input {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.2);
  color: #fff;
}

.fullscreen-comments-container input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.fullscreen-comments-container form button {
  background: #007aff;
  padding: 8px 12px;
  border-radius: 10px;
  color: #fff;
  border: none;
  cursor: pointer;
}

.user-profile-container.space .fullscreen-comments-container form button,
.user-profile-container.future .fullscreen-comments-container form button {
  background: linear-gradient(45deg, #8a2be2, #00b7ff);
}

.fullscreen-comment {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 5px;
  cursor: pointer;
  transition: background 0.2s ease;
}

.fullscreen-comment:hover {
  background: rgba(255, 255, 255, 0.1);
}

.comment-avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

.fullscreen-comment p {
  font-size: 13px;
  color: #fff;
  margin: 0 0 2px;
  word-break: break-word;
}

.fullscreen-comment .reply-to {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
}

.fullscreen-comment span {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.7);
}

.comment-actions {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.comment-actions button {
  background: none;
  border: none;
  color: #fff;
  font-size: 12px;
  cursor: pointer;
}

.comment-actions button.liked {
  color: #ff2d55;
}

.emoji-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 10px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  margin-top: 5px;
}

.emoji-grid span {
  font-size: 20px;
  cursor: pointer;
}
.group-header {
  padding: 10px 15px;
  background: rgba(20, 30, 48, 0.9);
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 2;
}

.back-btn {
  background: transparent;
  border: none;
  color: #00b4d8;
  font-size: 18px;
  cursor: pointer;
  margin-right: 10px;
}

.group-info {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.group-info .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  cursor: pointer;
}

.group-details {
  display: flex;
  flex-direction: column;
}

.group-details h2 {
  color: #fff;
  font-size: 16px;
  margin: 0;
  font-weight: 500;
}

.group-details span {
  color: #d0d8e0;
  font-size: 12px;
}

.group-options {
  position: absolute;
  top: 60px;
  left: 15px;
  background: rgba(20, 30, 48, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 15px; /* Увеличим отступы */
  z-index: 1000;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  max-height: 400px;
  overflow-y: auto;
  width: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.close-btn {
  position: sticky;
  top: 5px;
  align-self: flex-end; /* Справа от формы */
  background: transparent;
  border: none;
  color: #d0d8e0;
  font-size: 16px;
  cursor: pointer;
  padding: 0;
  z-index: 1001; /* Выше содержимого */
}

.group-options h3 {
  font-size: 14px;
  color: #fff;
  margin: 0 0 10px;
}

.group-options ul {
  list-style: none;
  padding: 0;
  margin: 0 0 10px;
}

.group-options li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  color: #d0d8e0;
  font-size: 13px;
}

.member-info {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-grow: 1;
}

.member-info .avatar {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.group-options li .member-name {
  cursor: pointer;
  color: #fff;
  transition: color 0.2s ease;
}

.group-options li .member-name:hover {
  color: #00b4d8;
}

.member-actions {
  display: flex;
  gap: 5px;
}

.group-options li button {
  background: transparent;
  border: none;
  color: #ff4444;
  cursor: pointer;
  font-size: 12px;
}

.group-options li button.moderator-btn {
  color: #00cc00;
}

.add-user {
  margin-top: 15px;
}

.add-user .user-search {
  width: 100%;
  padding: 8px 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.08);
  color: #fff;
  font-size: 13px;
  outline: none;
  transition: border-color 0.3s ease, background 0.3s ease;
}

.add-user .user-search:focus {
  border-color: #00b4d8;
  background: rgba(255, 255, 255, 0.12);
}

.add-user ul {
  max-height: 150px;
  overflow-y: auto;
}

.add-user li {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-user li .member-info {
  flex-grow: 1;
}

.add-user li button {
  color: #00cc00;
}

.group-options button {
  width: 100%;
  padding: 8px;
  background: rgba(255, 255, 255, 0.1);
  color: #d0d8e0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 13px;
  text-align: left;
  transition: background 0.2s ease;
}

.group-options button:hover {
  background: rgba(255, 255, 255, 0.2);
}

.photo-change {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.photo-change input {
  padding: 5px;
  font-size: 12px;
}

.photo-change button {
  background: linear-gradient(135deg, #007bff, #00b4d8);
  color: #fff;
}

.photo-change button:disabled {
  background: rgba(255, 255, 255, 0.3);
  cursor: not-allowed;
}

/* Адаптивность */
@media (max-width: 768px) {
  .group-options {
    width: 250px;
    left: 10px;
  }
}
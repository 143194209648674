@import url('../../Services/variables/variables.css');

/* General Container Styling */
.ORDERS {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh; /* Полная высота экрана */
    /*background: linear-gradient(135deg, #f5f7fa, #c3cfe2); !* Легкий градиент для фона *!*/
    padding: 20px;
    margin-bottom: 100px; /* Сохранение отступа снизу */
}


/* Orders Title */
.OrdersTitle {
    display: flex;
    justify-content: center;
    margin-bottom: 20px; /* Увеличенный отступ для воздушности */
    margin-top: 60px; /* Увеличенный отступ для воздушности */
    color: var(--text-color-primary, #1a73e8); /* Сохранение цвета из исходного кода */
    font-weight: 600;
    font-size: 30px;
    background: rgba(255, 255, 255, 0.9);
    padding: 10px 20px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    position: relative; /* Для эффекта блеска */
    overflow: hidden;
}

/* Shine Effect for Title */
.OrdersTitle::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 50%;
    height: 100%;
    background: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.3), transparent);
    animation: shine 2s infinite;
}

@keyframes shine {
    0% { left: -100%; }
    20% { left: 100%; }
    100% { left: 100%; }
}

/* Orders List */
.orders-list {
    display: grid;
    gap: 20px; /* Увеличенный зазор для современного вида */
    grid-template-columns: repeat(auto-fill, minmax(315px, 1fr));
    padding: 20px;
    width: 100%;
    max-width: 855px; /* Соответствие ширине профиля */
    margin-bottom: 100px; /* Сохранение отступа снизу */
    border-radius: 25px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
}

/* Loading and Error Messages */
.ORDERS > div {
    font-size: 18px;
    color: var(--text-color-primary, #333);
    padding: 15px 25px;
    border-radius: 15px;
}

/* No Orders chat */
.orders-list p {
    font-size: 18px;
    border-radius: 15px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    text-align: center;
    width: 100%;
}

/* Media Queries */
@media (max-width: 460px) {
    .ORDERS {
        margin-top: 50px; /* Уменьшенный отступ для маленьких экранов */
        padding: 15px;
    }

    .orders-list {
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Адаптация сетки */
        padding: 15px;
    }

    .OrdersTitle {
        font-size: 24px;
        padding: 8px 15px;
    }

    .back-button {
        padding: 8px 15px;
        font-size: 14px;
    }
}

@media (max-width: 390px) {
    .ORDERS {
        padding: 10px;
    }

    .orders-list {
        grid-template-columns: 1fr; /* Одна колонка на очень маленьких экранах */
    }
}
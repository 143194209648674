@import url('../../Services/variables/variables.css');


/* General Profile Container Styling */
.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  /*background: var(--profile-bg, linear-gradient(135deg, #1e1e2f, #2a2a4b)); !* Default dark theme *!*/
  position: relative;
  overflow: hidden;
}

/* Theme Variables */


/* Apply background based on theme */
@media (prefers-color-scheme: dark) {
  .profile-container {
    background: var(--profile-bg-dark);
  }
  .profile-avatar {
    background: var(--profile-avatar-bg-dark);
  }
}

@media (prefers-color-scheme: light) {
  .profile-container {
    background: var(--profile-bg-light);
  }
  .profile-avatar {
    background: var(--profile-avatar-bg-light);
  }
}

.whiteLand .profile-container {
  background: var(--primary-button-background);
}
.whiteLand .profile-avatar {
  background: var(--profile-avatar-bg-whiteLand);
}

/* Stars for Dark Theme */
.profile-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle at 20% 30%, rgba(255, 255, 255, 0.05) 1px, transparent 1.5px),
              radial-gradient(circle at 60% 70%, rgba(255, 255, 255, 0.04) 1px, transparent 1.5px),
              radial-gradient(circle at 80% 20%, rgba(255, 255, 255, 0.03) 1px, transparent 1.5px);
  opacity: 0.8;
  pointer-events: none;
  z-index: 0;
}

/* Sparkles for Light Theme */
@media (prefers-color-scheme: light) {
  .profile-container::before {
    background: radial-gradient(circle at 30% 40%, rgba(0, 123, 255, 0.1) 1px, transparent 1.5px),
                radial-gradient(circle at 70% 60%, rgba(0, 123, 255, 0.08) 1px, transparent 1.5px),
                radial-gradient(circle at 50% 20%, rgba(0, 123, 255, 0.06) 1px, transparent 1.5px);
    opacity: 0.6;
  }
}

/* Sparkles for WhiteLand Theme */
.whiteLand .profile-container::before {
  background: radial-gradient(circle at 25% 35%, rgba(64, 196, 255, 0.12) 1px, transparent 1.5px),
              radial-gradient(circle at 65% 65%, rgba(64, 196, 255, 0.1) 1px, transparent 1.5px),
              radial-gradient(circle at 45% 15%, rgba(64, 196, 255, 0.08) 1px, transparent 1.5px);
  opacity: 0.7;
}

/* Grid Container */
.profile-grid {
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-top: 18px;
  width: 100%;
  max-width: 855px;
  height: auto;
  align-items: center;
  border-radius: var(--border-radius-xxl);
  padding: 20px;
  backdrop-filter: blur(10px);
  margin-bottom: 100px;
  position: relative;
  z-index: 1;
}

/* Name Section */
.name-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: 10px;
}

.name-text h2 {
  font-size: 24px;
  font-weight: 600;
  color: var(--text-color-primary);
  margin: 0;
}

/* Avatar Section */
.profile-avatar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
  border-radius: 20px;
  padding: 15px;
  /*box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);*/
  backdrop-filter: blur(5px);
  overflow: hidden;
}

/* Glow Effect for Avatar */
.profile-avatar::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 150px;
  height: 150px;
  background: radial-gradient(circle, rgba(0, 123, 255, 0.2) 0%, transparent 70%);
  transform: translate(-50%, -50%);
  z-index: 0;
  opacity: 0.5;
  pointer-events: none;
}

.whiteLand .profile-avatar::before {
  background: radial-gradient(circle, rgba(64, 196, 255, 0.25) 0%, transparent 70%);
  opacity: 0.6;
}

/* Role Container */
.profile-role-container {
  background: var(--primary-button-background);
  padding: 8px 16px;
  border-radius: var(--border-radius-large, 12px);
  text-align: center;
  margin-top: 10px;
  z-index: 1;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  box-shadow: var(--shadow-glass-strong, 0 4px 15px rgba(0, 0, 0, 0.2));
  position: relative;
}

/* Ensure AvatarGame respects z-index */
.game-avatar {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border: 3px solid var(--neutral-color-1, #e0e0e0);
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.3s ease, border-color 0.3s ease, z-index 0s;
  cursor: pointer;
  z-index: 0;
}

.game-avatar.active {
  z-index: 2;
}

/* Profile Avatar Image (for non-AvatarGame cases) */
.profile-avatar img {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: var(--shadow-glass);
  border: 3px solid var(--neutral-color-1);
  transition: transform 0.5s ease, box-shadow 0.5s ease, border-color 0.5s ease;
  cursor: pointer;
}

.profile-avatar img.avatar-clicked {
  animation: softPress 0.5s ease forwards;
  box-shadow: 0 5px 15px rgba(0, 123, 255, 0.8), 0 10px 25px rgba(0, 123, 255, 0.5);
}

@keyframes softPress {
  0% {
    transform: scale(0.99);
    border-color: var(--neutral-color-1);
  }
  30% {
    transform: scale(0.915);
    border-color: #007bff;
  }
  100% {
    transform: scale(0.99);
    border-color: #007bff;
  }
}

/* Profile Info Section */
.profile-info {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin: 0 auto;
  width: 100%;
  max-width: 360px;
}

/* Profile Input Container */
.profile-input-item-container {
    align-items: flex-start;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background: var(--glass-background);
    border-radius: var(--border-radius-xl);
    box-shadow: var(--shadow-glass);
    padding: 15px;
}

.profile-input-item {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  color: var(--text-color-fourth);
  margin-bottom: 10px;
}


.input-icon {
  width: 15px;
  height: 15px;
  object-fit: contain;
}

/* Buttons Container */
.profile-button-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--border-radius-xl);
  backdrop-filter: blur(5px);
  width: 100%;
}

/* Edit Container */
.edit-container-button {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  border-radius: var(--border-radius-xl);
  backdrop-filter: blur(5px);
  width: 100%;
}

/* General Button Styles */
.edit-button,
.crm-button,
.support-button-profile,
.my-order-profile,
.logout-button,
.save-button,
.avatar-upload,
.toggle-button,
.partner-button {
  font-size: 16px;
  background: var(--gradient-button);
  backdrop-filter: blur(10px);
  border: 1px solid var(--border-glass);
  border-radius: var(--border-radius-xl);
  cursor: pointer;
  padding: 10px 20px;
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--text-color-primary);
  box-shadow: var(--shadow-glass);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}


/*.edit-button:active,*/
/*.crm-button:active,*/
/*.support-button-profile:active,*/
/*.my-order-profile:active,*/
/*.logout-button:active,*/
/*.save-button:active,*/
/*.avatar-upload:active,*/
/*.toggle-button:active,*/
/*.partner-button:active {*/
/*  transform: scale(0.98);*/
/*  box-shadow: var(--box-shadow-light);*/
/*}*/

/* Button Icons */
.edit-icon,
.crm-icon,
.support-icon,
.order-icon,
.save-icon,
.avatar-upload-icon,
.toggle-button img,
.partner-button-icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.logout-icon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}



/* Shine Effect (commented out) */
@keyframes shine {
  0% { left: -100%; }
  20% { left: 100%; }
  100% { left: 100%; }
}

/* Button Text */
.button-text {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-color-primary);
  margin-left: 10px;
}

.button-form-text {
  font-size: 16px;
  margin-left: 10px;
}

/* Inputs Container */
.inputs-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  background: var(--glass-background);
  border-radius: var(--border-radius-xl);
  width: 100%;
  margin-top: 10px;
}

.input-field {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 15px;
  width: 100%;
}

.input-field label {
  font-size: 16px;
  color: var(--text-color-primary);
  font-weight: 600;
}

.input-field input,
.input-field textarea {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid var(--neutral-color-6);
  border-radius: var(--border-radius-large);
  background: var(--glass-background-secondary);
  box-shadow: var(--box-shadow-light);
  transition: all 0.3s ease;
}

.input-field input:focus,
.input-field textarea:focus {
  box-shadow: var(--focus-shadow);
  outline: none;
}

.input-field textarea {
  resize: none;
  height: 100px;
}

/* File Input */
.file-input-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 16px;
  color: var(--text-color-fourth);
  padding: 10px 20px;
  border-radius: var(--border-radius-large);
  background: var(--glass-background-secondary);
  box-shadow: var(--shadow-glass);
  transition: all 0.3s ease;
}



.file-input {
  display: none;
}

.upload-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

/* Notification */
.upload-notification,
.save-notification {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 10px;
  color: var(--success-color);
  background: var(--notification-background);
  border-radius: var(--border-radius-large);
  padding: 8px 12px;
  font-size: 14px;
  animation: fadeIn 0.3s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(-10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Back Button */
.back-button-edit {
  background: var(--primary-button-background);
  border: none;
  font-size: 16px;
  cursor: pointer;
  color: var(--text-color-fifth);
  border-radius: var(--border-radius-large);
  padding: 10px 20px;
  transition: all 0.3s ease;
  box-shadow: var(--shadow-glass-strong);
  margin-bottom: 10px;
}



/* Modal */
.modal-overlay,
.modal-overlay-form {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--modal-overlay);
  backdrop-filter: blur(5px);
  z-index: 9999;
}

.modal {
  background: var(--gradient-button);
  border-radius: 20px;
  padding: 30px;
  width: 90%;
  max-width: 400px;
  text-align: center;
  color: var(--text-color-primary);
  box-shadow: var(--box-shadow-strong);
}

/* Modal Text */
.modal-text {
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: 500;
}

/* Close Button */
.close-button-modal {
  background: var(--gradient-primary);
  color: var(--text-color-fifth);
  padding: 12px 20px;
  border: none;
  border-radius: var(--border-radius-xl);
  cursor: pointer;
  font-size: 16px;
  box-shadow: var(--shadow-glass-strong);
  transition: all 0.3s ease;
}



/* Toggle Arrow */
.arrow-icon {
  width: 20px;
  height: 20px;
  transition: transform 0.3s ease;
}

.arrow-icon.expanded {
  transform: rotate(180deg);
}

/* Media Queries */
@media (max-width: 460px) {
  .profile-grid {
    margin-top: 50px;
    padding: 15px;
  }

  .edit-button,
  .crm-button,
  .support-button-profile,
  .my-order-profile,
  .logout-button,
  .save-button,
  .avatar-upload,
  .toggle-button,
  .partner-button {
    padding: 10px 15px;
  }


}

@media (max-width: 390px) {
  .profile-container {
    padding: 10px;
  }

  .profile-avatar img {
    width: 100px;
    height: 100px;
  }
}
:root {
  /* Day Mode Variables (Premium White Theme) */
  --button-text-color: #fff;
  --button-alternative-background-color: #d4af37; /* Золотой акцент для премиальности */

  /* Основные цвета (утонченные и дорогие) */
  --primary-color: #5856d6; /* Глубокий фиолетовый, как в iOS-премиум */
  --primary-hover-color: #4744b5; /* Темнее для ховера */
  --primary-color-login: rgba(255, 255, 255, 0.85); /* Полупрозрачный белый */
  --secondary-color: #d4af37; /* Золотой акцент для роскоши */
  --secondary-hover-color: #b9932e;

  /* Backgrounds (богатый белый с текстурой) */
  --background-color: #f5f5f7; /* Мягкий серо-бежевый, как керамика iPhone */
  --background-color-profile: rgba(245, 245, 247, 0.9); /* Легкий стеклянный эффект */
  --background-highlight-color: #ebebf0; /* Нежный серый с теплым оттенком */
  --background-secondary-color: #f0f0f5; /* Чуть темнее фон для контраста */

  /* Neutral Colors (премиальные оттенки) */
  --neutral-color-1: #ffffff; /* Чистый белый для акцентов */
  --neutral-color-2: #f7f7f9; /* Очень мягкий серый */
  --neutral-color-3: #f5f5f7; /* Базовый фон */
  --neutral-color-4: #8a8a8f; /* Холодный серый для деталей */
  --neutral-color-5: #d8d8dd; /* Теплый серый для разделителей */
  --neutral-color-6: #b0b0b5; /* Средний серый для теней */
  --neutral-color-7: #2c2c2e; /* Темный для текста */
  --neutral-color-8: #545456; /* Нейтральный серый для второстепенного */
  --neutral-color-9: #757579; /* Мягкий серый для иконок */

  /* Text Colors (глубокие и читаемые) */
  --text-color-primary: #1c2526; /* Глубокий темно-серый для основного текста */
  --text-color-secondary: #545456; /* Мягкий серый для второстепенного */
  --text-color-tertiary: #757579; /* Чуть светлее серый */
  --text-color-fourth: #000000; /* Черный для акцента */
  --text-color-fifth: #8a8a8f; /* Светло-серый для мелочей */

  /* Warning & Success (премиальные тона) */
  --warning-color: #ff453a; /* Яркий красный, как в iOS */
  --warning-hover-color: #ff2d55;
  --success-color: #30d158; /* Зеленый с премиальным оттенком */
  --success-hover-color: #28b74b;

  /* Shadows (мягкие и многослойные) */
  --box-shadow-light: 0 2px 6px rgba(0, 0, 0, 0.04), 0 1px 2px rgba(0, 0, 0, 0.06); /* Многослойная тень */
  --box-shadow-medium: 0 4px 12px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.07);
  --box-shadow-strong: 0 10px 30px rgba(0, 0, 0, 0.08), 0 4px 8px rgba(0, 0, 0, 0.1);

  /* Fonts (премиальный стиль) */
  --font-family-primary: -apple-system, BlinkMacSystemFont, 'San Francisco', 'Helvetica Neue', sans-serif; /* Шрифт iOS */
  --font-size-small: 12px;
  --font-size-medium: 14px;
  --font-size-large: 17px; /* Стандарт iOS */
  --font-size-xl: 20px;
  --font-size-xxl: 28px;

  /* Borders & Radius (закругления премиум-класса) */
  --border-radius-small: 8px;
  --border-radius-medium: 12px; /* Как у премиум-элементов iOS */
  --border-radius-large: 16px;
  --border-radius-xl: 24px;
  --border-radius-xxl: 32px; /* Для крупных элементов */

  /* Hover Background (тонкий металлический эффект) */
  --hover-background-color: rgba(88, 86, 214, 0.1); /* Легкий фиолетовый намёк */

  /* Icon Color (дорогой акцент) */
  --icon-color: #5856d6; /* Фиолетовый для иконок */

  /* iOS-Style Variables (Premium White Theme) */
  --gradient-background: linear-gradient(135deg, #f5f5f7, #e8e8ed); /* Текстурный градиент */
  --gradient-button: linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(235, 235, 240, 0.85)); /* Металлическая кнопка */
  --gradient-button-hover: linear-gradient(135deg, rgba(245, 245, 247, 0.95), rgba(225, 225, 230, 0.9));
  --gradient-primary: linear-gradient(135deg, #5856d6, #4744b5); /* Глубокий премиум-градиент */
  --gradient-primary-hover: linear-gradient(135deg, #4744b5, #3633a3);
  --glass-background: rgba(245, 245, 247, 0.85); /* Матовый керамический эффект */
  --glass-background-secondary: rgba(235, 235, 240, 0.8); /* Вторичный стеклянный фон */
  --border-glass: rgba(0, 0, 0, 0.1); /* Тонкая граница */
  --shine-effect: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.25), transparent); /* Легкий металлический блеск */
  --shadow-glass: 0 4px 12px rgba(0, 0, 0, 0.05), 0 2px 4px rgba(0, 0, 0, 0.07); /* Многослойная тень */
  --shadow-glass-strong: 0 8px 24px rgba(0, 0, 0, 0.08), 0 4px 8px rgba(0, 0, 0, 0.1);
  --focus-shadow: 0 0 0 3px rgba(88, 86, 214, 0.25); /* Фокус с фиолетовым оттенком */
  --notification-background: rgba(48, 209, 88, 0.15); /* Зеленый премиум-уведомления */
  --modal-overlay: rgba(0, 0, 0, 0.45); /* Полупрозрачный оверлей */
}

/* Night Mode Variables */
.night-mode {
  --button-text-color: #fff;
  --button-alternative-background-color: #fff;

  --primary-color: #1a73e8;
  --primary-hover-color: #134db3;
  --secondary-color: #34a853;
  --secondary-hover-color: #2a8441;

  /* Backgrounds */
  --background-color: #121212;
  --background-color-profile: rgba(18, 18, 18, 0.31);
  --background-highlight-color: #1e1e1e;
  --background-secondary-color: #2a2a2a;

  /* Neutral Colors */
  --neutral-color-1: #121212;
  --neutral-color-2: #1e1e1e;
  --neutral-color-3: #2a2a2a;
  --neutral-color-4: #9a9999;
  --neutral-color-5: #4e4e4e;
  --neutral-color-6: #666;
  --neutral-color-7: #ddd;
  --neutral-color-8: #bbb;
  --neutral-color-9: #888;

  /* Text Colors */
  --text-color-primary: #134db3;
  --text-color-secondary: #bbb;
  --text-color-tertiary: #888;
  --text-color-fourth: #FFFF;
  --text-color-fifth: white;

  /* Warning & Success */
  --warning-color: #ff5252;
  --warning-hover-color: #ff1744;
  --success-color: #66bb6a;
  --success-hover-color: #43a047;

  /* Shadows */
  --box-shadow-light: 0 2px 5px rgba(255, 255, 255, 0.1);
  --box-shadow-medium: 0 4px 8px rgba(255, 255, 255, 0.1);
  --box-shadow-strong: 0 10px 30px rgba(0, 0, 0, 0.4);

  /* iOS-Style Variables (Dark Theme) */
  --gradient-background: linear-gradient(135deg, #1e2a44, #2b3a5a);
  --gradient-button: linear-gradient(135deg, rgba(50, 65, 100, 0.9), rgba(40, 55, 85, 0.8));
  --gradient-button-hover: linear-gradient(135deg, rgba(70, 85, 120, 1), rgba(60, 75, 105, 0.9));
  --gradient-primary: linear-gradient(135deg, #3b5bbf, #2b4a9f);
  --gradient-primary-hover: linear-gradient(135deg, #2b4a9f, #1e3a7f);
  --glass-background: rgba(30, 42, 68, 0.95);
  --glass-background-secondary: rgba(50, 65, 100, 0.9);
  --border-glass: rgba(255, 255, 255, 0.1);
  --shine-effect: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.2), transparent);
  --shadow-glass: 0 4px 15px rgba(0, 0, 0, 0.3);
  --shadow-glass-strong: 0 4px 15px rgba(0, 0, 0, 0.4);
  --focus-shadow: 0 4px 15px rgba(59, 91, 191, 0.3);
  --notification-background: rgba(40, 167, 69, 0.2);
  --modal-overlay: rgba(0, 0, 0, 0.7);
}

/* WhiteLand Theme Variables */
.whiteLand {
  /* 🎨 Основные цвета */
  --button-text-color: #fff;              /* Белый текст для кнопок */
  --button-alternative-background-color: #fff; /* Альтернативный фон кнопок */

  --primary-color: #5E4A9F;              /* Основной фиолетовый акцент */
  --primary-hover-color: #4A3A7F;        /* Темнее фиолетовый для ховера */
  --secondary-color: #7A66BF;            /* Светлее фиолетовый для второго акцента */
  --secondary-hover-color: #64529F;      /* Темнее второго акцента */

  /* 🖼 Фоны */
  --background-color: #1A1A2E;           /* Темный фон с фиолетовым намеком */
  --background-color-profile: rgba(26, 26, 46, 0.31); /* Стеклянный темный фон */
  --background-highlight-color: #25253F; /* Легкий фиолетовый акцент */
  --background-secondary-color: #2E2E4D; /* Вторичный темный фон */

  /* ⚪ Нейтральные тона */
  --neutral-color-1: #1A1A2E;            /* Самый темный фон */
  --neutral-color-2: #25253F;            /* Чуть светлее */
  --neutral-color-3: #2E2E4D;            /* Средний темный */
  --neutral-color-4: #9A99A9;            /* Светлый серый */
  --neutral-color-5: #4E4E6E;            /* Темный серо-фиолетовый */
  --neutral-color-6: #666680;            /* Средний серо-синий */
  --neutral-color-7: #DDDDE5;            /* Очень светлый серый */
  --neutral-color-8: #BBBBCC;            /* Мягкий серо-фиолетовый */
  --neutral-color-9: #888899;            /* Нейтральный серый */

  /* ✍️ Текст */
  --text-color-primary: #5E4A9F;         /* Фиолетовый для основного текста */
  --text-color-secondary: #BBBBCC;       /* Светло-серый для второстепенного текста */
  --text-color-tertiary: #888899;        /* Темнее серый для третьего уровня */
  --text-color-fourth: #FFFFFF;          /* Белый для акцента */
  --text-color-fifth: white;             /* Чистый белый */

  /* 🚨 Статусы */
  --warning-color: #FF5252;              /* Красный для предупреждений */
  --warning-hover-color: #FF1744;        /* Яркий красный ховер */
  --success-color: #66BB6A;             /* Зеленый для успеха */
  --success-hover-color: #43A047;       /* Темный зеленый ховер */

  /* 🌫 Тени */
  --box-shadow-light: 0 2px 5px rgba(255, 255, 255, 0.1);   /* Лёгкая белая тень */
  --box-shadow-medium: 0 4px 8px rgba(255, 255, 255, 0.1);  /* Средняя тень */
  --box-shadow-strong: 0 10px 30px rgba(0, 0, 0, 0.4);      /* Сильная черная тень */

  /* ✨ iOS-стиль: градиенты и стекло (мой фиолетовый вариант) */
  --gradient-background: linear-gradient(135deg, #3A2A5F, #4A3A7F);          /* Фиолетовый фон */
  --gradient-button: linear-gradient(135deg, rgba(58, 42, 95, 0.9), rgba(74, 58, 127, 0.8)); /* Кнопка */
  --gradient-button-hover: linear-gradient(135deg, rgba(90, 74, 143, 1), rgba(74, 58, 127, 0.9)); /* Ховер кнопки */
  --gradient-primary: linear-gradient(135deg, #5E4A9F, #4A3A7F);             /* Основной градиент */
  --gradient-primary-hover: linear-gradient(135deg, #4A3A7F, #3A2A5F);       /* Темный ховер */
  --glass-background: rgba(58, 42, 95, 0.95);                                /* Стеклянный фиолетовый фон */
  --glass-background-secondary: rgba(74, 58, 127, 0.9);                      /* Вторичный стеклянный фон */
  --border-glass: rgba(255, 255, 255, 0.1);                                  /* Белая граница стекла */
  --shine-effect: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.2), transparent); /* Блеск */
  --shadow-glass: 0 4px 15px rgba(0, 0, 0, 0.3);                             /* Тень стекла */
  --shadow-glass-strong: 0 4px 15px rgba(0, 0, 0, 0.4);                      /* Усиленная тень */
  --focus-shadow: 0 4px 15px rgba(94, 74, 159, 0.3);                         /* Фиолетовая тень фокуса */
  --notification-background: rgba(122, 102, 191, 0.2);                       /* Уведомления с фиолетовым оттенком */
  --modal-overlay: rgba(0, 0, 0, 0.7);                                       /* Темный оверлей */
}
/* PureDark Theme Variables */
  .pureDark {
    --button-text-color: #fff;
    --button-alternative-background-color: #fff;

    --primary-color: #1a73e8;
    --primary-hover-color: #134db3;
    --secondary-color: #34a853;
    --secondary-hover-color: #2a8441;

    /* Backgrounds */
    --background-color: #000000; /* Pure black */
    --background-color-profile: rgba(0, 0, 0, 0.95);
    --background-highlight-color: #1a1a1a;
    --background-secondary-color: #0d0d0d;

    /* Neutral Colors */
    --neutral-color-1: #000000;
    --neutral-color-2: #0d0d0d;
    --neutral-color-3: #1a1a1a;
    --neutral-color-4: #5b5a5a;
    --neutral-color-5: #333333;
    --neutral-color-6: #4d4d4d;
    --neutral-color-7: #cccccc;
    --neutral-color-8: #b3b3b3;
    --neutral-color-9: #999999;

    /* Text Colors */
    --text-color-primary: #ffffff;
    --text-color-secondary: #cccccc;
    --text-color-tertiary: #b3b3b3;
    --text-color-fourth: #e6e6e6;
    --text-color-fifth: #ffffff;

    /* Warning & Success */
    --warning-color: #ff5252;
    --warning-hover-color: #ff1744;
    --success-color: #66bb6a;
    --success-hover-color: #43a047;

    /* Shadows */
    --box-shadow-light: 0 2px 5px rgba(255, 255, 255, 0.05);
    --box-shadow-medium: 0 4px 8px rgba(255, 255, 255, 0.07);
    --box-shadow-strong: 0 10px 30px rgba(0, 0, 0, 0.5);

    /* Fonts */
    --font-family-primary: 'Oswald', sans-serif;
    --font-size-small: 12px;
    --font-size-medium: 14px;
    --font-size-large: 16px;
    --font-size-xl: 20px;
    --font-size-xxl: 24px;

    /* Borders & Radius */
    --border-radius-small: 4px;
    --border-radius-medium: 5px;
    --border-radius-large: 8px;
    --border-radius-xl: 15px;
    --border-radius-xxl: 25px;

    /* Hover Background */
    --hover-background-color: rgba(26, 115, 232, 0.15);

    /* Icon Color */
    --icon-color: #ffffff;

    /* iOS-Style Variables (PureDark Theme) */
    --gradient-background: linear-gradient(135deg, #000000, #1a1a1a);
    --gradient-button: linear-gradient(135deg, rgba(26, 26, 26, 0.9), rgba(13, 13, 13, 0.8));
    --gradient-button-hover: linear-gradient(135deg, rgba(51, 51, 51, 1), rgba(38, 38, 38, 0.9));
    --gradient-primary: linear-gradient(135deg, #1a73e8, #134db3);
    --gradient-primary-hover: linear-gradient(135deg, #134db3, #0d3a8c);
    --glass-background: rgba(0, 0, 0, 0.95);
    --glass-background-secondary: rgba(26, 26, 26, 0.9);
    --border-glass: rgba(255, 255, 255, 0.05);
    --shine-effect: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.15), transparent);
    --shadow-glass: 0 4px 15px rgba(0, 0, 0, 0.4);
    --shadow-glass-strong: 0 4px 15px rgba(0, 0, 0, 0.5);
    --focus-shadow: 0 4px 15px rgba(26, 115, 232, 0.2);
    --notification-background: rgba(40, 167, 69, 0.15);
    --modal-overlay: rgba(0, 0, 0, 0.8);
  }
:root {
  /* Light Theme */
  --primary-background: rgba(255, 255, 255, 0.90);
  --primary-box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  --primary-100: #000000;
  --secondary-background: #f0f0f0;
  --accent-color: #007bff;

  /* TYPOGRAPHY */
  --font-family-primary: 'Oswald', sans-serif;
  --font-size-desktop-medium: 20px;
  --font-size-tablet-1024-medium: 18px;
  --font-size-mobile-428-medium: 12px;

  /* Text Colors */
  --text-color-primary: #000000; /* Added for ThemeToggle */

  /* INPUTS */
  --primary-form-group-input: #ffffff;

  /* Buttons */
  --primary-button-background: rgba(43, 102, 254, 0.90);
  --primary-button-background-submitButton: rgb(128, 129, 133);
  --primary-button-text-color-sumbitButton: rgb(255, 255, 255);
  --primary-button-text-color: #FFF;
  --secondary-button-background: #FFF;
  --submit-button--background-secondary-color: #1C1B1B;
  --submit-button--background-secondary-color--hover: rgba(255, 255, 255, 0.80);
  --submit-button-text-color: rgba(255, 255, 255, 0.80);

  /* ToggleSwitch */
  --switch-button-border: #1C1B1B;
  --switch-button-color: #000000;

  /* NavBar */
  --primary--nav--button--color: rgba(38, 38, 38, 0.80);
  --secondary--nav--button--color: rgba(43, 102, 254, 0.8);

  /* Border */
  --primary--category--border-color: rgb(153, 154, 157);

  /* Shadow */
  --primary--shadow-color: rgba(0, 0, 0, 0.15);

  /* Home */
  --home--inner-block--text-color: #2B66FE;
}

.night-mode {
  /* Night Theme */
  --primary-background: rgba(18, 18, 18, 0.90);
  --primary-box-shadow: 0 4px 8px rgba(255, 255, 255, 0.1);
  --primary-100: #dddddd;
  --secondary-background: #121212;
  --accent-color: #1a73e8;

  /* INPUTS */
  --primary-form-group-input: rgba(18, 18, 18, 0.90);

  /* TYPOGRAPHY */
  --font-family-primary: 'Oswald', sans-serif;
  --font-size-desktop-medium: 20px;
  --font-size-tablet-1024-medium: 18px;
  --font-size-mobile-428-medium: 12px;

  /* Text Colors */
  --text-color-primary: #ffffff;

  /* Buttons */
  --primary-button-background: #21397C;
  --secondary-button-background: rgba(43, 102, 254, 0.12);
  --primary-button-text-color: #1C1B1B;
  --submit-button--background-secondary-color: rgba(255, 255, 255, 0.80);
  --submit-button--background-secondary-color--hover: #1C1B1B;
  --submit-button-text-color: rgba(255, 255, 255, 0.80);

  /* ToggleSwitch */
  --switch-button-border: rgba(43, 102, 254, 0.12);
  --switch-button-color: rgba(255, 255, 255, 0.80);

  /* NavBar */
  --primary--nav--button--color: rgba(255, 255, 255, 0.80);
  --secondary--nav--button--color: #21397C;

  /* Border */
  --primary--category--border-color: rgba(255, 255, 255, 0.60);

  /* Shadow */
  --primary--shadow-color: rgba(255, 255, 255, 0.10);

  /* Home */
  --home--inner-block--text-color: rgba(255, 255, 255, 0.80);
}
.whiteLand {
  /* 🎨 Основные цвета */
  --button-text-color: #FFFFFF;              /* Белый текст для кнопок */
  --button-alternative-background-color: #FFFFFF; /* Альтернативный фон кнопок */

  --primary-button-background: #4B5EAA;     /* Главный фон страниц - мягкий индиго */

  --primary-color: #4B5EAA;                 /* Основной акцент - мягкий индиго */
  --primary-hover-color: #3D4E8A;           /* Темнее индиго для ховера */
  --secondary-color: #6B7ECC;               /* Светлее индиго для второго акцента */
  --secondary-hover-color: #596BB3;         /* Темнее второго акцента */

  /* 🖼 Фоны */
  --background-color: #4B5EAA;              /* Главный фон страниц (синоним --primary-button-background) */
  --background-color-profile: rgba(75, 94, 170, 0.31); /* Стеклянный фон с оттенком индиго */
  --background-highlight-color: #576BB3;    /* Легкий акцент индиго */
  --background-secondary-color: #3D4E8A;    /* Вторичный темный фон */

  /* ⚪ Нейтральные тона */
  --neutral-color-1: #2E3A66;               /* Самый темный фон */
  --neutral-color-2: #3D4E8A;               /* Чуть светлее */
  --neutral-color-3: #576BB3;               /* Средний темный */
  --neutral-color-4: #A6B1E1;               /* Светлый серо-синий */
  --neutral-color-5: #6B7ECC;               /* Средний серо-индиго */
  --neutral-color-6: #8796D6;               /* Светлый серо-синий */
  --neutral-color-7: #DCE2F0;               /* Очень светлый серый */
  --neutral-color-8: #B0B8D9;               /* Мягкий серо-синий */
  --neutral-color-9: #8A94C4;               /* Нейтральный серо-индиго */

  /* ✍️ Текст */
  --text-color-primary: #6B7ECC;            /* Светлый индиго для основного текста */
  --text-color-secondary: #B0B8D9;          /* Светло-серый для второстепенного текста */
  --text-color-tertiary: #8A94C4;           /* Темнее серый для третьего уровня */
  --text-color-fourth: #FFFFFF;             /* Белый для акцента */
  --text-color-fifth: white;                /* Чистый белый */

  /* 🚨 Статусы */
  --warning-color: #FF5252;                 /* Красный для предупреждений */
  --warning-hover-color: #FF1744;           /* Яркий красный ховер */
  --success-color: #4CAF50;                 /* Зеленый для успеха */
  --success-hover-color: #388E3C;           /* Темный зеленый ховер */

  /* 🌫 Тени */
  --box-shadow-light: 0 2px 5px rgba(255, 255, 255, 0.1);   /* Лёгкая белая тень */
  --box-shadow-medium: 0 4px 8px rgba(255, 255, 255, 0.1);  /* Средняя тень */
  --box-shadow-strong: 0 10px 30px rgba(0, 0, 0, 0.4);      /* Сильная черная тень */

  /* ✨ iOS-стиль: градиенты и стекло (мягкий индиго) */
  --gradient-background: linear-gradient(135deg, #4B5EAA, #3D4E8A);          /* Фон с индиго */
  --gradient-button: linear-gradient(135deg, rgba(75, 94, 170, 0.9), rgba(61, 78, 138, 0.8)); /* Кнопка */
  --gradient-button-hover: linear-gradient(135deg, rgba(107, 126, 204, 1), rgba(89, 107, 179, 0.9)); /* Ховер кнопки */
  --gradient-primary: linear-gradient(135deg, #6B7ECC, #4B5EAA);             /* Основной градиент */
  --gradient-primary-hover: linear-gradient(135deg, #4B5EAA, #3D4E8A);       /* Темный ховер */
  --glass-background: rgba(75, 94, 170, 0.95);                               /* Стеклянный фон индиго */
  --glass-background-secondary: rgba(107, 126, 204, 0.9);                    /* Вторичный стеклянный фон */
  --border-glass: rgba(255, 255, 255, 0.1);                                  /* Белая граница стекла */
  --shine-effect: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.2), transparent); /* Блеск */
  --shadow-glass: 0 4px 15px rgba(0, 0, 0, 0.3);                             /* Тень стекла */
  --shadow-glass-strong: 0 4px 15px rgba(0, 0, 0, 0.4);                      /* Усиленная тень */
  --focus-shadow: 0 4px 15px rgba(75, 94, 170, 0.3);                         /* Тень фокуса индиго */
  --notification-background: rgba(107, 126, 204, 0.2);                       /* Уведомления с оттенком индиго */
  --modal-overlay: rgba(0, 0, 0, 0.7);                                       /* Темный оверлей */
}
.pureDark {
  /* DarkNight Theme */
  --primary-background: rgba(0, 0, 0, 0.95);
  --primary-box-shadow: 0 4px 8px rgba(255, 255, 255, 0.05);
  --primary-100: #ffffff;
  --secondary-background: #0d0d0d;
  --accent-color: #1a73e8;

  /* TYPOGRAPHY */
  --font-family-primary: 'Oswald', sans-serif;
  --font-size-desktop-medium: 20px;
  --font-size-tablet-1024-medium: 18px;
  --font-size-mobile-428-medium: 12px;

  /* Text Colors */
  --text-color-primary: #ffffff;

  /* INPUTS */
  --primary-form-group-input: rgba(0, 0, 0, 0.95);

  /* Buttons */
  --primary-button-background:  rgba(43, 102, 254, 0.90);
  --secondary-button-background: rgba(13, 13, 13, 0.9);
  --primary-button-text-color: #FFF;
  --submit-button--background-secondary-color: #333333;
  --submit-button--background-secondary-color--hover: rgba(255, 255, 255, 0.85);
  --submit-button-text-color: rgba(255, 255, 255, 0.90);

  /* ToggleSwitch */
  --switch-button-border: #333333;
  --switch-button-color: #ffffff;

  /* NavBar */
  --primary--nav--button--color: rgba(255, 255, 255, 0.80);
  --secondary--nav--button--color: rgba(26, 115, 232, 0.8);

  /* Border */
  --primary--category--border-color: rgba(255, 255, 255, 0.50);

  /* Shadow */
  --primary--shadow-color: rgba(255, 255, 255, 0.07);

  /* Home */
  --home--inner-block--text-color: rgba(255, 255, 255, 0.80);
}
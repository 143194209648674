@import url('../../Services/variables/variables.css');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

html {
    width: 100% !important;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.car-list {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(315px, 1fr));
    padding: 10px;
    width: 100%;
}

.car-list * {
    font-family: "Oswald", sans-serif;
}

.disFlex {
    display: flex;
}

.Volume {
    display: flex;
    width: 33%;
    justify-content: center;
    align-items: center;
}

.Volume svg {
    width: 35px;
    height: 35px;
    color: var(--neutral-color-9);
}

.Elevator img {
    width: 25px;
    height: 25px;
}

.Volume p {
    color: var(--neutral-color-9);
    padding: 5px;
}

.brandvolume {
    display: flex;
    align-items: center;
    text-align: right;
}

.brandvolume p {
    color: var(--neutral-color-9);
    padding-left: 5px;
    float: right;
}

.IconBlock {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
    justify-items: center;
    align-items: center;
    width: 100%;
}

.IconGridFlats {
    display: flex;
    justify-content: space-between;
    padding-left: 30px;
    padding-right: 30px;
}

/* Стили для .button с синим градиентом */
.button {
    font-size: var(--font-size-large); /* 16px */
    /*background: var(--primary-button-background); !* Синий градиент: #007bff -> #0056b3 *!*/
    backdrop-filter: blur(10px);
    border: 1px solid var(--border-glass);
    border-radius: var(--border-radius-medium);
    cursor: pointer;
    padding: 12px 20px;
    margin-top: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: var(--shadow-glass);
    transition: all 0.3s ease;
    position: relative;
    z-index: 3;
    overflow: hidden;
}


.button:active {
    transform: scale(0.98);
    box-shadow: var(--box-shadow-light);
}



@keyframes shine {
    0% { left: -100%; }
    20% { left: 100%; }
    100% { left: 100%; }
}

.IconWrap {
    width: 33%;
    justify-items: center;
    align-items: center;
}

.price {
    margin-top: 10px;
    align-items: center;
    width: 100%;
}

.elementsIcons {
    padding-bottom: 10px;
    padding-top: 10px;
}

.flat-card {
    padding: 0;
    border-radius: var(--border-radius-large);
    overflow: hidden;
    box-shadow: var(--box-shadow-light);
    align-self: start;
    /*background-color: var(--background-highlight-color);*/
    transition: transform 0.2s ease-in-out;
    position: relative;
}

.fuelConsumption {
    display: flex;
    align-items: center;
}

.fuelNumber {
    font-size: var(--font-size-xl);
    font-weight: bold;
    vertical-align: bottom;
    color: var(--text-color-primary);
}

/* Стили для .pagination button с синим градиентом */
/*.pagination {*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    gap: 8px;*/
/*    margin-top: 20px;*/
/*    margin-bottom: 150px;*/
/*}*/

.pagination button {
    font-size: var(--font-size-medium); /* 14px */
    background: var(--gradient-button); /* Синий градиент: #007bff -> #0056b3 */
    backdrop-filter: blur(10px);
    border: 1px solid var(--border-glass);
    border-radius: var(--border-radius-default);
    padding: 8px 12px;
    cursor: pointer;
    color: var(--text-color-fifth); /* Белый текст */
    transition: all 0.3s ease;
    position: relative;
    z-index: 3;
    overflow: hidden;
    box-shadow: var(--shadow-glass);
}



.pagination button:active {
    transform: scale(0.98);
    box-shadow: var(--box-shadow-light);
}

.pagination button.active {
    background: #007bff; /* Яркий синий градиент: #007bff -> #0056b3 */
    /*color: var(--text-color-fifth);*/
    font-weight: bold;
    box-shadow: var(--shadow-glass-strong);
}

.pagination button:focus {
    outline: none;
}



.custom-arrow-down {
    transform: rotate(180deg);
    color: var(--neutral-color-9);
}

.car-image {
    width: 100%;
    height: 257px;
    object-fit: cover;
    object-position: center;
    border-radius: var(--border-radius-xl);
}

.company-name-overlay {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: var(--primary-color); /* #007bff */
    padding: 5px 10px;
    border-radius: var(--border-radius-small);
    z-index: 1;
    pointer-events: none;
    user-select: none;
}

.car-details {
    margin-bottom: auto;
    padding: 15px;
    width: 100%;
    text-align: center;
    padding-bottom: 0;
}

.car-details h3 {
    margin: 0;
    font-size: 1.2em;
    color: var(--text-color-fourth);
    pointer-events: none;
    user-select: none;
}

.car-details p {
    margin: 5px 0;
    color: var(--text-color-secondary);
    pointer-events: none;
    user-select: none;
}

.LeftRight .LeftRight {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.LeftRight .left {
    text-align: left;
}

.LeftRight .right {
    text-align: right;
}

.description {
    flex: 1;
    text-align: left;
    color: var(--primary-color);
}

.Class-inf {
    margin: 0;
    text-align: right;
    background-color: var(--primary-color); /* #007bff */
    border-radius: var(--border-radius-xl);
    padding: 10px;
    color: var(--text-color-fifth);
}

.price-details {
    text-align: left;
    width: 100%;
}

.price-item {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
    position: relative;
}

.price-days {
    text-align: right;
    flex-shrink: 0;
    white-space: nowrap;
    margin-right: 10px;
    font-weight: bold;
    color: var(--text-color-secondary);
}

.price-value {
    text-align: right;
    flex-shrink: 0;
    white-space: nowrap;
    font-weight: bold;
    color: var(--text-color-primary);
    font-size: var(--font-size-xl);
    position: relative;
}

.per-day {
    font-size: 80%;
    position: absolute;
    top: -0.2em;
    left: 100%;
    margin-left: 5px;
    color: var(--text-color-tertiary);
}

.datepicker {
    width: 94%;
    border: 1px solid var(--primary-color); /* #007bff */
    border-radius: var(--border-radius-tiny);
    overflow: visible;
}

.button-container {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    width: 100%;
    position: relative;
    z-index: 2;
}

/* Стили для .details-button с синим градиентом */
.details-button {
    font-size: var(--font-size-xl); /* 20px */
    background: var(--primary-button-background); /* Синий градиент: #007bff -> #0056b3 */
    backdrop-filter: blur(10px);
    border: 1px solid var(--border-glass);
    border-radius: var(--border-radius-small);
    cursor: pointer;
    padding: 12px 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: var(--shadow-glass);
    transition: all 0.3s ease;
    position: relative;
    z-index: 3;
    overflow: hidden;
}



.details-button:active {
    transform: scale(0.98);
    box-shadow: var(--box-shadow-light);
}

.details-button.collapsed {
    /*background: var(--primary-color); !* Однотонный синий #007bff вместо стекла *!*/
    color: var(--text-color-fifth); /* Белый текст */
    height: auto;
    box-shadow: var(--shadow-glass);
}

.details-button.collapsed .arrow-up {
    color: var(--text-color-fifth); /* Белая стрелка */
}


.bluetooth-label {
    margin-top: 5px;
    font-size: var(--font-size-small);
    color: var(--text-color-secondary);
}

.separator {
    margin: 0 10px;
    font-weight: 100;
    color: var(--neutral-color-9);
    padding: 0 15px;
    font-weight: 200;
    display: inline-block;
}

.car-detail-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.car-detail {
    flex: 1;
    text-align: center;
    white-space: nowrap;
}

.buttonLine {
    border: none;
    width: 100px;
    height: 100%;
    background-color: var(--neutral-color-1);
    transform: rotate(90deg);
    margin: 0;
}

.btnBlock {
    justify-content: space-between;
    width: 100%;
    display: flex;
}

.slick-slider {
    width: 100%;
    max-width: 100%;
    height: auto;
    position: relative;
    overflow: hidden;
}

.slick-slide img {
    width: 100%;
    height: 257px;
    object-fit: cover;
    object-position: center;
}

.slick-list {
    overflow: hidden;
}

.slick-track {
    display: flex;
}

.Description {
    text-align: left;
    color: var(--text-color-tertiary);
    font-size: var(--font-size-xl);
}

.arrow-up {
    display: inline-block;
    font-family: none, sans-serif;
}

.DeclinedCar {
    color: var(--warning-color);
}

.parameters-container {
    display: flex;
    gap: 20px;
    margin-top: 10px;
    margin-left: 10px;
}

.wd {
    font-size: var(--font-size-xl);
    color: var(--neutral-color-9);
    margin: 10px 0;
    text-align: center;
}

@media (max-width: 385px) {
    .car-list {
        grid-template-columns: 1fr;
        padding: 0;
        gap: 10px;
    }

    .car-card {
        margin: 0;
        width: 100%;
    }

    .car-image {
        height: 200px;
        object-fit: cover;
    }

    .car-details h3 {
        font-size: 1em;
    }

    .details-button {
        padding: 8px 15px;
        font-size: var(--font-size-medium);
    }
}
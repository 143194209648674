@import url('../../Services/variables/variables.css');

/*.category-banner {*/
/*    background-size: cover;*/
/*    background-position: center;*/
/*    background-repeat: no-repeat;*/
/*    max-width: 3600px;*/
/*    width: 100%;*/
/*    height: 691px;*/
/*    position: relative;*/
/*}*/

.banner-title-section {
    max-width: 900px;
}

.banner-content-wrapper {
    position: absolute;
    top: 300px;
    margin: 0 auto;
    filter: none;
}



.category-banner {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    max-width: 3600px;
    width: 100%;
    height: 691px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.banner-background {
    background-size: cover;
    background-position: center;
    position: absolute; /* Ensure it takes full space behind content */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    filter: blur(15px); /* Adjust the blur intensity */
    z-index: 1;
}

/* Content Wrapper Centering */
.banner-content-wrapper {
    display: flex;
    align-items: center;
    text-align: center;
    position: absolute;
    top: 430px;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 20px;
    filter: blur(0);
}



/* Title Section */
.banner-title-section {
    display: flex;
    max-width: 900px;
    /*margin-bottom: 20px;*/
    flex-direction: column;
    align-items: flex-end;
}

/* Title and Beta Styling */
.main-title {
    color: #2B66FE;
    font-family: var(--font-family-primary), sans-serif;
    font-size: 220px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -6.6px;
    margin: 0;
    max-height: 270px;
    text-shadow: 2px 2px black;
    opacity: 66%;
    pointer-events: none; /* Отключает возможность клика */
    user-select: none;
}

.beta-tag {
    color: rgba(255, 255, 255, 0.80);
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: right;
    text-shadow: 2px 2px black;
    pointer-events: none; /* Отключает возможность клика */
    user-select: none;
}

/* Description Styling */
.banner-description {
    font-size: 18px;
    line-height: 1.6;
    width: 50%;
    padding: 0;
    text-align: left;
    pointer-events: none; /* Отключает возможность клика */
    user-select: none;
}

/* Trademark Sign */
.trademark-sign {
    font-size: 24px;
    width: 32px;
    height: 32px;
    margin-top: 70px;
}
@media (max-width: 3600px) {
    .category-banner {
        width: 100%;
        height: auto;
    }
}


@media (max-width: 1600px) {
    .category-banner {
        max-width: 1600px;
        height: 691px;
    }
}

@media (max-width: 1024px) {
    .category-banner {
        max-width: 1024px;
        height: 514px;
    }

    .banner-description {
        display: none;
    }

    .banner-content-wrapper {
        top: 50%;
    }

    .main-title {
        font-size: 180px;
        max-height: 230px;
        margin-top: 147px;
    }

    .beta-tag {
        font-size: 20px;
    }

    .trademark-sign {
        margin-top: 145px;
    }
}

@media (max-width: 800px) {
    .main-title {
        font-size: 140px;
        max-height: 180px;
    }

    .trademark-sign {
        margin-top: 100px;
    }
}

@media (max-width: 600px) {
    .category-banner {
        margin-top: 11px;
        max-width: 600px;
        height: 320px;
    }

    .main-title {
        margin-top: 38px;
        font-size: 90px;
        max-height: 115px;
    }

    .trademark-sign {
        margin-top: 52px;
    }
}

@media (max-width: 428px) {
    .category-banner {
        max-width: 428px;
        height: 245px;
    }

    .main-title {
        /*font-size: 70px;*/
        /*max-height: 90px;*/

    }

    .beta-tag {
        font-size: 10px;
    }

    .trademark-sign {
        margin-top: 101px;
    }
}
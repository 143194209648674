@import url('../../../Services/variables/variables.css');

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

.support-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    /*min-height: 100vh;*/
    width: 100%;
    padding: 20px;
}


/* Avatar Styling */
.support-avatar {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 77px;
}

.support-avatar img {
    width: 125px;
    height: 125px;
    border-radius: 50%; /* Круглая аватарка */
    object-fit: cover;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
    border: 3px solid rgba(255, 255, 255, 0.9);
}

/* Form Container */
.support-form-container {
    max-width: 460px;
    width: 100%;
    padding: 20px;
    border-radius: 25px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(10px);
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
}

/* Back Button */
.back-support-button {
    background: linear-gradient(135deg, #007bff, #0056b3);
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: var(--text-color-fifth, #fff);
    border-radius: 12px;
    padding: 10px 20px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
}

.back-support-button:hover {
    background: linear-gradient(135deg, #0056b3, #003d82);
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25);
}

.back-support-button:active {
    transform: scale(0.98);
}

/* Title Container */
.support-title-container {
    padding: 10px 20px;
    border-radius: 15px;
    backdrop-filter: blur(5px);
}

.support-title {
    font-size: 24px;
    font-weight: 600;
    color: var(--text-color-primary, #333);
    text-align: center;
    margin: 0;
}

/* Form Styling */
.support-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
}

/* Radio Options */
.support-options {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
}

.support-options label {
    font-size: 16px;
    color: var(--text-color-primary, #333);
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.support-options input[type="radio"] {
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.9);
    border: 2px solid rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    cursor: pointer;
    position: relative;
}

.support-options input[type="radio"]:checked {
    background: #007bff;
    border-color: #007bff;
    box-shadow: 0 0 0 4px rgba(0, 123, 255, 0.2);
}

.support-options input[type="radio"]:checked::after {
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: white;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.support-options span {
    font-size: 16px;
    font-weight: 500;
}

/* Textarea Container */
.support-textarea-container {
    position: relative;
    width: 100%;
}

.support-textarea {
    width: 100%;
    height: 167px;
    padding: 15px;
    font-size: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    resize: none;
    transition: all 0.3s ease;
    margin-top: 5px;
}

.support-textarea:focus {
    border-color: #007bff;
    box-shadow: 0 4px 15px rgba(0, 123, 255, 0.2);
    outline: none;
}

/* File Input */
.support-file-label {
    position: absolute;
    top: 10px;
    right: 10px;
    transform: none;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.support-file-icon {
    font-size: 18px;
    color: var(--text-color-primary, #555);
    margin-left: 0;
}

.file-count {
    font-size: 14px;
    color: #007bff;
    font-weight: bold;
    margin-left: 5px;
}

.support-file-input {
    display: none;
}

/* Messenger Selection */
.messenger-selection-container,
.messenger-info-container {
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
}

.messenger-selection-container label,
.messenger-info-container label {
    font-size: 16px;
    color: var(--text-color-primary, #333);
    font-weight: 600;
}

.messenger-selection-container select,
.messenger-info-container input {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.9);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
}

.messenger-selection-container select {
    appearance: none;
    background-image: url('data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16"%3E%3Cpath fill="none" stroke="black" stroke-width="2" d="M1 6l6 6 6-6"%3E%3C/path%3E%3C/svg%3E');
    background-position: right 10px center;
    background-repeat: no-repeat;
}

.messenger-selection-container select:focus,
.messenger-info-container input:focus {
    border-color: #007bff;
    box-shadow: 0 4px 15px rgba(0, 123, 255, 0.2);
    outline: none;
}

/* Instructions */
.support-instructions {
    font-size: 16px;
    color: var(--text-color-primary, #333);
    line-height: 1.5;
    background: rgba(255, 255, 255, 0.9);
    padding: 10px 15px;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.support-instructions a {
    color: #007bff;
    text-decoration: none;
    font-weight: 600;
}

.support-instructions a:hover {
    text-decoration: underline;
}

/* Submit Button */
.support-submit-button {
    background: linear-gradient(135deg, #007bff, #0056b3);
    color: white;
    padding: 12px 20px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    font-size: 16px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    transition: all 0.3s ease;
    margin-top: 20px;
    margin-bottom: 78px;
}

.support-submit-button:hover {
    background: linear-gradient(135deg, #0056b3, #003d82);
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25);
}

.support-submit-button:active {
    transform: scale(0.98);
}

/* Notification */
.push-notification {
    position: fixed;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    background: #28a745;
    color: white;
    padding: 10px 20px;
    border-radius: 12px;
    font-size: 16px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    animation: showNotification 0.5s forwards;
}

@keyframes showNotification {
    0% { opacity: 0; transform: translateX(-50%) translateY(20px); }
    100% { opacity: 1; transform: translateX(-50%) translateY(0); }
}


@keyframes shine {
    0% { left: -100%; }
    20% { left: 100%; }
    100% { left: 100%; }
}

/* Media Queries */
@media (max-width: 600px) {
    .support-form-container {
        max-width: 400px;
        padding: 15px;
    }

    .support-avatar img {
        width: 100px;
        height: 100px;
    }
}

@media (max-width: 415px) {
    .support-form-container {
        max-width: 360px;
    }

    .support-title {
        font-size: 20px;
    }
}

@media (max-width: 380px) {
    .support-form-container {
        max-width: 340px;
    }

    .support-textarea {
        height: 140px;
    }
}
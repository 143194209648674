@import url('../../../Services/variables/variables.css');

.message-item {
  display: flex;
  align-items: flex-start;
  max-width: 70%;
  padding: 15px 20px;
  border-radius: 15px;
  font-size: 15px;
  position: relative;
  background: linear-gradient(145deg, rgba(50, 60, 90, 0.9), rgba(30, 40, 60, 0.8));
  box-shadow:
    0 8px 20px rgba(0, 0, 0, 0.4), /* Основная тень для глубины */
    0 4px 15px rgba(0, 180, 216, 0.2), /* Легкое свечение */
    inset 0 2px 4px rgba(255, 255, 255, 0.1); /* Внутренний блик */
  transform: translateZ(10px); /* Добавляем глубину */
}

.message-item:hover {
  transform: translateZ(15px); /* Усиление эффекта при наведении */
  box-shadow:
    0 10px 25px rgba(0, 0, 0, 0.5),
    0 6px 20px rgba(0, 180, 216, 0.3);
}

.message-item.sent {
  background: linear-gradient(145deg, #00b4d8, #007bff);
  color: #fff;
  align-self: flex-end;
  border-radius: 15px 15px 0 15px;
}

.message-item.received {
  background: linear-gradient(145deg, rgba(60, 70, 100, 0.9), rgba(40, 50, 80, 0.8));
  color: #e0e0e0;
  align-self: flex-start;
  border-radius: 15px 15px 15px 0;
}

.message-item.unread {
  background: linear-gradient(145deg, rgba(70, 80, 110, 0.9), rgba(50, 60, 90, 0.8));
  box-shadow:
    0 8px 20px rgba(0, 0, 0, 0.4),
    0 4px 15px rgba(0, 204, 0, 0.3); /* Зеленоватое свечение для непрочитанных */
}

.message-item.pinned {
  border-left: 4px solid #00cc00;
  background: linear-gradient(145deg, rgba(50, 70, 90, 0.9), rgba(30, 50, 70, 0.8));
  box-shadow:
    0 8px 20px rgba(0, 0, 0, 0.4),
    0 4px 15px rgba(0, 204, 0, 0.4);
}

.message-item.double-clicked {
  box-shadow:
    0 12px 30px rgba(0, 0, 0, 0.6),
    0 6px 25px rgba(255, 255, 255, 0.3);
}

.message-item .avatar {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

.message-content {
  flex: 1;
}

.message-sender {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 5px;
  font-weight: 500;
}

.message-text {
  margin: 5px 0;
  word-wrap: break-word;
}

.message-time {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 5px;
}

.message-time.sent {
  text-align: right;
  color: rgba(255, 255, 255, 0.8);
}

.edited-label {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.6);
  margin-left: 8px;
  font-style: italic;
}

.forwarded-label {
  font-size: 11px;
  color: rgba(255, 255, 255, 0.6);
  margin-left: 8px;
}

.message-actions {
  display: none;
  gap: 8px;
  margin-top: 5px;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.message-item:hover .message-actions {
  display: flex;
  opacity: 1;
}

.message-actions button {
  background: transparent;
  color: #d0d8e0;
  border: none;
  font-size: 13px;
  cursor: pointer;
  padding: 3px 8px;
}

.message-actions button:hover {
  text-decoration: underline;
}

.edit-container {
  display: flex;
  align-items: center;
  gap: 5px;
}

.edit-container input {
  padding: 5px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.edit-container button {
  padding: 5px 10px;
  background-color: #1976d2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.edit-container button:hover {
  background-color: #1565c0;
}

.voice-message-player {
  display: flex;
  align-items: center;
}

.play-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
  transition: transform 0.2s ease;
}

.play-btn:hover {
  transform: scale(1.1);
}

.play-btn svg {
  fill: #1976d2;
}

/* Темы для message-content */
body.night-mode .message-content {
  color: #FFFF;
}

body.whiteLand .message-content {
  color: #FFFFFF;
}

body.pureDark .message-content {
  color: #e6e6e6;
}
@import url('../../Services/variables/variables.css');

.moderation-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: var(--neutral-color-2); /* Background color */
    border-radius: var(--border-radius-large); /* Border radius */
    box-shadow: var(--box-shadow-medium); /* Box shadow */
}

.moderation-page h2 {
    text-align: center;
    font-size: var(--font-size-xxl); /* Font size */
    margin-bottom: 20px;
    color: var(--text-color-primary); /* Text color */
}

.partner-request-card {
    background-color: var(--neutral-color-1); /* Background color */
    border: 1px solid var(--neutral-color-5); /* Border color */
    border-radius: var(--border-radius-large); /* Border radius */
    padding: 15px;
    margin-bottom: 15px;
    box-shadow: var(--box-shadow-light); /* Box shadow */
    transition: transform 0.2s;
}

.partner-request-card:hover {
    transform: translateY(-3px); /* Hover effect */
}

.partner-request-card h3 {
    font-size: var(--font-size-xl); /* Font size */
    margin: 0;
    color: var(--text-color-primary); /* Text color */
}

.partner-request-card p {
    font-size: var(--font-size-large); /* Font size */
    margin: 8px 0;
    color: var(--text-color-secondary); /* Text color */
}

.partner-request-card button {
    padding: 10px 15px;
    border: none;
    border-radius: var(--border-radius-medium); /* Border radius */
    font-size: var(--font-size-large); /* Font size */
    cursor: pointer;
    margin-right: 10px;
    transition: background-color 0.3s;
}

.partner-request-card button:first-child {
    background-color: var(--success-color); /* Success button color */
    color: var(--neutral-color-1); /* Text color */
}

.partner-request-card button:first-child:hover {
    background-color: var(--success-hover-color); /* Success hover color */
}

.partner-request-card button:last-child {
    background-color: var(--warning-color); /* Warning button color */
    color: var(--neutral-color-1); /* Text color */
}

.partner-request-card button:last-child:hover {
    background-color: var(--warning-hover-color); /* Warning hover color */
}

@media (max-width: 600px) {
    .moderation-page {
        padding: 10px;
    }

    .partner-request-card {
        padding: 10px;
    }

    .partner-request-card button {
        width: 100%;
        margin-bottom: 10px;
    }
}

.loader-container {
    position: fixed; /* Логотип по центру экрана */

    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: #2B65FE; /* Белый фон загрузчика */
    z-index: 9999; /* Поверх всего */
}

/* Анимация логотипа */
.logo-animation {
    animation: scaleUp 1.5s ease-in-out infinite; /* Пульсация логотипа */
}

.loader-content {
    display: flex;
    flex-direction: column; /* Логотип и GIF будут друг под другом */
    align-items: center;
}

/* Стили логотипа */
.app-logo {
    width: 700px; /* Размер логотипа */
    height: 700px;
    object-fit: contain;
    opacity: 0;
    justify-content: center;
    margin-bottom: 5px;
    animation: fadeIn 1.5s ease-in-out forwards; /* Плавное появление логотипа */
}
.loading-gif {
    width: 60px; /* Размер GIF */
    height:60px;
    margin-top: -50px;
    object-fit: contain;
    animation: fadeIn 1.5s ease-in-out forwards;
}

.carsbanner1 {
    width: 27%;
    object-fit: contain;
    margin-top: -30px;
}

.loading747-gif {
    width: 90%;
    object-fit: contain;
    opacity: 0;




    animation: fadeIn 1.5s ease-in-out forwards;




}
.loading-text {
  /* Размер GIF */
height: 50%;


    object-fit: contain;
    animation: fadeIn 1.5s ease-in-out forwards;
}
.loading-text2 {

height: 51%;
    object-fit: contain;
    animation: fadeIn 1.5s
}
.loading-text-body {
    justify-self: center;
    display: flex;
}


/* Ключевые кадры для увеличения */
@keyframes scaleUp {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}

/* Ключевые кадры для появления */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}






@media (max-width: 1024px) {

    .app-logo {
        width: 820px; /* Размер логотипа */
        height: 820px;
        margin-top: 170px;
    }
}
/*    .loading747-gif{*/
/*        width: 100%;*/
/*        margin-top: 40px;*/



/*    }*/
/*}*/


@media (max-width: 900px) {

    .app-logo {
        width: 700px; /* Размер логотипа */
        height: 700px;
        margin-top: 150px;
    }
    .loading747-gif{
       width: 100%;
        margin-top: 40px;



    }
}
@media (max-width: 700px) {

    .app-logo {
        width: 670px; /* Размер логотипа */
        height: 670px;
        margin-top: 100px;
    }
    .loading747-gif{
        width: 100%;
        margin-top: 40px;



    }
}
@media (max-width: 600px) {

    .app-logo {
        width: 420px; /* Размер логотипа */
        height: 420px;
        margin-top: 170px;

    }
    .loading747-gif{
        width: 67%;
        object-fit: contain;
        margin-top: 170px;

    }

}
/*@media (max-width: 390px) {*/

/*    .app-logo {*/
/*        width: 370px; !* Размер логотипа *!*/
/*        height: 370px;*/
/*        margin-top: 170px;*/

/*    }*/
/*    .loading747-gif{*/
/*        width: 27%;*/
/*        object-fit: contain;*/
/*        margin-top: 30px;*/


/*    }*/

/*}*/
/*@media (max-width: 330px) {*/

/*    .app-logo {*/
/*        width: 330px; !* Размер логотипа *!*/
/*        height: 330px;*/
/*        margin-top: 100px;*/

/*    }*/
/*    .loading747-gif{*/
/*        height: 100%;*/


/*    }*/


               .mini-profile {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  background: rgba(20, 30, 48, 0.9);
  border-bottom: 1px solid rgba(255, 255, 255, 0.08);
}

.mini-profile .avatar {
  width: 40px; /* Чуть больше, чем в списке чатов */
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
  cursor: pointer;
}

.mini-profile-info {
  display: flex;
  flex-direction: column;
}

.mini-profile-name {
  font-size: 16px;
  color: #d0d8e0;
  font-weight: 500;
  cursor: pointer;
}

.mini-profile-name:hover {
  color: #00b4d8;
}

.mini-profile-stats {
  display: flex;
  gap: 15px;
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
}

.mini-profile-stats span {
  cursor: pointer;
}

.mini-profile-stats span:hover {
  color: #00b4d8;
}

.notifications {
  position: relative;
}

.notification-count {
  position: absolute;
  top: -5px;
  right: -10px;
  background: #ff4444;
  color: white;
  border-radius: 50%;
  padding: 2px 5px;
  font-size: 10px;
}
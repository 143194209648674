.notification-popup-container {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 8px;
  pointer-events: none;
}

.notification-popup-container.map-mode {
  bottom: 80px;
}

.notification-popup {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 12px 16px;
  color: #000000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  max-width: 320px;
  max-height: 300px;
  overflow-y: auto;
  transition: transform 0.2s ease;
  pointer-events: auto;
}

.notification-popup:hover {
  transform: translateY(-2px);
}

.notification-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.notification-content p {
  margin: 0;
  font-size: 14px;
  line-height: 1.4;
  color: #333333;
}

.notification-close {
  background: none;
  border: none;
  color: #888888;
  font-size: 16px;
  cursor: pointer;
  padding: 0 8px;
}

.notification-close:hover {
  color: #000000;
}

.notification-history {
  margin: 8px 0;
  max-height: 150px;
  overflow-y: auto;
  border-top: 1px solid #e0e0e0;
  padding-top: 8px;
}

.mini-message {
  font-size: 12px;
  padding: 6px 10px;
  border-radius: 8px;
  margin: 4px 0;
}

.mini-message.sent {
  background: #007bff;
  color: #ffffff;
  align-self: flex-end;
}

.mini-message.received {
  background: #e9ecef;
  align-self: flex-start;
}

.notification-input {
  display: flex;
  margin-top: 8px;
  gap: 8px;
}

.notification-input input {
  flex: 1;
  padding: 6px 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  outline: none;
}

.notification-input button {
  background: #007bff;
  color: #ffffff;
  border: none;
  padding: 6px 12px;
  border-radius: 8px;
  cursor: pointer;
}

.notification-input button:hover {
  background: #0056b3;
}

.notification-controls {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  padding: 8px;
  pointer-events: auto;
}

.notification-controls button {
  background: #f0f0f0;
  color: #333333;
  border: none;
  padding: 6px 12px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
}

.notification-controls button:hover {
  background: #e0e0e0;
}

/* Адаптивность */
@media (max-width: 768px) {
  .notification-popup {
    max-width: 280px;
    padding: 10px 12px;
  }
}

@media (max-width: 480px) {
  .notification-popup {
    max-width: 240px;
    font-size: 12px;
  }

  .notification-content p {
    font-size: 12px;
  }

  .notification-input input {
    font-size: 12px;
  }

  .notification-controls button {
    padding: 4px 8px;
    font-size: 12px;
  }
}
@import url('../../../Services/variables/variables.css');
.centring{
    width: 100%;
    display: flex;
    justify-content: center;
}
/* Signup CarForm Container */
.signup-form-container {
    padding:20px;
    display: grid;
    align-items: center;
    background-color: var(--neutral-color-1);
    border-radius: var(--border-radius-large);
    border-top-right-radius: 0;
    border-top-left-radius: 0;

}



.container-form{
    width: 100%;
}
.input-container-auth-sign{
    max-height: 150px;


}
.input-form-auth{
    width: 100%;
}
.signup-form-container input,
.signup-form-container textarea,
.signup-form-container select {
    font-size: 16px;
}
.signup-form-container input,
.signup-form-container textarea,
.signup-form-container select {
    font-size: 16px;
}
.image-form{
    display:flex;
    flex-direction: row;
}
.signup-form-content-image {
    width: 100%; /* Ширина изображения адаптивная */
    min-width: 375px; /* Максимальная ширина для больших экранов */
    height: 420px; /* Высота будет изменяться пропорционально */
    border-radius: 18px;
    background-image: url('/src/Components/AuthPage/forlogin.jpeg');
    background-size: cover;
    background-position: center;
    box-shadow: var(--primary-box-shadow);

}
.auth-checkbox-container{
    justify-content: space-between;
width: 100%;
}
.signup-image-container {
    display: block;
    margin: 10px auto;
    border-radius: 1px; /* Закругленные углы */
    transition: filter 0.3s ease, opacity 0.3s ease;
    opacity: 1;
}
.signup-image-container:hover {
    filter: brightness(1.8);
    transform: scale(1.09);
    opacity: 2;
    cursor: pointer;

}

.signup-image {
    max-width: 50px; /* Размер иконки */
    height: auto;
}


.signup-form-content {
    flex: 1;
    display: grid;
    margin-left: 30px;
    margin-right: 30px;

}


/* CarForm Heading */
.signup-form-container h2 {
    text-align: center;
    font-size: var(--font-size-xl); /* Font size variable */
    font-weight: bold;
    color: var(--text-color-primary); /* Text color */

}



.signup-form-header {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 25px;
    margin-left: 26px;
}
.signup-form-header h2 {
    font-size: var(--font-size-large);
    margin-top: 0px;
    font-weight: 300;
    margin-right: 20px;
}
.signup-form-body h1{
    font-size: 28px;
    word-wrap: break-word; /* Перенос длинных слов */
    overflow-wrap: break-word;
    text-align: center;
    user-select: none;
}

.signup-form-body {
    height: 36px;
    font-size: var(--font-size-large);
    font-weight: 400;
    text-align: center;
    padding-bottom: 25px;
    user-select: none;
}



/* CarForm Group Styling */
.signup-form-group {

    justify-content: space-between;
    gap: 10px; /* расстояние между изображениями */
    display: flex;
    margin-top:21px;


}
.form-group label {
    display: block;
    font-size: var(--font-size-medium); /* Font size variable */

    color: var(--text-color-secondary); /* Secondary text color */
}

/* Input Fields */
.form-group input.form-group-input{
    width: 100%; /* Ширина поля ввода адаптивная */
    padding: 10px;
    font-size: 1rem; /* Используем относительные единицы для шрифта */
    border: 1px solid var(--neutral-color-6);
    border-radius: var(--border-radius-medium);
    background-color: var(--primary-form-group-input);
    color: var(--text-color-fourth)!important;
    box-sizing: border-box;
    box-shadow: var(--box-shadow-light);
}

/* Input Focus State */
.form-group input:focus {
    border-color: rgba(100, 100, 100, 1); /* Цвет рамки при фокусе */
    box-shadow: 0px 8px 20px rgba(100, 100, 100, 0.3); /* Более яркая тень при фокусе */
}

.submit-button.submit-button {
    /* Адаптивная ширина кнопки */
    font-size: 1rem; /* Относительные единицы для шрифта */
    background-color: var(--submit-button--background-secondary-color);
    color: var(--submit-button--background-secondary-color--hover);
    border: 1px solid black;
    margin-top:20px ;
    border-radius: var(--border-radius-medium);
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: var(--box-shadow-medium);


}
.submit-button.submit-button:hover {
    background-color: var(--submit-button--background-secondary-color--hover); /* Чёрный фон при наведении */
    color: var(--text-color-primary) !important;
}







.authInputs{
    margin-bottom: 10px;
}

/* Error chat Styling */
.error-message {
    color: var(--warning-color); /* Warning color for errors */
    font-size: var(--font-size-small); /* Small font size */
    margin-top: 5px;
}
.eye-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 1.2em; /* Размер иконки глаза */
}
.messenger-label {
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;

    border:2px #0083ff solid;
    transition: background-color 0.3s;
}

.messenger-label.active {
    background-color: #0083ff; /* Выберите нужный цвет */
}






@media (max-width: 880px) {
    .signup-form-container {
        display: grid;
        padding-left: 10px;
        padding-right: 10px;
        grid-template-columns: 1fr; /* Структура для размещения содержимого */
        align-items: center; /* Центрируем элементы по горизонтали */
        justify-content: center; /* Центрируем элементы по вертикали */

        height: 100%;
        width: 100%;
        max-width: 600px; /* Ограничиваем максимальную ширину */

        overflow-x: hidden; /* Убираем горизонтальную прокрутку */
        /* Добавляем вертикальную прокрутку, если необходимо */
    }

     input, textarea, select {
        font-size: 16px;
    }
    .image-form {
        display: grid;
        justify-content: center; /* Центрируем содержимое внутри */
        width: 100%;
    }
  .signup-form-body {
        display: flex;
        flex-direction: row; /* Размещаем текст и изображение в строку */
        align-items: center; /* Центрируем элементы по вертикали */
        justify-content: center; /* Выравниваем элементы слева */
        width: 100%; /* Задаем полную ширину */
        margin-top: 10px;
    }

    .signup-form-body p {
        margin-top: -14px;
    }



    .signup-form-header {
        display: flex;
        width: 100%;
       justify-content: center;
        /*margin-bottom: 25px;*/
        margin-top: 20px;
        margin-left: 0px;
    }


    .signup-form-content {
        display: flex;
        flex-direction: column; /* Устанавливаем элементы в колонку */
        align-items: center; /* Центрируем элементы по горизонтали */
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
    }

    .signup-form-content-image {
        min-width: 360px; /* Максимальная ширина для больших экранов */
        height: 237px; /* Высота будет изменяться пропорционально */
    }

    .signup-form-group {
        display: flex;
        flex-direction: row; /* Устанавливаем элементы в колонку */
        width: 100%; /* Полная ширина для полей ввода */
        margin-bottom: 46px;
    }
     .submit-button.submit-button {
        padding: 7px; /* Увеличиваем внутренний отступ для кнопки */
        font-size: 1.5rem; /* Увеличиваем размер шрифта на 1.5 раза */
        margin-top: 20px; /* Добавляем отступ сверху */
    }

}

@media (max-width: 420px) {
    .signup-form-container {
        display: grid;
        padding-left: 10px;
        padding-right: 10px;
        grid-template-columns: 1fr; /* Структура для размещения содержимого */
        align-items: center; /* Центрируем элементы по горизонтали */
        justify-content: center; /* Центрируем элементы по вертикали */

        height: 100%;
        max-width: 390px;
        width:100%;
        overflow-x: hidden; /* Убираем горизонтальную прокрутку */
        /* Добавляем вертикальную прокрутку, если необходимо */
    }

     input, textarea, select {
        font-size: 16px;
    }
    .image-form {
        display: grid;
        justify-content: center; /* Центрируем содержимое внутри */
        width: 100%;
    }
   .signup-form-body {
        display: flex;
        flex-direction: row; /* Размещаем текст и изображение в строку */
        align-items: flex-start; /* Центрируем элементы по вертикали */
        justify-content: center; /* Выравниваем элементы слева */
        width: 100%; /* Задаем полную ширину */
        padding-bottom: 50px;
    }

   .signup-form-body h1{
       margin-left: 20px;
       margin-bottom: 0px;
   }
 .signup-form-body p{
           margin-left: 20px;
       }


    .signup-form-header {
        display: flex;
        width: 100%;
       justify-content: center;
        margin-bottom: 25px;
        margin-top: 20px;

    }


    .signup-form-content {
        display: flex;
        flex-direction: column; /* Устанавливаем элементы в колонку */
        align-items: center; /* Центрируем элементы по горизонтали */
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
    }

    .signup-form-content-image {
        width: 100%; /* Увеличиваем ширину изображения */
        margin-bottom: 20px;
        max-width: 340px; /* Ограничиваем максимальную ширину изображения */
        height: 200px;
         justify-content: center;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
    }

    .signup-form-group {
        display: flex;
        flex-direction: row; /* Устанавливаем элементы в колонку */
        width: 100%; /* Полная ширина для полей ввода */
        margin-bottom: 10px;
    }
     .submit-button.submit-button {
        padding: 5px; /* Увеличиваем внутренний отступ для кнопки */
        font-size: 1.5rem; /* Увеличиваем размер шрифта на 1.5 раза */
        margin-top: 20px; /* Добавляем отступ сверху */
         max-width: 340px;
    }


}
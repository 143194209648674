@import url('../../Services/variables/variables.css');

/* Unavailable Category Container */
.unavailable-category-container {
    text-align: center;
    margin-top: 100px;
}

.unavailable-category-container h2 {
    font-size: var(--font-size-xxl); /* Using variable for font size */
    margin-bottom: 20px;
    color: var(--text-color-primary); /* Primary text color */
}

.unavailable-category-container p {
    font-size: var(--font-size-large); /* Using variable for paragraph font size */
    margin-bottom: 30px;
    color: var(--text-color-secondary); /* Secondary text color */
}

/* Partner Button */
.partner-button {
    padding: 10px 20px;
    color: var(--text-color-primary); /* Neutral color for text */
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}



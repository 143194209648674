@import url('../../../../Services/variables/variables.css');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
  background: var(--glass-background); /* Легкий градиент для фона */
}

/* Container */
.cdp-container {
  border-radius: 25px;
  padding: 20px;
  max-width: 400px;
  margin: 20px auto;
  backdrop-filter: blur(10px); /* Эффект стекла */
}

/* Date Range Display */
.cdp-date-range {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
  border-radius: 15px;
  padding: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.cdp-date-box {
  flex: 1;
  text-align: center;
  padding: 10px;
}

.cdp-date-box label {
  display: block;
  font-size: 0.9rem;
  color: var(--text-color-primary, #333);
  margin-bottom: 5px;
  font-weight: 500;
}

.cdp-date-box span {
  font-size: 1.1rem;
  color: var(--text-color-primary, #333);
  font-weight: 600;
}

/* Calendar */
.cdp-calendar {
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}

.cdp-calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.cdp-nav-btn {
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.9), rgba(240, 240, 245, 0.8));
  border: 1px solid rgba(255, 255, 255, 0.3);
  font-size: 1.5rem;
  color: #007bff;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}



.cdp-calendar-header span {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--text-color-primary, #333);
}

.cdp-selection-hint {
  text-align: center;
  font-size: 0.9rem;
  color: #007bff;
  margin-bottom: 10px;
}

.cdp-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
}

.cdp-day-header {
  text-align: center;
  font-size: 0.9rem;
  color: var(--text-color-fourth, #666);
  font-weight: 600;
  padding: 8px 0;
}

.cdp-day {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: var(--text-color-fourth, #555);
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s ease;
}



.cdp-day.disabled {
  color: #8e7979;
  cursor: not-allowed;
}

.cdp-day.selected-start,
.cdp-day.selected-end {
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: #fff;
  font-weight: 600;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.cdp-day.in-range {
  background: rgba(0, 123, 255, 0.2);
}

.cdp-clear-btn {
  display: block;
  margin: 15px auto 0;
  padding: 10px 20px;
  background: linear-gradient(135deg, #dc3545, #c82333);
  color: var(--text-color-fifth, #fff);
  border: none;
  border-radius: 12px;
  cursor: pointer;
  font-size: 0.9rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}



/* Pricing */
.cdp-pricing {
  text-align: center;
  padding: 15px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
}

.cdp-price {
  font-size: 1.1rem;
  color: var(--text-color-primary, #333);
  margin: 5px 0;
}

.cdp-price.total {
  font-size: 1.3rem;
  font-weight: 600;
  color: #007bff;
}

/* Actions */
.cdp-actions {
  display: flex;
  gap: 10px;
  justify-content: center;
  padding: 15px 0;
}

.cdp-book-btn,
.cdp-chat-btn {
  flex: 1;
  padding: 12px;
  font-size: 1rem;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  color: var(--text-color-fifth, #fff);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.cdp-book-btn {
  background: linear-gradient(135deg, #007bff, #0056b3);
}



.cdp-chat-btn {
  background: linear-gradient(135deg, #17a2b8, #117a8b);
}





@keyframes shine {
  0% { left: -100%; }
  20% { left: 100%; }
  100% { left: 100%; }
}

/* Modal */
.cdp-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cdp-modal {
  position: relative;
  padding: 20px;
  border-radius: 20px;
  max-width: 90%;
  width: 500px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
}

.cdp-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 32px;
  height: 32px;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 50%;
  font-size: 20px;
  color: var(--text-color-primary, #333);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}


.cdp-form-closed {
  text-align: center;
  font-size: 1.1rem;
  color: var(--text-color-fourth, #666);
  padding: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .cdp-container {
    max-width: 100%;
    padding: 15px;
    margin: 10px auto;
  }

  .cdp-date-range {
    flex-direction: column;
    gap: 15px;
  }

  .cdp-day {
    width: 35px;
    height: 35px;
    font-size: 0.9rem;
  }

  .cdp-actions {
    flex-direction: column;
    gap: 10px;
  }

  .cdp-book-btn,
  .cdp-chat-btn {
    width: 100%;
  }

  .cdp-modal {
    width: 95%;
    padding: 15px;
  }
}
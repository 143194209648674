@import url('../../Services/variables/new-variables.css');

/* General Home Page Layout */
.home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.category-home-h2 {
    z-index: 2;
    padding: 20px;
    margin: 20px;
    position: relative;
}
.houses-card {
    opacity: 90%;
}

/* Text Banner Section */
.home-page-banner {
    max-width: calc(100vw - 200px);
    text-align: center;
    color: #333;
    margin-top: 110px;
    margin-bottom: 30px;
}

.home-banner-content-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
}

.home-title-text .home-main-title {
    color: #2B66FE;
    font-family: Oswald, sans-serif;
    font-size: 210px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: -6.3px;
    max-height: 250px;
    pointer-events: none;
    user-select: none;
}

.home-beta-tag {
    color: #2B66FE;
    font-family: Oswald, sans-serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: end;
    pointer-events: none;
    user-select: none;
}

.home-beta-tag2 {
    color: #2B66FE;
    font-family: Oswald, sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: end;
}

.home-banner-description {
    color: #2B66FE;
    font-family: Oswald, sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 842px;
    max-height: 170px;
    margin-left: 50px;
    pointer-events: none;
    user-select: none;
}

/* Category Grid Section */
.home-category-card {
    position: relative;
    color: var(--home--inner-block--text-color);
    border-radius: 20px;
    background-color: var(--primary-background);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 30px 0 var(--primary--shadow-color);
    cursor: pointer; /* Добавлено для интерактивности */
}

.home-category-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Добавляем эффект нажатия для карточек */
.home-category-card:active {
    animation: softPress 0.5s ease forwards;
    box-shadow: 0 5px 15px rgba(0, 123, 255, 0.8), 0 10px 25px rgba(0, 123, 255, 0.5);
}

/* Анимация softPress из первого файла */
@keyframes softPress {
    0% {
        transform: scale(0.99);
    }
    30% {
        transform: scale(0.915);
    }
    100% {
        transform: scale(0.99);
    }
}

.water-recreation-image .inner-block .img {
    width: 80%;
    height: auto;
    object-fit: contain;
    max-width: 300%;
    max-height: 100%;
}
.water-recreation-card .inner-block .video {
    width: 80%;
    height: auto;
    object-fit: contain;
    max-width: 300%;
    max-height: 100%;
}

.inner-block {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.vehicles-card .category-text {
    margin-left: -50px;
}

.vehicles-card .category-text,
.houses-card .category-text,
.apartments-card .category-text,
.tours-adventures-card .category-text,
.restaurants-card .category-text,
.bike-card .category-text,
.health-card .category-text {
    position: relative;
    z-index: 2;
    font-weight: bold;
    font-size: 2em;
    color: #FFFFFF;
    transition: color 0.3s ease, text-shadow 0.3s ease;
    -webkit-text-stroke: 1px #696969;
    text-shadow: 2px 2px 5px rgb(0 0 0 / 50%);
}

.vehicles-card .category-text:hover,
.houses-card .category-text:hover,
.apartments-card .category-text:hover,
.tours-adventures-card .category-text:hover,
.restaurants-card .category-text:hover,
.health-card .category-text:hover {
    /* Оставляем возможность для кастомизации hover */
}

.water-recreation-card .category-text {
    position: relative;
    z-index: 2;
    font-weight: bold;
    font-size: 3em;
    color: #FFFFFF;
    transition: color 0.3s ease, text-shadow 0.3s ease;
    -webkit-text-stroke: 1px #696969;
    text-shadow: 2px 2px 5px rgb(0 0 0 / 50%);
}

.category img {
    position: relative;
    z-index: 2;
}

/* Category Grid Section */
.home-category-grid {
    display: grid;
    grid-template-columns: repeat(4, minmax(200px, 1fr));
    gap: 20px;
    width: 100%;
    max-width: calc(100vw - 200px);
    margin-bottom: 150px;
}

/* CARDS */
/* Specific Category Card Positions */
.vehicles-card { grid-column: 1; grid-row: 1; }
.houses-card { grid-column: 1; grid-row: 2; }
.apartments-card { grid-column: 2; grid-row: 1 / span 2; }
.tours-adventures-card { grid-column: 3; grid-row: 1; }
.health-card { grid-column: 4; grid-row: 1; }
.water-recreation-card { grid-column: 1 /span 3; grid-row: 4; }
.restaurants-card { grid-column: 3 /span 2; grid-row: 2; }
.map-card { grid-column: 1 / span 3; grid-row: 3; }
.bike-card { grid-column: 4; grid-row: 3 /span 2; }

.events-card,
.placeholder-card {
    display: none;
}

.bars-cash-wrapper {
    display: grid;
    grid-column: 1 / span 4;
    grid-row: 7;
    height: 100%;
    gap: 20px;
}

.vehicles-card .inner-block {
    background: #2B66FE;
    border-radius: 270px 20px 20px 20px;
    box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.25);
    background-size: cover;
}
.vehicles-card .inner-block img {
    margin-top: 45px;
    width: 132%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
    z-index: 10;
}
.vehicles-card .category-text {
    bottom: 95px;
    left: 117px;
}

.houses-card .inner-block {
    box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.25);
    background-size: cover;
}
.houses-card .inner-block img {
    width: 111%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
    z-index: 10;
}

.water-recreation-card .inner-block {
    box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.25);
    background-size: cover;
    width: 100%;
    background-position: center;
}
.water-recreation-card .inner-block img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 119%;
    margin-top: -21px;
    object-fit: cover;
    border-radius: inherit;
    z-index: 1;
}

.apartments-card .inner-block {
    box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.25);
    background-size: cover;
    background-position: center;
}
.apartments-card .inner-block img {
    position: absolute;
    top: -6px;
    left: 0;
    width: 100%;
    height: 102%;
    object-fit: cover;
    border-radius: inherit;
    z-index: 10;
}
.apartments-card .category-text {
    top: 220px;
    font-size: 2.5em;
}

.bike-card .inner-block {
    box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.25);
    background-size: cover;
    background-position: center;
}
.bike-card .inner-block img {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
    z-index: 10;
}

.restaurants-card .inner-block {
    box-shadow: 0 0 30px 5px rgba(0, 0, 0, 0.25);
    background-size: cover;
    background-position: center;
}
.restaurants-card .inner-block img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
    z-index: 10;
}

.tours-adventures-card .inner-block img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
    z-index: 10;
}
.tours-adventures-card .category-text {
    top: 91px;
    right: 33px;
}

.events-card .inner-block,
.placeholder-card .inner-block {
    background: #2B66FE;
}

.health-card {
    height: 230px;
}
.health-card .inner-block img {
    position: absolute;
    top: -3px;
    left: 0;
    width: 100%;
    height: 120%;
    object-fit: cover;
    border-radius: inherit;
    z-index: 10;
}

.water-recreation-card,
.placeholder-card,
.events-card,
.vehicles-card,
.health-card {
    color: rgb(255, 255, 255, 0.80);
}

/* Grouped by height */
.vehicles-card,
.tours-adventures-card,
.houses-card,
.water-recreation-card {
    height: 280px;
}

.apartments-card,
.food-card {
    height: 580px;
}

.bike-card {
    height: 100%;
}

.map-card {
    height: 100%;
}

.restaurants-card {
    height: 100%;
}

.health-card {
    height: 100%;
}

.cash-card,
.placeholder-card,
.events-card {
    height: 55px;
}

/* Media Queries */
@media (max-width: 3440px) {
    .inner-block {
        height: 100%;
    }
    .restaurants-card {
        grid-column: 3; grid-row: 2;
    }
    .bike-card {
        grid-column: 4; grid-row: 3 /span 3;
    }
    .health-card {
        grid-column: 4; grid-row: 1 /span 2;
    }
    .tours-adventures-card .category-text {
        top: 35px;
        left: 3%;
        font-size: 4em;
        text-align: left;
    }
    .restaurants-card .category-text {
        font-size: 3.7em;
        left: -17%;
    }
    .health-card .category-text {
        font-size: 4.2em;
        left: -23%;
        top: -19%;
    }
    .health-card .inner-block img {
        width: 130%;
    }
}

@media (max-width: 2100px) {
    .restaurants-card {
        grid-column: 3/span 2; grid-row: 2;
    }
    .bike-card {
        grid-column: 4; grid-row: 3 /span 3;
    }
    .health-card {
        grid-column: 4; grid-row: 1;
    }
    .tours-adventures-card .category-text {
        top: 40px;
        left: 5%;
        font-size: 3.6em;
        text-align: left;
    }
    .restaurants-card .category-text {
        font-size: 4em;
        left: -22%;
    }
    .bike-card .category-text {
        font-size: 3.3em;
    }
}

@media (max-width: 1700px) {
    .home-banner-description {
        font-size: 25px;
    }
    .home-title-text .home-main-title {
        font-size: 188px;
    }
    .home-beta-tag {
        font-size: 36px;
    }
    .home-category-grid, .home-page-banner {
        max-width: calc(100vw - 120px);
    }
    .tours-adventures-card .category-text {
        top: 50px;
        left: 4%;
        font-size: 3em;
        text-align: left;
    }
    .houses-card .category-text {
        bottom: -78px;
        left: -46px;
        font-size: 2.5em;
    }
    .health-card .category-text {
        font-size: 3.8em;
        left: -23%;
        top: -19%;
    }
    .houses-card .inner-block {
        height: 280px;
    }
    .houses-card .inner-block img {
        width: 100%;
    }
    .restaurants-card .category-text {
        font-size: 3.7em;
        top: 0px;
        left: -19%;
    }
}

@media (max-width: 1400px) {
    .home-banner-description {
        font-size: 20px;
        width: 48%;
    }
    .tours-adventures-card .category-text {
        top: 50px;
        left: 5%;
        font-size: 2.6em;
        text-align: left;
    }
    .home-banner-content-wrapper {
        justify-content: flex-end;
    }
    .home-title-text .home-main-title {
        font-size: 110px;
        margin-top: -5px;
    }
    .home-beta-tag {
        font-size: 24px;
    }
    .vehicles-card,
    .tours-adventures-card,
    .water-recreation-card,
    .houses-card {
        height: 260px;
    }
    .vehicles-card .inner img,
    .tours-adventures-card .inner img,
    .houses-card .inner img {
        height: 260px;
    }
    .map-card {
        height: 100%;
    }
    .cash-card,
    .placeholder-card,
    .events-card {
        height: 55px;
    }
    .apartments-card {
        height: 540px;
    }
    .apartments-card .category-text {
        top: 210px;
    }
    .vehicles-card .category-text {
        bottom: 77px;
        left: 71px;
        font-size: 2.4em;
    }
    .restaurants-card .category-text {
        font-size: 3.7em;
        top: 0px;
        left: -17%;
    }
    .health-card .category-text {
        font-size: 3.5em;
    }
}

@media (max-width: 1300px) {
    .vehicles-card,
    .water-recreation-card,
    .tours-adventures-card,
    .houses-card {
        height: 284px;
    }
    .houses-card .category-text {
        bottom: -78px;
        left: -46px;
        font-size: 2.5em;
    }
    .apartments-card {
        height: 100%;
    }
    .home-category-grid, .home-page-banner {
        max-width: calc(100vw - 60px);
    }
    .restaurants-card .category-text {
        font-size: 3.7em;
        top: 0px;
        left: -17%;
    }
    .apartments-card .category-text {
        top: 170px;
    }
    .vehicles-card .category-text {
        bottom: 77px;
        left: 71px;
        font-size: 2.4em;
    }
    .vehicles-card .inner-block img {
        margin-top: 90px;
    }
    .tours-adventures-card .category-text {
        top: 50px;
        left: 4%;
        font-size: 2.3em;
        text-align: left;
    }
    .health-card .category-text {
        font-size: 3em;
    }
}

@media (max-width: 1200px) {
    .vehicles-card,
    .tours-adventures-card,
    .water-recreation-card,
    .houses-card {
        height: 280px;
    }
    .category-text {
        font-size: 1em;
    }
    .map-card {
        height: 100%;
    }
    .houses-card .category-text {
        bottom: -62px;
        left: -38px;
        font-size: 2.5em;
    }
    .home-category-grid {
        gap: 10px;
    }
    .apartments-card {
        height: 100%;
    }
    .apartments-card .category-text {
        top: 173px;
        font-size: 2.4em;
    }
    .tours-adventures-card .category-text {
        top: 50px;
        left: 4%;
        font-size: 2.3em;
        text-align: left;
        -webkit-text-stroke: 1px #696969;
        text-shadow: 2px 2px 5px rgb(0 0 0 / 50%);
    }
    .restaurants-card .category-text {
        font-size: 3.7em;
        top: 0px;
        left: -17%;
    }
    .vehicles-card .category-text {
        bottom: 77px;
        left: 65px;
        font-size: 2.4em;
    }
    .vehicles-card .inner-block img {
        margin-top: 76px;
    }
    .water-recreation-card .category-text {
        font-size: 2.7em;
    }
    .health-card .category-text {
        font-size: 2.5em;
    }
}

@media (max-width: 1024px) {
    .vehicles-card,
    .tours-adventures-card,
    .water-recreation-card,
    .houses-card {
        height: 240px;
    }
    .vehicles-card .inner-block img {
        margin-top: 66px;
    }
    .restaurants-card {
        height: 100%;
    }
    .restaurants-card .category-text {
        left: 70px;
    }
    .houses-card .category-text {
        bottom: -62px;
        left: -38px;
        font-size: 2.6em;
    }
    .vehicles-card .inner img,
    .tours-adventures-card .inner img,
    .houses-card .inner img,
    .water-recreation-card .inner img {
        height: 175px;
    }
    .apartments-card {
        height: 100%;
    }
    .apartments-card .category-text {
        top: 123px;
        font-size: 2.4em;
    }
    .restaurants-card .category-text {
        font-size: 3.7em;
        top: 0px;
        left: -15%;
    }
    .health-card .category-text {
        font-size: 2.5em;
    }
    .bike-card .category-text {
        font-size: 2.6em;
    }
}

@media (max-width: 930px) {
    .home-category-grid, .home-page-banner {
        max-width: calc(100vw - 20px);
    }
    .home-beta-tag {
        margin-top: -25px;
    }
    .home-title-text .home-main-title {
        font-size: 185px;
    }
    .home-banner-content-wrapper {
        flex-direction: column;
        margin-top: -50px;
    }
    .home-category-grid {
        grid-template-columns: 1fr 1fr;
    }
    .home-banner-description {
        width: 90%;
        margin-left: 0;
        display: none;
    }
    .map-card {
        height: 100%;
    }
    .events-card .inner-block,
    .placeholder-card .inner-block {
        width: 100%;
        top: -64%;
        left: -77%;
    }
    .placeholder-card { grid-column: 2; grid-row: 5; }
    .water-recreation-card,
    .events-card,
    .placeholder-card {
        color: var(--home--inner-block--text-color);
    }
    .apartments-card .inner-block {
        background: #2B66FE;
    }
    .apartments-card {
        color: rgb(255, 255, 255, 0.80);
    }
    .events-card {
        display: none;
    }
    .vehicles-card { grid-column: 1; grid-row: 1; }
    .houses-card { grid-column: 2; grid-row: 1; }
    .apartments-card { grid-column: 2; grid-row: 2; }
    .tours-adventures-card { grid-column: 1; grid-row: 2; }
    .water-recreation-card { grid-column: 1 /span 2; grid-row: 4; }
    .restaurants-card { grid-column: 1 /span 2; grid-row: 7; }
    .health-card { grid-column: 2; grid-row: 8; }
    .bike-card { grid-column: 1; grid-row: 8; }
    .map-card { grid-column: 1 / span 2; grid-row: 6; }
    .bars-cash-wrapper { grid-column: 1 / span 2; grid-row: 10; display: flex; gap: 10px; }
    .vehicles-card,
    .houses-card,
    .restaurants-card {
        height: 423px;
    }
    .health-card {
        height: 200px;
    }
    .restaurants-card {
        height: 162px;
    }
    .bike-card {
        height: 100%;
    }
    .health-card .category-text {
        font-size: 3em;
    }
    .water-recreation-card {
        height: 255px;
    }
    .apartments-card,
    .tours-adventures-card {
        height: 565px;
    }
    .apartments-card .inner img {
        height: 370px;
    }
    .vehicles-card .inner img {
        height: 100%;
    }
    .vehicles-card .inner-block img {
        margin-top: 99px;
    }
    .vehicles-card .category-text {
        bottom: 134px;
        left: 122px;
        font-size: 3em;
    }
    .houses-card .category-text {
        bottom: -130px;
        left: -100px;
        font-size: 3em;
    }
    .tours-adventures-card .category-text {
        top: 100px;
        left: 15px;
        font-size: 3.5em;
        text-align: left;
    }
    .apartments-card .category-text {
        top: 143px;
        font-size: 3.6em;
    }
    .restaurants-card .category-text {
        font-size: 3.9em;
        top: 0px;
        left: -23%;
    }
}

@media (max-width: 766px) {
    .home-title-text .home-main-title {
        font-size: 155px;
    }
    .restaurants-card .category-text {
        font-size: 3.4em;
        top: 0px;
        left: -22%;
    }
    .tours-adventures-card .category-text {
        top: 104px;
        text-align: left;
        left: 5px;
        font-size: 3em;
    }
    .tours-adventures-card .inner-block img {
        width: 156%;
    }
    .vehicles-card .category-text {
        left: 86px;
        top: -105px;
        font-size: 2.5em;
    }
    .houses-card .category-text {
        bottom: 71px;
        top: 95px;
        left: -61px;
        font-size: 2.5em;
    }
    .vehicles-card .inner-block img {
        margin-top: 110px;
        width: 132%;
    }
    .vehicles-card,
    .houses-card {
        height: 364px;
    }
    .restaurants-card {
        height: 181px;
    }
    .tours-adventures-card {
        height: 565px;
    }
    .health-card .inner-block img {
        width: 146%;
    }
}

@media (max-width: 600px) {
    .home-title-text .home-main-title {
        font-size: 110px;
    }
    .home-category-grid {
        margin-bottom: 100px;
    }
    .vehicles-card,
    .houses-card,
    .tours-adventures-card {
        height: 254px;
    }
    .restaurants-card {
        height: 172px;
    }
    .vehicles-card .category-text {
        bottom: 63px;
        left: 55px;
        font-size: 2.2em;
        top: -67px;
    }
    .vehicles-card .inner-block img {
        margin-top: 90px;
    }
    .tours-adventures-card .category-text {
        top: 78px;
        text-align: left;
        left: 5px;
        font-size: 2.2em;
    }
    .houses-card .category-text {
        bottom: 65px;
        top: 70px;
        left: -50px;
        font-size: 2.2em;
    }
    .vehicles-card .inner img,
    .houses-card .inner img,
    .tours-adventures-card .inner img,
    .restaurants-card .inner img {
        height: 100%;
    }
    .water-recreation-card .inner img {
        width: 113%;
        height: 100%;
    }
    .apartments-card,
    .water-recreation-card,
    .tours-adventures-card {
        height: 310px;
    }
    .water-recreation-card {
        height: 223px;
    }
    .water-recreation-card .inner-block {
        width: 100%;
    }
    .apartments-card .category-text {
        top: 101px;
        font-size: 2.2em;
    }
    .restaurants-card .category-text {
        left: -20%;
        font-size: 2.9em;
    }
    .health-card .category-text {
        font-size: 2.6em;
    }
}

@media (max-width: 432px) {
    .home-title-text .home-main-title {
        font-size: 99px;
    }
    .home-banner-description {
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.12px;
    }
    .vehicles-card {
        height: 224px;
    }
    .houses-card .category-text {
        left: -24px;
    }
    .tours-adventures-card .category-text {
        top: 78px;
        text-align: left;
        left: -11px;
        font-size: 2.2em;
    }
    .vehicles-card .category-text {
        top: -60px;
        left: 44px;
    }
    .vehicles-card,
    .houses-card,
    .tours-adventures-card {
        height: 235px;
    }
    .restaurants-card {
        height: 161px;
    }
    .water-recreation-card {
        width: 100%;
        height: 161px;
    }
    .houses-card .inner img {
        height: 290px;
    }
    .houses-card .inner-block img {
        width: 112%;
    }
    .apartments-card .category-text {
        font-size: 2em;
    }
    .restaurants-card .category-text {
        left: -73px;
        font-size: 2.4em;
    }
    .tours-adventures-card {
        height: 100%;
    }
    .tours-adventures-card .category-text {
        left: 3px;
        font-size: 1.8em;
    }
    .health-card .category-text {
        font-size: 2em;
    }
    .bike-card .category-text {
        font-size: 2.3em;
        top: 15px;
    }
}

@media (max-width: 360px) {
    .home-title-text .home-main-title {
        font-size: 92px;
    }
    .restaurants-card .category-text {
        left: -10%;
        font-size: 2.4em;
    }
    .vehicles-card .category-text {
        left: 23px;
        font-size: 2.4em;
    }
    .houses-card .category-text {
        font-size: 2em;
        top: 50px;
        left: -10px;
    }
    .tours-adventures-card .category-text {
        font-size: 1.5em;
        top: 60px;
    }
    .apartments-card .category-text {
        font-size: 1.61em;
        top: 78px;
    }
    .vehicles-card .inner-block img {
        width: 157%;
        top: -29px;
        position: relative;
    }
    .houses-card .inner-block img {
        width: 100%;
        height: 183px;
    }
    .vehicles-card,
    .houses-card {
        height: 183px;
    }
    .tours-adventures-card,
    .apartments-card {
        height: 241px;
    }
}
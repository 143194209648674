.avatar-game-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
}

.game-avatar {
  width: 125px;
  height: 125px;
  border-radius: 50%;
  object-fit: cover;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  border: 3px solid var(--neutral-color-1, #e0e0e0);
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1), box-shadow 0.3s ease, border-color 0.3s ease;
  cursor: pointer;
  z-index: 2;
}

.game-avatar:hover {
  box-shadow: 0 5px 20px rgba(0, 123, 255, 0.6);
  border-color: #007bff;
}

.game-avatar.jump {
  animation: jump-animation 1s ease-in-out;
}

.game-avatar.spin {
  animation: spin-animation 1s ease-in-out;
}

.game-avatar.pulse {
  animation: pulse-animation 1s ease-in-out;
}

.game-avatar.press {
  animation: press-animation 0.2s ease forwards; /* Matches profile button */
}

@keyframes jump-animation {
  0% { transform: translateY(0); }
  50% { transform: translateY(-25px); }
  100% { transform: translateY(0); }
}

@keyframes spin-animation {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes pulse-animation {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

@keyframes press-animation {
  0% { transform: scale(1); }
  100% { transform: scale(0.98); box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15); }
}

.game-avatar.avatar-glowing {
  animation: glow 1.5s infinite alternate;
}

@keyframes glow {
  0% {
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    border-color: var(--neutral-color-1, #e0e0e0);
  }
  100% {
    box-shadow: 0 0 15px rgba(0, 123, 255, 1);
    border-color: #007bff;
  }
}

.game-message {
  bottom: -70px;
  /*background: rgba(255, 255, 255, 0.95);*/
  padding: 10px 15px;
  border-radius: 12px;
  backdrop-filter: blur(5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  max-width: 250px;
  text-align: center;
  color: var(--text-color-primary, #333);
  font-size: 14px;
  z-index: 2;
  transition: opacity 0.2s ease, transform 0.2s ease; /* Faster transition */
  opacity: 1;
  transform: translateY(0);
}

.game-message.hidden {
  opacity: 0;
  transform: translateY(10px);
  pointer-events: none;
}

.game-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin-top: 10px;
  padding: 5px 10px;
  background: linear-gradient(135deg, #007bff, #0056b3);
  border-radius: 10px;
  color: white;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.game-badge:hover {
  transform: scale(1.05);
}

.badge-icon {
  font-size: 16px;
}

.badge-title {
  font-weight: 600;
}

.badge-level {
  font-size: 12px;
  opacity: 0.9;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 16px;
  color: #666; /* Soft gray instead of red */
  cursor: pointer;
  transition: transform 0.3s ease, color 0.3s ease;
}

.close-button:hover {
  transform: scale(1.2);
  color: #333; /* Darker gray on hover */
}

.grok-signature {
  display: block;
  margin-top: 5px;
  font-size: 10px;
  color: #007bff;
  font-style: italic;
  animation: fadeIn 1s ease-in;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
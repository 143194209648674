.car-class-container::-webkit-scrollbar {
    width: 10%; /* Ширина скроллбара */
    height: 8px; /* Высота скроллбара для горизонтального скролла */
    margin-right: 10px; /* Смещение скроллбара немного вправо */
}

.car-class-container::-webkit-scrollbar-thumb {
    background-color: #007bff; /* Цвет ползунка */
    border-radius: 10px; /* Скругление ползунка */
    /* Внешний цвет вокруг ползунка */

}

.car-class-container::-webkit-scrollbar-track {
    background-color: var(--background-highlight-color); /* Цвет трека (фона скроллбара) */
    border-radius: 10px; /* Скругление трека */
}

/* При наведении на ползунок */
.car-class-container::-webkit-scrollbar-thumb:hover {
    background-color: #0056b3; /* Более темный цвет при наведении */
}

.car-class-container {
    display: flex;
    flex-wrap: nowrap; /* Запрещаем перенос элементов */
    max-width: 100%; /* Убираем лишнюю ширину */
    overflow-x: auto; /* Добавляем горизонтальный скролл */

}

.car-class-item {
    flex: 1; /* Растягиваем элементы на всю ширину контейнера */
    min-width: 120px; /* Минимальная ширина элемента */
    text-align: center;
    padding: 10px;
    cursor: pointer;
    position: relative; /* Добавляем position: relative для позиционирования псевдоэлементов */
    transition: background-color 0.3s;
    white-space: nowrap; /* Предотвращаем перенос текста на новую строку */
    font-size: 20px;
    font-weight: 500;
}

.car-class-item::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    width: 1px;
    height: 20px;
    background-color: var(--primary-100);
    transform: translateY(-50%);
}

.car-class-item:last-child::after {
    display: none; /* Убираем разделитель у последнего элемента */
}

/* Подсвечивание выбранных классов */
/* Подсвечивание выбранных классов */
.car-class-item-child {
    background-color: transparent;
    border-radius: 30px;
    padding: 5px 15px; /* Добавляем отступ, чтобы фон не заполнял всю кнопку */
    transition: background-color 0.3s, border-radius 0.3s; /* Плавный переход */
}

/* Фон теперь применяется к дочернему элементу */
.car-class-item.selected .car-class-item-child {
    background-color: #007bff;
    color: white;
    border-radius: 20px;
}

/* При снятии выделения теперь переход будет плавным */
.car-class-item:not(.selected) .car-class-item-child {
    transition: background-color 0.3s, border-radius 0.3s;
}


/* При наведении */

/* Медиазапрос для мобильных устройств */
@media (max-width: 767px) {
    .car-class-item {
        flex: 0 0 auto; /* Изменяем flex-свойства для фиксированной ширины */
        min-width: 80px; /* Уменьшаем минимальную ширину для мобильных устройств */
        padding: 5px; /* Уменьшаем padding для мобильных устройств */
        font-size: 12px; /* Уменьшаем размер шрифта для мобильных устройств */
    }
}

/* Медиазапрос для ПК */
@media (min-width: 768px) {
    .car-class-container {
        display: flex;
        width: calc(100% + 20px); /* Увеличиваем ширину контейнера для скролла */
        padding: 10px;
        overflow-x: auto; /* Добавляем горизонтальный скролл */
    }

    .car-class-item {
        flex: 0 0 12.5%; /* Растягиваем элементы на 1/8 ширины контейнера */
        max-width: 12.5%; /* Ограничиваем максимальную ширину */
        min-width: 120px; /* Минимальная ширина элемента */
        box-sizing: border-box; /* Включаем padding и border в ширину элемента */
    }
}

@import url('../../Services/variables/variables.css');

.custom-marker {
    background-size: cover;
    width: 37px;
    height: 37px;
    border-radius: 50%;
    cursor: pointer;
}

.marker-label {
    position: absolute;
    bottom: -20px; /* Distance from marker to text */
    left: 50%;
    transform: translateX(-50%);
    font-size: var(--font-size-small); /* Font size variable */
    font-weight: bold;
    background-color: rgba(var(--neutral-color-1), 0.8); /* Slightly transparent background */
    padding: 2px 5px;
    border-radius: var(--border-radius-small); /* Border radius variable */
    margin-top: 10px;
    white-space: nowrap;
    pointer-events: none; /* Prevent clicks on the label */
}


.popup-content {
    text-align: center;
}

.popup-content h3 {
    margin: 0;
    font-size: var(--font-size-large); /* Font size variable */
    color: var(--text-color-primary); /* Text color variable */
}

.popup-content p {
    margin: 5px 0 0;
    font-size: var(--font-size-medium); /* Font size variable */
    /*color: var(--text-color-secondary); !* Text color variable *!*/
}

/*.popup-content {*/
/*    text-align: center;*/
/*   !* Ширина попапа *!*/
/*}*/

/*.popup-content h3 {*/
/*    margin: 0;*/
/*    font-size: 16px;*/
/*}*/

/*.popup-content p {*/
/*    margin: 5px 0 0;*/
/*    font-size: 14px;*/
/*}*/


.mapboxgl-popup-content img {
    width: 100%;
    height: 100%;
    /*max-width: 100%;*/
    /*max-height: 200px;*/
    object-fit: cover;
    border-radius: var(--border-radius-medium);
}

.mapboxgl-popup-tip {
    display: none; /* Remove the popup arrow */
}



.mapboxgl-popup-content {
    text-align: center;
    width: 150px;
    animation: popupOpen 0.3s forwards; /* Opening animation */
    background: var(--neutral-color-1) !important; /* Popup background */
    border-radius: var(--border-radius-medium) !important;
    box-shadow: var(--box-shadow-light) !important; /* Box shadow variable */
    padding: 0 !important;
}

.animated-popup .mapboxgl-popup-content {
    animation: popupOpen 0.3s forwards;
}

@keyframes popupOpen {
    from {
        transform: scale(0.5);
        opacity: 0;
    }
    to {
        transform: scale(1);
        opacity: 1;
    }
}

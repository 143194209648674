.message-input {
  display: flex;
  padding: 12px 20px;
  background: rgba(20, 30, 48, 0.9);
  border-top: 1px solid rgba(255, 255, 255, 0.08);
  gap: 10px;
  position: sticky;
  bottom: 0;
  z-index: 2;
  width: 100%;
  box-sizing: border-box;
}

.message-input input {
  flex-grow: 1;
  padding: 12px 15px;
  border: none;
  border-radius: 20px;
  background: rgba(255, 255, 255, 0.08);
  color: #fff;
  font-size: 14px;
  outline: none;
  transition: background 0.2s ease;
}

.message-input input:focus {
  background: rgba(255, 255, 255, 0.12);
}

.message-input button {
  background: #00b4d8;
  color: #fff;
  border: none;
  padding: 12px;
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mic-button {
  background: #00b4d8;
  color: #fff;
  border: none;
  padding: 12px;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

.emoji-button {
  background: transparent;
  color: #fff;
  border: none;
  padding: 12px;
  border-radius: 50%;
  cursor: pointer;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.emoji-picker {
  position: absolute;
  bottom: 60px;
  left: 20px;
  background: rgba(20, 30, 48, 0.95);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 10px;
  z-index: 1000;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 200px;
}

.emoji-picker .emoji {
  font-size: 24px;
  cursor: pointer;
}

/* Адаптивность для мобильных устройств */
@media (max-width: 768px) {
  .message-input {
    padding: 10px 15px; /* Уменьшаем отступы для мобильных */
    position: fixed; /* Используем fixed вместо sticky для стабильности */
    bottom: 60px; /* Учитываем высоту NavBar */
    left: 0;
    right: 0;
    background: rgba(20, 30, 48, 0.95); /* Увеличиваем непрозрачность для видимости */
    z-index: 100; /* Выше других элементов */
  }

  .message-input input {
    padding: 10px 12px; /* Уменьшаем внутренние отступы */
    font-size: 13px; /* Уменьшаем шрифт для компактности */
  }

  .message-input button,
  .mic-button,
  .emoji-button {
    width: 36px; /* Уменьшаем кнопки */
    height: 36px;
    padding: 10px;
  }

  .emoji-picker {
    bottom: 70px; /* Учитываем смещение из-за fixed инпута */
    left: 10px;
    max-width: 180px;
  }
}

@media (max-width: 480px) {
  .message-input {
    padding: 8px 10px;
  }

  .message-input input {
    padding: 8px 10px;
    font-size: 12px;
  }

  .message-input button,
  .mic-button,
  .emoji-button {
    width: 32px;
    height: 32px;
    padding: 8px;
  }
}

/* Поддержка тем */
body.night-mode .message-input {
  background: var(--glass-background, rgba(30, 42, 68, 0.95));
}
body.night-mode .message-input button {
  background: var(--gradient-primary, linear-gradient(135deg, #3b5bbf, #2b4a9f));
}
body.night-mode .message-input button:hover {
  background: var(--gradient-primary-hover, linear-gradient(135deg, #2b4a9f, #1e3a7f));
}

body.whiteLand .message-input {
  background: var(--glass-background, rgba(58, 42, 95, 0.95));
}
body.whiteLand .message-input button {
  background: var(--gradient-primary, linear-gradient(135deg, #5E4A9F, #4A3A7F));
}
body.whiteLand .message-input button:hover {
  background: var(--gradient-primary-hover, linear-gradient(135deg, #4A3A7F, #3A2A5F));
}

body.pureDark .message-input {
  background: var(--glass-background, rgba(0, 0, 0, 0.95));
}
body.pureDark .message-input button {
  background: var(--gradient-primary, linear-gradient(135deg, #1a73e8, #134db3));
}
body.pureDark .message-input button:hover {
  background: var(--gradient-primary-hover, linear-gradient(135deg, #134db3, #0d3a8c));
}
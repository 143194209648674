.LoginComponent{
    display: flex;
    justify-content: center;
    width: 100%;
}
.signup-login-container{
    background-color: var(--glass-background);
    display: flex;
    max-width: 800px;
    padding: 20px;
    border-radius: 20px;
}

.signup-login-image {
    width: 50%;
    overflow: hidden;
    aspect-ratio: 1 / 1.1;/* Обрезает контент, выходящий за пределы */
    display: flex; /* Для корректного позиционирования */
    justify-content: center; /* Центрирование изображения */
    align-items: center;
    margin-right: 20px;
    border-radius: 35px;

}

.signup-login-image img {
    width: 110%; /* Делаем изображение чуть больше контейнера */
    height: auto;
    max-width: none; /* Чтобы не ограничивать по max-width */

     /* Сдвигаем изображение влево */
}

.signup-login-title{
    margin-bottom: 15px;
    text-align: center;
    font-size: 22px;
}
.signup-login-details{
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 20px;
    align-items: center;
    overflow-y: auto;
}
.auth-submit-button{
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1rem; /* Относительные единицы для шрифта */
    background-color: var(--submit-button--background-secondary-color);
    color: var(--submit-button--background-secondary-color--hover);
    border: 1px solid black;
    margin-top:20px ;
    border-radius: var(--border-radius-medium);
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: var(--box-shadow-medium);
}

.signup-login-input{
    width: 100%;
    padding: 10px;
    font-size: 1rem;
}
.auth-page-phone-info {
    width: 100%;
    word-wrap: break-word;
    overflow-wrap: break-word;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 5px;
    padding-right: 5px;
}

.signup-login-details-container{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.signup-login-details-container > * {
    margin-top: 15px; /* Добавляет отступ снизу для каждого дочернего элемента */
}
.input-container-auth-sign{
    padding-left: 5px;
    padding-right: 5px;
}
.signup-login-details::-webkit-scrollbar {
    width: 5px; /* Ширина скроллбара */
}

.signup-login-details::-webkit-scrollbar-track {
    background: none; /* Цвет фона трека */
    border-radius: 4px;
}

.signup-login-details::-webkit-scrollbar-thumb {
    background: var(--primary-color); /* Цвет ползунка */
    border-radius: 4px;
}
.input-name{
    margin-top: 0!important;
}
.password-container {
    position: relative;
    width: 100%;
}

.password-container input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    padding-right: 40px; /* Оставляем место под кнопку */
    box-sizing: border-box; /* Исключаем padding в расчет ширины */
}
.toggle-password {
    position: absolute;
    right: 10px;
    top: 55%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    color: var(--text-color-fourth);

}


@media (max-width: 700px) {

.signup-login-container{
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    max-width: 380px;


}
.signup-login-image{
    margin-right: 0;
    margin-bottom: 15px;
    aspect-ratio: unset;
    width: 100%;

}
.signup-login-image img{
    max-height: 100px;
    width: 100%;
    border-radius: 35px;
    object-fit: cover;
}
.signup-login-details{
    margin-left: 0;
    width: 100%;

}

    .signup-login-title{
        font-size: 20px;
    }
}
@media (max-width: 600px) {
.LoginComponent{
    display: grid;
}
.signup-login-details-container p{
   font-size: 16px;

}

.google-login-btn{
    font-size: 3.5vw;
}
}
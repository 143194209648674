@import url('../../../../Services/variables/variables.css');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap');



body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, sans-serif;
}

/* Container */
.cdp-container {
  border-radius: 12px;
  padding: 20px;
  max-width: 400px;
  margin: 20px auto;
}

/* Date Range Display */
.cdp-date-range {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 20px;
}

.cdp-date-box {
  flex: 1;
  text-align: center;
  padding: 10px;
  border-radius: 8px;
}

.cdp-date-box label {
  display: block;
  font-size: 0.9rem;
  color: var(--text-color-primary);
  margin-bottom: 5px;
}

.cdp-date-box span {
  font-size: 1.1rem;
  color: var(--text-color-primary);
  font-weight: 500;
}

/* Calendar */
.cdp-calendar {
  border-radius: 8px;
  padding: 15px;
  background-color: var(--gradient-background);
}

.cdp-calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.cdp-nav-btn {
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #007bff;
  cursor: pointer;
  padding: 5px 10px;
  transition: color 0.2s ease;
}



.cdp-calendar-header span {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--text-color-primary);
}

.cdp-selection-hint {
  text-align: center;
  font-size: 0.9rem;
  color: #007bff;
  margin-bottom: 10px;
}

.cdp-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 4px;
}

.cdp-day-header {
  text-align: center;
  font-size: 0.9rem;
  color: #666;
  font-weight: 600;
  padding: 8px 0;
}

.cdp-day {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  color: var(--text-color-fourth);
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.2s ease, color 0.2s ease;
}



.cdp-day.disabled {
  color: #8e7979;
  cursor: not-allowed;
}

.cdp-day.selected-start,
.cdp-day.selected-end {
  background-color: #007bff;
  color: #fff;
  font-weight: 600;
}

.cdp-day.in-range {
  background-color: #cce5ff;
}

.cdp-clear-btn {
  display: block;
  margin: 15px auto 0;
  padding: 8px 20px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s ease;
}



/* Pricing */
.cdp-pricing {
  text-align: center;
  padding: 15px 0;
  }

.cdp-price {
  font-size: 1.1rem;
  color: var(--text-color-primary);
  margin: 5px 0;
}

.cdp-price.total {
  font-size: 1.3rem;
  font-weight: 600;
  color: #007bff;
}

/* Actions */
.cdp-actions {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.cdp-book-btn,
.cdp-chat-btn {
  flex: 1;
  padding: 12px;
  font-size: 1rem;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  color: #fff;
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.cdp-book-btn {
  background-color: #007bff;
}



.cdp-chat-btn {
  background-color: #17a2b8;
}


/* Modal */
.cdp-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.cdp-modal {
  position: relative;
  padding: 20px;
  border-radius: 12px;
  max-width: 90%;
  width: 500px;
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.cdp-modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 32px;
  height: 32px;
  border: none;
  border-radius: 50%;
  font-size: 20px;
  color: #333;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.2s ease;
}



.cdp-form-closed {
  text-align: center;
  font-size: 1.1rem;
  color: #666;
  padding: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .cdp-container {
    max-width: 100%;
    width: 358px;
    padding: 5px;
  }

  .cdp-date-range {
    flex-direction: row;
    gap: 15px;
  }

  .cdp-day {
    width: 35px;
    height: 35px;
    font-size: 0.9rem;
  }

  .cdp-actions {
    flex-direction: column;
    gap: 10px;
  }

  .cdp-book-btn,
  .cdp-chat-btn {
    width: 100%;
  }

  .cdp-modal {
    width: 95%;
    padding: 15px;
  }
}



.interactive-map-widget {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}




.tabs {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin-bottom: 10px;
}

.tab {
    flex: 1;
    padding: 10px 10px;
    background-color: #f3f3f3;
    color: #2B66FE;
    font-weight: bold;
    text-align: center;
    border: none;
    border-radius: 15px 4px 15px 4px;
    cursor: pointer;

    transition: background-color 0.3s ease;
    box-shadow: -1px 2px 12px 0 var(--primary--shadow-color);

}
.tab.hover {
    transform: scale(2%);
}

.tab.active {
    background-color: #2B66FE;
    color: rgba(255, 255, 255, 1);
}

.tab-content {
    width: 100%;
    height: 100%;
    max-height: 600px;
    min-height: 200px;

    background-color: white;
    border-radius: 0 10px 10px 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}
.content {
    height: auto;
    display: flex;
    justify-content: space-around;
    flex-wrap: nowrap;
    align-content: center;
    flex-direction: row-reverse;
    align-items: center;

}
.content h6 {
   font-size: 0.6em;
    padding: 10px;


}
.content h3 {
    font-size: 0.9em;
    color: #2B66FE;
    padding-left: 10px;
    padding-right: 10px;

}
.img-container1 {
    width: 100%;
}
.text-content-container {
    width: 100%;
    margin-right: 10px;
}

.map-image {
    width: 90%;
    height: auto;
    box-shadow: 0 -1px 20px 0 var(--primary--shadow-color);
    object-fit: cover;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px;
}
.map-image1 {
    width: 300px;
    height: 300px;
    margin: 10px;



}


@media (max-width: 2100px) {
    .tab-content {

    }
    .content {
        height: 310px;
    }



}
@media (max-width: 1024px) {
    .map-image {

    }

}

@media (max-width: 930px) {
    .tab-content {


    }
    .content {
        height: auto;
        display: flex;
        justify-content: space-around;
        flex-wrap: nowrap;
        align-content: center;
        flex-direction: row-reverse;
        align-items: center;

    }

    .map-image {

        margin: 10px;

    }
    .map-image1 {
        width: 250px;
        height: 250px;
        margin: 10px;



    }

}
@media (max-width: 600px) {
    .tab-content {
        .map-image {
            width: 90%;
            margin: 10px;
            height: 90%;

        }

        .content {
            height: auto;
            display: flex;
            justify-content: space-around;
            flex-wrap: nowrap;
            align-content: center;
            flex-direction: column-reverse;
            align-items: center;

        }
        .map-image1 {
            width: 200px;
            height: 200px;
            margin: 10px;



        }



    }
}

@media (max-width: 432px) {
    .tab-content {
        .map-image {
            width: 90%;
            height: auto;
            box-shadow: 0 -1px 20px 0 var(--primary--shadow-color);
            object-fit: cover;
            border-radius: 10px;
            overflow: hidden;
            margin: 10px;
        }

    }
}


@media (max-width: 360px) {
    .tab-content {
        .map-image {
            width: 86%;
            height: auto;
            box-shadow: 0 -1px 20px 0 var(--primary--shadow-color);
            object-fit: cover;
            border-radius: 10px;
            overflow: hidden;
        }

    }
}
.MapWrapper {
    position: relative;
}

.ToggleMapButton {
    margin-bottom: 10px;
}

.MapContainer {
    position: fixed; /* Позиционирование относительно окна браузера */
    top: 0;
    left: 0;
    width: 100vw; /* Используем единицы vw и vh для мобильных устройств */
    height: 100vh;
    background: white; /* Фон карты */
    z-index: 9999; /* Высокий z-index чтобы карта была поверх всех элементов */
    display: flex;
    justify-content: center;
    align-items: center;
}

.AddMap {
    width: 100%;
    height: 100%;
}

.CloseMapButton,
.SaveLocationButton {
    position: absolute; /* Изменяем на fixed для правильного отображения на мобильных */
    z-index: 10;
    background: #fff;
    border: none;
    padding: 10px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    font-size: 16px;
}

.CloseMapButton {
    top: 20px;
    right: 10px;
    width: 40px!important;

}

.SaveLocationButton {
    bottom: 10px;
    right: 10px;

}

@media (max-width: 768px) {
    .CloseMapButton,
    .SaveLocationButton {
        padding: 8px; /* Уменьшаем padding на мобильных */
        font-size: 14px; /* Уменьшаем размер текста */
    }
}

@import url('../../../Services/variables/variables.css');

/* App Container */
.cdc-app-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  background: var(--background-gradient); /* Заменяем градиент на переменную, можно добавить --background-gradient: linear-gradient(135deg, #f5f7fa, #c3cfe2) */
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(10px);
  font-family: var(--font-family-primary); /* Используем переменную шрифта */
}

/* Header */
.cdc-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: var(--glass-background); /* Заменяем градиент на переменную, можно добавить --glass-background: linear-gradient(135deg, rgba(255, 255, 255, 0.95), rgba(240, 240, 245, 0.9)) */
  border-radius: 0 0 var(--border-radius-xxxl) var(--border-radius-xxxl); /* Заменяем 25px на переменную */
  box-shadow: var(--box-shadow-light); /* Заменяем rgba(0, 0, 0, 0.1) на переменную */
  backdrop-filter: blur(10px);
  position: sticky;
  top: 0;
  z-index: 10;
}


.cdc-title {
  font-size: var(--font-size-xxl); /* Заменяем 1.5rem (24px) на переменную */
  font-weight: 600;
  color: var(--text-color-primary); /* Уже использует переменную (#333 в светлой, #ddddd6 в темной) */
  flex-grow: 1;
  margin-top: 45px;
  text-align: center;
  text-shadow: var(--text-shadow-light); /* Заменяем rgba(0, 0, 0, 0.05) на переменную, можно добавить --text-shadow-light: 0 2px 5px rgba(0, 0, 0, 0.05) */
}

/* Main Content */
.cdc-main {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  margin-bottom: 50px;
  background: transparent;
}

/* Gallery */
.cdc-gallery-container {
  width: 100%;
  margin: 0 auto 20px;
  border-radius: var(--border-radius-xl); /* Заменяем 15px на переменную */
  backdrop-filter: blur(5px);
}

.cdc-main-image-container {
  width: 100%;
  height: 50vh;
  max-height: 400px;
  border-radius: var(--border-radius-large); /* Заменяем 12px на переменную */
  overflow: hidden;
  position: relative;
  user-select: none;
  background-color: var(--neutral-color-5); /* Заменяем #f0f0f0 на переменную */
}

.cdc-main-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  transition: transform 0.3s ease;
}



.cdc-thumbnail-carousel {
  display: flex;
  gap: 8px;
  overflow-x: auto;
  padding: 10px 0;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: var(--primary-color) transparent; /* Используем переменную вместо #2B66FE */
}

.cdc-thumbnail-carousel::-webkit-scrollbar {
  height: 6px;
}

.cdc-thumbnail-carousel::-webkit-scrollbar-track {
  background: var(--neutral-color-2); /* Заменяем rgba(0, 0, 0, 0.05) на переменную, можно добавить --neutral-color-2: rgba(0, 0, 0, 0.05) */
  border-radius: var(--border-radius-tiny); /* Заменяем 3px на переменную */
}

.cdc-thumbnail-carousel::-webkit-scrollbar-thumb {
  background-color: var(--primary-color); /* Заменяем #007bff на переменную */
  border-radius: var(--border-radius-tiny); /* Заменяем 3px на переменную */
}

.cdc-thumbnail-item {
  flex: 0 0 80px;
  height: 60px;
  border-radius: var(--border-radius-large); /* Заменяем 8px на переменную */
  overflow: hidden;
  cursor: pointer;
  border: 2px solid transparent;
  transition: all 0.3s ease;
  box-shadow: var(--box-shadow-light); /* Заменяем rgba(0, 0, 0, 0.1) на переменную */
  background: var(--glass-white); /* Заменяем rgba(255, 255, 255, 0.9), можно добавить --glass-white: rgba(255, 255, 255, 0.9) */
}

.cdc-thumbnail-item.active {
  border: 2px solid var(--primary-color); /* Заменяем #007bff на переменную */
  transform: scale(1.05);
  box-shadow: var(--box-shadow-primary); /* Заменяем rgba(0, 123, 255, 0.2), можно добавить --box-shadow-primary: 0 4px 15px rgba(0, 123, 255, 0.2) */
}

.cdc-thumbnail-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Details Section */
.cdc-details {
  width: 100%;
  margin: 0 auto;
  padding: 15px;
  background: var(--glass-background); /* Используем переменную для эффекта стекла */
  border-radius: var(--border-radius-xl); /* Заменяем 15px на переменную */
  backdrop-filter: blur(5px);
}

.cdc-details h2 {
  font-size: var(--font-size-xl-plus); /* Заменяем 1.4rem (~22px) на переменную, можно добавить --font-size-xl-plus: 22px */
  font-weight: 600;
  color: var(--text-color-primary); /* Уже использует переменную */
  margin-bottom: 15px;
  text-shadow: var(--text-shadow-light); /* Используем переменную */
}

/* Info Panels */
.cdc-info-p,
.cdc-info-p2 {
  padding: 15px;
  border-radius: var(--border-radius-large); /* Заменяем 12px на переменную */
  margin-bottom: 20px;
}

.cdc-info-p p,
.cdc-info-p2 p {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--font-size-medium-plus); /* Заменяем 1.1rem (~17px), можно добавить --font-size-medium-plus: 17px */
  padding: 8px 0;
  border-bottom: 1px solid var(--neutral-color-3); /* Заменяем rgba(0, 0, 0, 0.1), можно добавить --neutral-color-3: rgba(0, 0, 0, 0.1) */
  color: var(--text-color-fourth); /* Уже использует переменную (#555 в светлой, адаптируется в темной) */
}

.cdc-info-p p:last-child,
.cdc-info-p2 p:last-child {
  border-bottom: none;
}

.cdc-info-p p strong,
.cdc-info-p2 p strong {
  font-weight: 600;
  color: var(--text-color-primary); /* Уже использует переменную */
}

/* Vehicle Description */
.vehicle-description {
  padding: 15px;
  border-radius: var(--border-radius-large); /* Заменяем 12px на переменную */
  margin-bottom: 20px;
}

.vehicle-description p {
  font-size: var(--font-size-medium-plus); /* Заменяем 1.1rem (~17px) */
  color: var(--text-color-fourth); /* Заменяем #666 на переменную */
  line-height: 1.5;
}

/* Booking Section */
.cdc-booking-section {
  padding: 15px;
  text-align: center;
  border-radius: var(--border-radius-large); /* Заменяем 12px на переменную */
  margin-bottom: 20px;
  backdrop-filter: blur(5px);
}

/* Share Buttons */
.cdc-share-buttons {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  padding: 15px;
}

.cdc-share-btn {
  flex: 1;
  min-width: 80px;
  padding: 12px;
  border: none;
  border-radius: var(--border-radius-xl); /* Заменяем 15px на переменную */
  color: var(--text-color-fifth); /* Уже использует переменную (#fff) */
  font-size: var(--font-size-medium); /* Заменяем 14px на переменную */
  font-weight: 500;
  cursor: pointer;
  box-shadow: var(--box-shadow-light); /* Уже использует переменную */
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}



/* Цвета кнопок шаринга */
.cdc-share-native { background: var(--gradient-green); } /* Можно добавить --gradient-green: linear-gradient(135deg, #4CAF50, #388E3C) */
.cdc-share-twitter { background: var(--gradient-twitter); } /* Можно добавить --gradient-twitter: linear-gradient(135deg, #1DA1F2, #0d8ddb) */
.cdc-share-facebook { background: var(--gradient-facebook); } /* Можно добавить --gradient-facebook: linear-gradient(135deg, #4267B2, #2a4887) */
.cdc-share-whatsapp { background: var(--gradient-whatsapp); } /* Можно добавить --gradient-whatsapp: linear-gradient(135deg, #25D366, #1da851) */
.cdc-share-telegram { background: var(--gradient-telegram); } /* Можно добавить --gradient-telegram: linear-gradient(135deg, #0088cc, #006699) */

/* Fullscreen Overlay */
.cdc-fullscreen-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--overlay-color); /* Заменяем rgba(0, 0, 0, 0.5), можно добавить --overlay-color: rgba(0, 0, 0, 0.5) */
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
  isolation: isolate;
}

.cdc-fullscreen-img {
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;
  border-radius: var(--border-radius-large); /* Заменяем 12px на переменную */
  box-shadow: var(--box-shadow-dark); /* Заменяем rgba(0, 0, 0, 0.2), можно добавить --box-shadow-dark: 0 10px 30px rgba(0, 0, 0, 0.2) */
}

/* Fullscreen Close Button */
.cdc-fullscreen-close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 36px;
  height: 36px;
  background: var(--gradient-red); /* Заменяем градиент, можно добавить --gradient-red: linear-gradient(135deg, #ff6b6b, #ff4040) */
  border: none;
  border-radius: 50%;
  font-size: var(--font-size-xl); /* Заменяем 20px на переменную */
  color: var(--text-color-fifth); /* Уже использует переменную (#fff) */
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}



/* Fullscreen Arrows */
.cdc-fullscreen-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background: var(--glass-white); /* Используем переменную для rgba(255, 255, 255, 0.9) */
  border: none;
  border-radius: 50%;
  font-size: var(--font-size-xxl); /* Заменяем 24px на переменную */
  color: var(--text-color-primary); /* Уже использует переменную */
  cursor: pointer;
  transition: all 0.3s ease;
}


.cdc-fullscreen-arrow-left { left: 15px; }
.cdc-fullscreen-arrow-right { right: 15px; }

/* Loading and Not Found */
.cdc-loading,
.cdc-not-found {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: var(--font-size-xxl); /* Заменяем 1.5rem (24px) на переменную */
  color: var(--text-color-fourth); /* Уже использует переменную (#666 в светлой, адаптируется в темной) */
  background: var(--glass-background); /* Используем переменную */
  border-radius: var(--border-radius-xl); /* Заменяем 15px на переменную */
  box-shadow: var(--box-shadow-light); /* Уже использует переменную */
  backdrop-filter: blur(5px);
}

/*!* Shine Effect *!*/
/*.cdc-share-btn::before,*/
/*.cdc-fullscreen-close-btn::before {*/
/*  content: '';*/
/*  position: absolute;*/
/*  top: 0;*/
/*  left: -100%;*/
/*  width: 50%;*/
/*  height: 100%;*/
/*  background: var(--shine-effect); !* Можно добавить --shine-effect: linear-gradient(120deg, transparent, rgba(255, 255, 255, 0.3), transparent) *!*/
/*  animation: shine 2s infinite;*/
/*}*/

@keyframes shine {
  0% { left: -100%; }
  20% { left: 100%; }
  100% { left: 100%; }
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .cdc-header {
    padding: 15px;
  }

  .cdc-title {
    font-size: var(--font-size-large-plus); /* Заменяем 1.2rem (~19px), можно добавить --font-size-large-plus: 19px */
    margin-top: 30px;
  }



  .cdc-main-image-container {
    height: 40vh;
    max-height: 300px;
  }

  .cdc-thumbnail-item {
    flex: 0 0 60px;
    height: 45px;
  }

  .cdc-details h2 {
    font-size: var(--font-size-large-plus); /* Заменяем 1.2rem (~19px) */
  }

  .cdc-info-p p,
  .cdc-info-p2 p {
    font-size: var(--font-size-medium); /* Заменяем 1rem (16px) на переменную */
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }

  .cdc-share-btn {
    padding: 10px;
    font-size: var(--font-size-small); /* Заменяем 12px на переменную */
  }
}

@media (min-width: 600px) {
  .cdc-main {
    padding: 25px;
  }

  .cdc-gallery-container {
    max-width: 800px;
  }

  .cdc-details {
    max-width: 800px;
  }
}
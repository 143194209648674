.disFlex{
    display: flex;
}
/* AddFlats.css */

.addFlatsContainer {
    max-width: 800px;
    margin:0 auto;
    /*padding: 22px;*/
    justify-content: center;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.addFlatsContainer h2 {
    text-align: center;
    margin-bottom: 20px;
    color: var(--text-color-primary);
}
.addFlatsContainer input {
    width: 100%;
    padding: 12px 15px;
    margin: 10px 0;
    border: 2px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    font-family: 'Arial', sans-serif;
    transition: all 0.3s ease;
    color: var(--text-color-primary);
}

.addFlatsContainer label {
    display: flex;
    align-items: center;
    margin-right: 7px;
    font-size: 0.9rem;
    color: var(--text-color-primary);
    white-space: nowrap;
}

.name-add-text {
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 500;

}
.required {
    color: red;

}
.input-container-checkbox {
    display: flex;
    flex-direction: row;
    width: 200px;
    margin-bottom: 10px;
    margin-top: 10px;
    gap: 20px;

}


.input-container {
    margin-bottom: 20px;
}

input[type="text"], input[type="textarea"], select {
    width: 100%;
    padding: 10px;

    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: var(--background-color);
    color: var(--text-color-primary);


}
.input-container-checkbox {
    display: flex; /* Используем flex для выравнивания по горизонтали */
    align-items: center; /* Выравниваем по вертикали */
}

.input-container-checkbox p {
    margin-right: 10px; /* Отступ между текстом и чекбоксом */
    white-space: nowrap; /* Запрещаем перенос текста на новую строку */
}

/*input[type="checkbox"] {*/
/*    margin-right: 10px;*/
/*}*/
/*.addFlatsContainer input[type="checkbox"] {*/
/*    width: auto;*/
/*    margin-right: 5px;*/
/*    vertical-align: middle;*/
/*}*/
/*.disFlex {*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*}*/

/*label {*/
/*    font-weight: 500;*/
/*    margin-bottom: 5px;*/
/*}*/

input[type="checkbox"], select {
    width: 100%;
}


.addFlatsContainer * {
    box-sizing: border-box;
    color: var(--text-color-primary);
}

.addFlatsContainer h2 {
    text-align: center;
    margin-bottom: 20px;
        color: var(--text-color-primary);

}
.centring{
    justify-content: center;
}

.addFlatsContainer input:focus,
.addFlatsContainer select:focus,
.addFlatsContainer button:focus {
    padding: 10px;
    margin: 5px 0;
    background-color: var(--background-highlight-color);
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
        border-color: #007BFF;  /* Цвет рамки при фокусе */
    outline: none;          /* Убирает обводку браузера */
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5); /* Тень при фокусе */

}
.addFlatsContainer input,
.addFlatsContainer select,
.addFlatsContainer button {
    padding: 10px;
    margin: 5px 0;
    border: 1px solid #ccc;
    background-color: var(--background-highlight-color);
    border-radius: 5px;
    font-size: 1rem;
    width: 100%;
}

.addFlatsContainer input[type="checkbox"] {
    width: auto;
    margin-right: 5px;
}

.addFlatsContainer label {
    display: flex;
    align-items: center;
    margin-right: 15px;
    font-size: 0.9rem;
    color: var(--text-color-primary);
}

.addFlatsContainer button {
    background-color: #007BFF;

    color: #fff;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
    border: none;
    width: 100%;
}

.addFlatsContainer button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.addFlatsContainer button:hover:enabled {
    background-color: #0056b3;
}

.addFlatsContainer .disFlex {
    display: flex;
    gap: 15px;

    flex-direction: column;
}

.addCarsContainer .disFlex input,
.addCarsContainer .disFlex select {

}
.notification {
    margin-top: 10px;
    padding: 10px;
    background-color: #e0ffe0;
    border: 1px solid #a0d6a0;
    color: #2a702a;
    border-radius: 5px;
}
.checkbox-group {
    display: flex;
    flex-wrap: wrap; /* Позволяет переносить элементы, если не хватает места */
    gap: 10px; /* Отступы между элементами */
}

.checkbox-group div {
    display: flex;
    align-items: center; /* Выравнивание текста и чекбоксов по центру */
}

.checkbox-group label {
    margin-left: 5px; /* Отступ между чекбоксом и текстом */
}
.block-container {
    border: 1px solid #ccc; /* Рамка вокруг блока */
    border-radius: 8px;     /* Закругление углов */
    padding: 16px;          /* Внутренние отступы */
    margin-bottom: 16px;    /* Отступ снизу */
    background-color: var(--background-highlight-color); /* Светлый фон */
}

.block-container label {
    display: block;         /* Отображение метки с новой строки */
    margin-bottom: 8px;     /* Отступ снизу */
    font-weight: bold;      /* Жирный текст */
    color: var(--text-color-primary);            /* Цвет текста */
}

.block-container input,
.block-container select {
    width: 100%;            /* Полная ширина элемента */
    padding: 8px;           /* Внутренние отступы */
    margin-bottom: 16px;    /* Отступ снизу */
    border: 1px solid #ddd; /* Рамка вокруг поля */
    border-radius: 4px;     /* Закругление углов */
    font-size: 16px;        /* Размер текста */
}

.block-container input:focus,
.block-container select:focus {
    border-color: #007BFF;  /* Цвет рамки при фокусе */
    outline: none;          /* Убирает обводку браузера */
    box-shadow: 0 0 4px rgba(0, 123, 255, 0.5); /* Тень при фокусе */
}


@media (max-width: 768px) {
    .addFlatsContainer .disFlex {
        flex-direction: column;
    }

    .addFlatsContainer {
    max-width: 600px;
}

}

.addFlatsContainer .disFlex label {
    font-size: 0.9rem;
    color: var(--text-color-primary);
}
@media (max-width: 600px) {
    .addFlatsContainer {
    max-width: 600px;
}
}




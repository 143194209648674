.datepicker-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}


.datepicker {
    width: 100%; /* Календарь внутри контейнера тоже занимает 100% */
}
.days-count {
    margin-top: 10px;
    font-size: 16px;
    font-weight: bold;
}
.datapickerFlats-Button-block{
    padding-left: 5px;
    padding-right: 5px;
}
.rdrDefinedRangesWrapper {
    display: none;
}

.rdrDateDisplayWrapper {
    display: none;
}

/* Увеличение размера календаря */
.rdrCalendarWrapper {
    font-size: 14px; /* Увеличение размера шрифта для календаря */
}
@media (max-width: 500px) {
    .rdrCalendarWrapper {
        font-size: 12px; /* Увеличение размера шрифта для календаря */
    }

}
@media (max-width: 330px) {
    .rdrCalendarWrapper {
        font-size: 10px; /* Увеличение размера шрифта для календаря */
    }
    .rdrDay {

        font-weight: bold; /* Жирный шрифт */
    }

}
/* Стили для серых дат */
.datepicker-container .rdrDay--disabled {
    color: #d3d3d3;

}

.datepicker-container .rdrDay--disabled:hover {
    cursor: not-allowed;
}

/* Увеличение размера шрифта для доступных дат и выделение жирным */
.rdrDay {
    font-size: 18px; /* Увеличение размера шрифта для доступных дат */
    font-weight: bold; /* Жирный шрифт */

}

.rdrMonth {
    padding: 0;
}
.total-price{
    justify-content: space-between;
    display: flex;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
}


.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;

}
/* Изменение фона календаря */
/* Фон для всего календаря */
.rdrCalendarWrapper {
    background-color: var(--background-highlight-color); /* Общий фон для календаря */
}

/* Фон для всех месяцев */
.rdrMonths {
    background-color: var(--background-highlight-color);
}

/* Фон для каждого отдельного месяца */
.rdrMonth {
    background-color: var(--background-highlight-color);
}

/* Фон для названий дней недели (например, Mon, Tue) */
.rdrWeekDay {
    background-color: var(--background-highlight-color); /* Меняем фон для дней недели */
    color: var(--text-color-primary);
}

/* Фон для всех ячеек с датами */
.rdrDay {
    background-color: var(--background-highlight-color); /* Задаем нужный фон для всех дат */
}
.rdrDays{

}
.rdrDayNumber span{
    color:  var(--text-color-primary);
}
/* Фон для выбранных дат */

/* Изменение стиля для выделенной даты при наведении */

.l_btnFlat{
    width: 66%;
    font-weight: 1000;
    font-size: 20px !important;
    border-radius: 12px !important;
}
.r_btn{
    width: 33%;
    font-weight: 1000;
    font-size: 20px !important;
    border-radius: 12px !important;
}
/* Изменение фона для заголовков месяцев (например, October 2024) */
.rdrMonthAndYearWrapper {
    background-color: var(--background-highlight-color); /* Фон заголовка месяца */
    color: var(--text-color-primary);
}

/* Фон для кнопок переключения месяцев (стрелки влево/вправо) */
.rdrNextPrevButton {
    background-color: var(--background-highlight-color); /* Меняем фон кнопок переключения */
    border: none; /* Убираем рамку */
    color: white; /* Цвет стрелок */
}

/* Стили для серых недоступных дат */
.rdrDayDisabled {

    color: #8c8c8c; /* Цвет текста для недоступных дат */
}



.datepicker-modal-content {
    /*background-color: var(--background-highlight-color);*/
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    width: 100%;
    text-align: center;
    position: relative;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
}

.modal-input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.modal-checkboxes {
    display: flex;
    justify-content: space-around;
    margin: 20px 0;
}

.modal-submit {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 6px;
    cursor: pointer;
}


/*.modal-submit:hover {*/
/*    background-color: #0056b3;*/
/*}*/
.BookMsgBtn{
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
    gap:5px;
}
.BookMsgBtn Button{
    margin: 0;
}
.user-profile-wall {
  padding: 10px;
}

.user-profile-wall h3 {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin: 0 0 10px;
}

.user-profile-container.space .user-profile-wall h3,
.user-profile-container.future .user-profile-wall h3 {
  color: #ffffff;
}

.post-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.post-form textarea {
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  min-height: 60px;
  background: #f5f5f5;
  color: #000;
  transition: transform 0.2s ease;
}

.post-form textarea:focus {
  transform: scale(1.02);
}

.user-profile-container.space .post-form textarea,
.user-profile-container.future .post-form textarea {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #ffffff;
}

.user-profile-container.space .post-form textarea::placeholder,
.user-profile-container.future .post-form textarea::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.post-form button {
  background: #007aff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  transition: transform 0.2s ease, background 0.2s ease;
}

.post-form button:active {
  transform: scale(0.95);
}

.user-profile-container.space .post-form button,
.user-profile-container.future .post-form button {
  background: linear-gradient(45deg, #8a2be2, #00b7ff);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.post {
  padding: 10px;
  border-bottom: 1px solid #e5e5ea;
}

.user-profile-container.space .post,
.user-profile-container.future .post {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.post p {
  font-size: 14px;
  color: #000;
  margin: 0 0 5px;
}

.user-profile-container.space .post p,
.user-profile-container.future .post p {
  color: #ffffff;
}

.post span {
  font-size: 12px;
  color: #666;
}

.user-profile-container.space .post span,
.user-profile-container.future .post span {
  color: rgba(255, 255, 255, 0.7);
}

.post-actions {
  display: flex;
  gap: 10px;
  margin: 5px 0;
}

.post-actions button {
  background: none;
  border: none;
  color: #666;
  font-size: 14px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.post-actions button:active {
  transform: scale(0.95);
}

.post-actions button.liked {
  color: #ff2d55;
}

.comments {
  margin-top: 5px;
}

.comment {
  padding: 5px 0;
}

.comment p {
  font-size: 13px;
  margin: 0 0 2px;
  color: #000;
}

.user-profile-container.space .comment p,
.user-profile-container.future .comment p {
  color: #ffffff;
}

.comment span {
  font-size: 11px;
  color: #999;
}

.user-profile-container.space .comment span,
.user-profile-container.future .comment span {
  color: rgba(255, 255, 255, 0.7);
}

.comment-form {
  display: flex;
  gap: 5px;
  margin-top: 5px;
}

.comment-form input {
  flex: 1;
  padding: 8px;
  border: none;
  border-radius: 10px;
  font-size: 13px;
  background: #f5f5f5;
  color: #000;
  transition: transform 0.2s ease;
}

.comment-form input:focus {
  transform: scale(1.02);
}

.user-profile-container.space .comment-form input,
.user-profile-container.future .comment-form input {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #ffffff;
}

.comment-form button {
  background: #007aff;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 10px;
  font-size: 13px;
  cursor: pointer;
  transition: transform 0.2s ease, background 0.2s ease;
}

.comment-form button:active {
  transform: scale(0.95);
}

.user-profile-container.space .comment-form button,
.user-profile-container.future .comment-form button {
  background: linear-gradient(45deg, #8a2be2, #00b7ff);
}

.wall-comments-section {
  margin-top: 15px;
}

.wall-comments-section h3 {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin: 0 0 10px;
}

.user-profile-container.space .wall-comments-section h3,
.user-profile-container.future .wall-comments-section h3 {
  color: #ffffff;
}

.wall-comment-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.wall-comment-form textarea {
  padding: 10px;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  min-height: 60px;
  background: #f5f5f5;
  color: #000;
  transition: transform 0.2s ease;
}

.wall-comment-form textarea:focus {
  transform: scale(1.02);
}

.user-profile-container.space .wall-comment-form textarea,
.user-profile-container.future .wall-comment-form textarea {
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  color: #ffffff;
}

.user-profile-container.space .wall-comment-form textarea::placeholder,
.user-profile-container.future .wall-comment-form textarea::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.wall-comment-form button {
  background: #007aff;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  transition: transform 0.2s ease, background 0.2s ease;
}

.wall-comment-form button:active {
  transform: scale(0.95);
}

.user-profile-container.space .wall-comment-form button,
.user-profile-container.future .wall-comment-form button {
  background: linear-gradient(45deg, #8a2be2, #00b7ff);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.wall-comment {
  padding: 10px;
  border-bottom: 1px solid #e5e5ea;
}

.user-profile-container.space .wall-comment,
.user-profile-container.future .wall-comment {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.wall-comment p {
  font-size: 14px;
  color: #000;
  margin: 0 0 5px;
}

.user-profile-container.space .wall-comment p,
.user-profile-container.future .wall-comment p {
  color: #ffffff;
}

.wall-comment span {
  font-size: 12px;
  color: #666;
}

.user-profile-container.space .wall-comment span,
.user-profile-container.future .wall-comment span {
  color: rgba(255, 255, 255, 0.7);
}
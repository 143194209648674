@import url('../../../Services/variables/variables.css');
.centring{
    width: 100%;
    display: flex;
    justify-content: center;
}
.login-form-container {
    padding:20px;
    display: grid;
    align-items: center;
    background-color: var(--neutral-color-1);
    border-radius: var(--border-radius-large);
    border-radius: var(--border-radius-large);

    border-top-right-radius: 0;
    border-top-left-radius: 0;

}
.login-form-container input,
.login-form-container textarea,
.login-form-container select {
    font-size: 16px;
}
.login-form-container input,
.login-form-container textarea,
.login-form-container select {
    font-size: 16px;
}


.image-form{
    display:flex;
    flex-direction: row;
    margin-bottom: 80px;
}

.login-form-image {

    max-width: 345px; /* Максимальная ширина для больших экранов */
    height: 400px; /* Высота будет изменяться пропорционально */
    border-radius: 18px;

    box-shadow: var(--primary-box-shadow);
}

.login-image-container {
    display: block;
    margin: 10px auto;
    border-radius: 1px; /* Закругленные углы */
    transition: filter 0.3s ease, opacity 0.3s ease;
    opacity: 1;
}

.login-image-container:hover {
    filter: brightness(1.8);
    transform: scale(1.09);
    opacity: 2;
    cursor: pointer;

}


.login-form-content {
     flex: 1;
    display: grid;
    margin-left: 30px;
    margin-right: 30px;

}

.login-form-header {
      display: flex;
        width: 100%;
       justify-content: center;
       margin-left: 26px;
       margin-bottom: 26px;
}
.login-form-header h2 {
    font-size: var(--font-size-large);
    margin-top: 0px;
    font-weight: 300;
    margin-right: 20px;
    color: var(--primary-100);
}


.login-form-body h1{
    font-size: 28px;
    padding-right: 3px;
    user-select: none;
}

.login-form-body {
    height: 36px;
    font-size: 18px;
    font-weight: 400;
    text-align: center;
    user-select: none;
}


.login-form-group {
    display: flex;
    flex-direction: column; /* Располагает элементы сверху вниз */
    justify-content: flex-start; /* Выравнивает элементы сверху */
    gap: 10px; /* Расстояние между элементами */
    margin-top: 85px;
    margin-bottom: 33px;
}

.login-prompt-text {
    margin-top: 10px;
    font-size: var(--font-size-medium);
    color: var(--text-color-primary);
    text-align: left;
}

.form-group label {
    display: block;
    font-size: var(--font-size-medium);

    color: var(--text-color-primary);
}

.form-group input.form-group input {
    width: 100%; /* Ширина поля ввода адаптивная */
    padding: 15px;
    border: 1px solid var(--neutral-color-6);
    border-radius: var(--border-radius-medium);
    background-color: var(--primary-form-group-input);
    color: var(--text-color-primary);
    box-sizing: border-box;
    box-shadow: var(--box-shadow-medium);
}

.form-group input:focus {
    border-color: rgba(100, 100, 100, 1); /* Цвет рамки при фокусе */
    box-shadow: 0px 8px 20px rgba(100, 100, 100, 0.9); /* Более яркая тень при фокусе */
}
.google-login-btn {
    align-items: center;
    background-color: #fff;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 10px #fff0, 0 0 10px #ffffff1a;
    display: flex;
    height: 35px;
}
.google-login-btn {
    background-color: var(--main-color-2) !important;
    box-shadow: var(--box-shadow-medium);
    border-style: none;
    bottom: 0;
    color: var(--text-color-primary);
    cursor: pointer;
    font-family: fontR, sans-serif;
    font-size: 15px;
    height: 42px;
    justify-content: center;
    letter-spacing: 1px;

    transition: .3s ease,background-color .3s ease, transform .3s ease;
    width: 100%;
    z-index: 1600;
}
/*.google-login-btn:hover {*/
/*    transform: scale(1.02); !* Легкое увеличение кнопки *!*/
/*}*/
.google-login-btn svg {
    border-radius: 10px;
    height: 50px;
    justify-content: flex-start;
    margin-right: 0;
    padding: 12px;
    width: 47px;
}
.cls-111 {
    fill: #4280ef;
}
.cls-112 {
    fill: #34a353;
}
.cls-113 {
    fill: #f6b704;
}
.cls-114 {
    fill: #e54335;
}

.submit-button.submit-button {
     /* Адаптивная ширина кнопки */
    font-size: 1rem; /* Относительные единицы для шрифта */
    background-color: var(--submit-button--background-secondary-color);
    color: var(--submit-button--background-secondary-color--hover);
    border: 1px solid black;
    border-radius: var(--border-radius-medium);
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: var(--box-shadow-medium);
}
.submit-button.submit-button:hover {
    background-color: var(--submit-button--background-secondary-color--hover); /* Чёрный фон при наведении */
    color: var(--text-color-primary) !important;
}



.form-group input:focus {
    outline: none;
    border-color: var(--primary-color); /* Focus border color */
    background-color: var(--neutral-color-1); /* Background on focus */
}
.form-group {
    position: relative;

}
.eye-icon {
    cursor: pointer;
    transition: filter 0.3s ease, transform 0.2s ease;
}

.eye-icon:hover {
    filter: brightness(1.8); /* Увеличивает яркость иконки */
}

.eye-icon-image {
    width: 20px; /* Установите нужный размер иконки */
    height: 20px;
}



.login-form-body{

}

@media (max-width: 880px) {
    .login-form-container {
        display: grid;
        padding-left: 10px;
        padding-right: 10px;
        grid-template-columns: 1fr; /* Структура для размещения содержимого */
        align-items: center; /* Центрируем элементы по горизонтали */
        justify-content: center; /* Центрируем элементы по вертикали */

        width: 100%;
        height: 100%;
        max-width: 600px; /* Ограничиваем максимальную ширину */
        overflow-x: hidden; /* Убираем горизонтальную прокрутку */
        overflow-y: auto; /* Добавляем вертикальную прокрутку, если необходимо */
    }

.auth-page-container {
    margin-top: 101px!important;
    width: 100%;
}
 input, textarea, select {
        font-size: 16px;
    }
    .image-form {
        display: grid;
        justify-content: center; /* Центрируем содержимое внутри */
        width: 100%;
        margin-bottom: 20px;
    }
  .login-form-body {
        display: flex;
        flex-direction: row; /* Размещаем текст и изображение в строку */
        align-items: center; /* Центрируем элементы по вертикали */
        justify-content: center; /* Выравниваем элементы слева */
        width: 100%; /* Задаем полную ширину */
      margin-bottom: 10px;
    }

    .login-form-body p {

        margin-top: 0px;
    }



    .login-form-header {
        max-width: 400px;
        display: flex;
        width: 100%;
       justify-content: center;
        margin-top: 20px;
        margin-bottom: 25px;
        margin-left: 0px;
    }


    .login-form-content {
        display: flex;
        flex-direction: column; /* Устанавливаем элементы в колонку */
        align-items: center; /* Центрируем элементы по горизонтали */
        width: 100%;
        margin-left: 0px;
        margin-right: 0px;
    }

    .login-form-content-image {
        width: 100%; /* Увеличиваем ширину изображения */
        margin-bottom: 14px;
        max-width: 390px; /* Ограничиваем максимальную ширину изображения */
        height: 200px;
        justify-content: center;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .login-form-group {
        display: flex;
        flex-direction: column; /* Устанавливаем элементы в колонку */
        width: 100%; /* Полная ширина для полей ввода */
        margin-bottom: 54px;
    }

    .submit-button.submit-button {
        width: 100%;
        padding: 5px; /* Увеличиваем внутренний отступ для кнопки */
        font-size: 1.5rem; /* Увеличиваем размер шрифта на 1.5 раза */
        margin-top: 20px; /* Добавляем отступ сверху */

    }
}

@media (max-width: 420px) {
    .login-form-container {
        display: grid;
        padding-left: 10px;
        padding-right: 10px;
        grid-template-columns: 1fr;
        align-items: center;
        justify-content: center;

        width: 100%;
        max-width: 380px;
        overflow-x: hidden;
        overflow-y: auto;
    }
 input, textarea, select {
        font-size: 16px;
    }
    .image-form {
        display: grid;
        justify-content: center;
        width: 100%;
         /* Ограничиваем максимальную ширину изображения */
    }

    .login-form-body {
        display: flex;
        flex-direction: column; /* Размещаем текст и изображение в строку */
        align-items: center; /* Центрируем элементы по вертикали */
        justify-content: center; /* Выравниваем элементы слева
        width: 100%; /* Задаем полную ширину */
    }

   .login-form-body h1{
       margin-left: 10px;
       margin-bottom: 0px;
   }

    .login-form-header {
        display: flex;
        width: 100%;
        justify-content: center;
        margin-top: 20px;
        margin-bottom: 25px;

    }

    .login-form-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        max-width: 390px; /* Ограничиваем максимальную ширину контента */
        margin-left: 0;
        margin-right: 0;

    }

    .login-form-content-image {
        /*width: 100%;*/
        margin-bottom: 20px;
        height: 200px; /* Подгоняем высоту автоматически */
        min-width: 360px;
        justify-content: center;
        text-align: center;
        margin-right: auto;
        margin-left: auto;
    }

    .login-form-group {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 0px;
        max-width: 328px; /* Ограничиваем максимальную ширину группы */
        margin-bottom: 10px;
    }

    .login-form-group input[type="password"],
    .login-form-group input[type="email"] {
        width: 100%;
        max-width: 320px; /* Ограничиваем максимальную ширину полей ввода */
        /*padding: 10px;*/
        font-size: 15px;
        box-sizing: border-box;
    }

    .submit-button.submit-button {
        width: 100%;
        max-width: 390px; /* Ограничиваем максимальную ширину кнопки */
        padding: 5px;
        font-size: 1.5rem;
        margin-top: 20px;

    }
}

/*@media (max-width: 420px) {*/
/*    .login-form-container {*/
/*        display: grid;*/
/*        padding-left: 10px;*/
/*        padding-right: 10px;*/
/*        grid-template-columns: 1fr;*/
/*        align-items: center;*/
/*        justify-content: center;*/

/*        width: 100%;*/
/*        max-width: 420px;*/
/*        overflow-x: hidden;*/
/*        overflow-y: auto;*/
/*    }*/
/*}*/

.mapboxgl-popup-content {
    width: auto;
}

.popup-content .car-card * {
    font-size: 12px;
}

.popup-content .car-card .datepicker .rdrMonthAndYearWrapper .rdrMonthPicker select,
.popup-content .car-card .datepicker .rdrMonthAndYearWrapper .rdrYearPicker select {
    padding: 10px;
}

.popup-content  .car-card .car-details .datepicker-container .disFlex.BookMsgBtn button {
    font-size: 12px !important;
}

.popup-content .car-card .car-details {
    padding: 15px 5px;
}

.popup-content .car-card .car-details .car-details {
    padding: 0;
}

.popup-content .car-card .car-details .disFlex.elementsIcons.IconGrid {
    padding: 0;
    margin-bottom: -8px;
}

.popup-content .car-card .car-details .disFlex.elementsIcons.IconGrid .Bluetooth .bluetooth-label {
    display: none;
}

.popup-content .car-card .car-details .datepicker {
    overflow: hidden;
}

.popup-content .car-card .car-details .rdrDateRangePickerWrapper,
.popup-content .car-card .car-details .rdrCalendarWrapper.rdrDateRangeWrapper,
.popup-content .car-card .car-details .rdrMonth {
    width: 100%;
}

.popup-content .car-card .total-price .per-day {
    position: static;
}

.popup-content .car-card.card-opened .button-container {
    padding: 0;
}

.popup-content .car-card > .car-details {
    padding: 5px;
}

.popup-content .car-card.card-opened .company-name-overlay,
.popup-content .car-card.card-opened .slick-slider,
.popup-content .car-card.card-opened .car-details .LeftRight,
.popup-content .car-card.card-opened .car-details .car-details,
.popup-content .car-card .car-details .price-details,
.popup-content .car-card .date-icon {
    display: none;
}

.popup-content .car-card.card-opened .date-icon {
    display: block;
}
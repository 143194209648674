.like__container {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    height: 30px; /* Фиксированная высота контейнера, чтобы предотвратить дергание */
}

.hearth {
    width: 30px;
    height: 30px;
    fill: none;
    stroke: white;
    stroke-width: 2px;
    transition: transform 0.2s ease-in-out;
}

.hearth.pulse {
    animation: pulseAnimation 0.6s ease-in-out;
}

.like_count {
    font-size: 18px;
    color: white;
    margin-left: 5px;
    display: inline-block;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    position: relative; /* Чтобы не изменялись размеры контейнера */
    z-index: 2; /* Чтобы текст всегда был сверху */
}

.count-up {
    animation: countUpAnimation 0.5s ease-out;
}

.count-down {
    animation: countDownAnimation 0.5s ease-in;
}

/* Пульсация сердца */
@keyframes pulseAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}

/* Анимация увеличения числа */
@keyframes countUpAnimation {
    0% {
        transform: translateY(10px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Анимация уменьшения числа */
@keyframes countDownAnimation {
    0% {
        transform: translateY(-10px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Стили для родительского компонента */
.LikesButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: rgba(15, 15, 16, 0.15);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    z-index: 1;
    user-select: none;
}

@import url('../../Services/variables/variables.css');

/* General Header Styles */
.header {
    position: fixed;
    top: 0; /* Прижимаем к верху страницы */
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    max-width: 855px;
    height: 80px;
    background: var(--gradient-button);
    border-radius: 20px;
    box-shadow: var(--shadow-glass);
    backdrop-filter: blur(10px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    z-index: 1000;
    font-family: var(--font-family-primary);
    box-sizing: border-box;
}

/* Flex Containers for Left and Right Parts */
.header-left, .header-right {
    display: flex;
    align-items: center;
    gap: 15px;
}

.header-left {
    flex: 1;
    justify-content: flex-start;
}

.header-right {
    flex: 1;
    justify-content: flex-end;
}

/* Country Selector Styles */
.country-selector {
    position: relative;
    max-width: 188px;
    width: 100%;
    border-radius: var(--border-radius-large);
    transition: all 0.3s ease;
}

.country-button {
    display: flex;
    align-items: center;
    gap: 10px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: var(--border-radius-large);
    transition: all 0.3s ease;
}

._selector-button {
    background: var(--glass-background-secondary);
    box-shadow: var(--box-shadow-light);
    max-width: 188px;
    width: 100%;
    height: 48px;
    justify-content: space-between;
}

.country-button.disabled {
    pointer-events: none;
    opacity: 0.5;
    cursor: not-allowed;
}

.country-flag {
    width: 30px;
    height: 20px;
    border-radius: var(--border-radius-small);
    font-size: 17px;
}

.country-name {
    color: var(--text-color-primary);
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
}

.country-options {
    position: absolute;
    top: 100%; /* Привязываем к нижней части кнопки */
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background: var(--glass-background);
    box-shadow: var(--shadow-glass);
    border-radius: var(--border-radius-large);
    z-index: 1001;
    padding: 10px;
    max-width: 188px;
    width: 100%;
}

.country-arrow-icon {
    width: 15px;
    height: 8px;
    transition: transform 0.3s ease;
}

.country-button._selector-button.open .country-arrow-icon {
    transform: rotate(180deg);
}

/* Language Switcher Styles */
.language-switcher {
    position: relative;
    max-width: 60px;
    width: 100%;
    border-radius: var(--border-radius-large);
}

.lang-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--glass-background-secondary);
    border: none;
    cursor: pointer;
    padding: 8px 12px;
    border-radius: var(--border-radius-large);
    transition: all 0.3s ease;
    width: 100%;
    max-width: 60px;
    box-shadow: var(--box-shadow-light);
}

.language-options {
    position: absolute;
    top: 100%; /* Привязываем к нижней части кнопки */
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
    background: var(--glass-background);
    box-shadow: var(--shadow-glass);
    border-radius: var(--border-radius-large);
    z-index: 1001;
    padding: 10px;
    max-width: 60px;
    width: 100%;
}

.lang-name {
    color: var(--text-color-primary);
    font-size: 16px;
    font-weight: 500;
}

.lang-arrow-icon {
    width: 12px;
    transition: transform 0.3s ease;
}

.lang-button._selected.open .lang-arrow-icon {
    transform: rotate(180deg);
}

/* Media Queries */
@media (max-width: 1024px) {
    .header {
        max-width: 95%;
        height: 70px;
        padding: 0 15px;
    }

    .country-flag {
        width: 25px;
        height: 15px;
    }

    .country-name {
        font-size: 16px;
    }

    ._selector-button {
        height: 40px;
        max-width: 160px;
    }

    .country-options {
        max-width: 160px;
    }

    .lang-name {
        font-size: 16px;
    }
}

@media (max-width: 700px) {
    .header {
        height: 60px;
    }

    .country-selector {
        max-width: 140px;
    }

    ._selector-button {
        max-width: 140px;
        padding: 5px 10px;
        height: 36px;
    }

    .country-flag {
        width: 20px;
        height: 15px;
    }

    .country-name {
        font-size: 14px;
    }

    .country-arrow-icon {
        width: 12px;
        height: 6px;
    }

    .lang-button {
        justify-content: center;
    }

    .lang-name {
        font-size: 14px;
    }
}

@media (max-width: 600px) {
    .header {
        width: 100%;
        max-width: 100%;
        border-radius: 0;
        padding: 0 10px;
        height: 55px;
    }

    .country-selector {
        max-width: 120px;
    }

    ._selector-button {
        max-width: 120px;
        height: 30px;
        padding: 5px 8px;
    }

    .country-name {
        font-size: 12px;
    }

    .lang-button._selected {
        max-width: 45px;
        padding: 5px;
    }
}

@media (max-width: 428px) {
    .header-left {
        gap: 5px;
    }

    .country-selector {
        max-width: 100px;
    }

    ._selector-button {
        max-width: 100px;
        padding: 5px 6px;
    }

    .country-name {
        font-size: 11px;
    }

    .header-right {
        gap: 5px;
    }

    .lang-button._selected {
        padding: 0;
    }
}
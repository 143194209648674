/* Импорт переменных (если используется) */
@import url('../../../Services/variables/variables.css');

/* Основной контейнер */
.addCarsContainer {
    display: flex;
    flex-direction: column;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    border-radius: 20px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px); /* Эффект стекла */
}

/* Заголовок */
.addCarsContainer h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
    color: var(--text-color-primary, #333);
}

/* Текст метки */
.name-add-text {
    font-size: 16px;
    font-weight: 500;
    color: var(--text-color-primary, #333);
    margin-bottom: 5px;
}

/* Обязательное поле */
.required {
    color: #ff3b30; /* Красный в стиле iOS */
}

/* Контейнер для чекбоксов */
.input-container-checkbox {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; /* Позволяем перенос элементов на узких экранах */
    gap: 20px;
    margin: 10px 0;
    align-items: center;
    width: 100%; /* Убираем фиксированную ширину, растягиваем на весь контейнер */
}

/* Общий контейнер для ввода */
.input-container {
    margin-bottom: 20px;
}

/* Стили для полей ввода и селектов */
input[type="text"],
input[type="textarea"],
select {
    width: 100%;
    padding: 12px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.9);
    font-size: 16px;
    color: var(--text-color-primary, #333);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
}

input[type="text"]:focus,
input[type="textarea"]:focus,
select:focus {
    border-color: #007bff;
    box-shadow: 0 4px 15px rgba(0, 123, 255, 0.2);
    outline: none;
}

/* Чекбоксы */
input[type="checkbox"] {
    width: auto;
    margin-right: 10px;
    accent-color: #007bff; /* Цвет чекбокса в стиле iOS */
}

/* Flex-контейнер */
.disFlex {
    display: flex;
    flex-direction: row;
}

/* Метки */
.addCarsContainer label {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: var(--text-color-primary, #333);
    margin-bottom: 5px;
}

/* Кнопка */
.addCarsContainer button {
    padding: 12px;
    background: linear-gradient(135deg, #007bff, #0056b3);
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    border: none;
    border-radius: 15px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
    margin: 11px 0;
}

.addCarsContainer button:disabled {
    background: rgba(200, 200, 200, 0.9);
    cursor: not-allowed;
    box-shadow: none;
}

.addCarsContainer button:hover:enabled {
    background: linear-gradient(135deg, #0056b3, #003d82);
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25);
}

/* Контейнер загрузки изображений */
.image-upload {
    max-width: 500px;
    margin: 20px auto;
    padding: 20px;
    background: rgba(255, 255, 255, 0.9);
    border: 2px dashed rgba(0, 0, 0, 0.2);
    border-radius: 15px;
    text-align: center;
    transition: all 0.3s ease;
    backdrop-filter: blur(5px);
}

.image-upload:hover {
    border-color: rgba(0, 0, 0, 0.4);
}

/* Кнопка загрузки */
.image-upload__button {
    padding: 12px 20px;
    background: linear-gradient(135deg, #007bff, #0056b3);
    color: #fff;
    font-size: 16px;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.image-upload__button:hover {
    background: linear-gradient(135deg, #0056b3, #003d82);
    transform: translateY(-2px);
}

/* Превью изображений */
.image-upload__preview {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
}

.image-upload__preview img {
    max-width: 100%;
    height: auto;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Медиа-запросы */
@media (max-width: 768px) {
    .addCarsContainer .disFlex {
        flex-direction: column;
    }
    .addCarsContainer {
        max-width: 600px;
    }
    .input-container-checkbox {
        flex-direction: column;
        align-items: flex-start;
    }
}

@media (max-width: 600px) {
    .addCarsContainer {
        padding: 15px;
        max-width: 100%;
    }
    .name-add-text {
        font-size: 14px;
    }
    input[type="text"],
    input[type="textarea"],
    select {
        font-size: 14px;
    }
    .addCarsContainer button {
        font-size: 14px;
    }
}
@import url('../../Services/variables/new-variables.css');

.theme-toggle-span {
    width: 25px;
    height: 25px;
    background-color: var(--text-color-primary);
    border-radius: 50%;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.theme-toggle-btn {
    display: flex;
    width: 50px;
    height: 50px;
    background: transparent;
    border-radius: 50%;
    border: none;
    justify-content: center;
    align-items: center;
    cursor: pointer;

}


.stories-container {
  margin-top: 20px;
  padding: 15px;
  background: var(--background-secondary-color);
  border-radius: var(--border-radius-small);
}

.stories-container h3 {
  color: var(--text-color-primary);
  font-size: 16px;
  margin-bottom: 10px;
}

.story-input {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.story-input input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid var(--neutral-color-6);
  border-radius: var(--border-radius-small);
  font-size: 14px;
  background: var(--background-color);
  color: var(--text-color-primary);
}

.story-input input:focus {
  border-color: var(--primary-color);
}

.story-input button {
  background: var(--primary-color);
  color: #ffffff;
  border: none;
  padding: 8px 12px;
  border-radius: var(--border-radius-small);
  cursor: pointer;
}

.story-input button:hover {
  background: var(--primary-hover-color);
}

.stories-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.story {
  padding: 10px;
  border: 1px solid var(--neutral-color-6);
  border-radius: var(--border-radius-small);
  background: var(--background-color);
}

.story p {
  margin: 0;
  font-size: 14px;
  color: var(--text-color-primary);
}

.story span {
  font-size: 12px;
  color: var(--neutral-color-6);
}

/* Адаптивность */
@media (max-width: 768px) {
  .stories-container {
    padding: 10px;
  }

  .story-input input {
    font-size: 12px;
  }

  .story p {
    font-size: 12px;
  }
}